import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./feature/authSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
  }, // rootReducer
});
 //console.log(JSON.stringify('authSlice'+authSlice)) 출력안됨
//export type AppDispatch = typeof store.dispatch;
export default store;
