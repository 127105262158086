import axios from "axios";

const DOMAIN = "//xn--pn3b68hmps.com/api";
axios.defaults.withCredentials = true; // 쿠키 데이터를 전송받기 위해
export const request = (method, url, data) => {
  return axios({
    method: method,
    url: DOMAIN + url,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
