import React, { useEffect, useState, View, Text } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  Table,
  Space,
  DatePicker,
  Input,
  Select,
  message,
  Divider,
  Form,
} from "antd";
import { FileExcelOutlined, ReloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, Link } from "react-router-dom";
import type { FilterValue } from "antd/es/table/interface";
import { CSVLink } from "react-csv";
import {
  utimemonth_list,
  getutimemonthFullList,
} from "../../service/staticService";
import dayjs from "dayjs";

//==>수정순서   각 영역별로 faq or notice의 리스트를열고 복사 붙혀넣기를 한다  수정이라고 체크된부분을 수정한다
//수정요소: listdata 출력, 선택삭제, 엑셀다운, 등록버튼링크
//db: usetime

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

//테이블의  column 헤더에 출력되는 1row 값

//console.log("날짜" + dayjs().format("YYYY-MM-DD hh:mm:ss")); // 이런식으로 출력이되어야 밀리세컨드로 계산이된다
/*  밀리세컨드로 변환해서 출력하기  2시간 20분30초방식으로 출력하기
var time1 = new Date("2019-09-03 12:00:00");
var time2 = new Date("2019-09-03 14:23:32");

 var milisecond =time2 - time1;// 위 사용시간을 밀리세컨드로 변환   8612000
 console.log(`milisecond는${milisecond}`)

 var carcu_time= Math.floor((milisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));// 시간부분문 출력   2시간
 var carcu_minute= Math.floor((milisecond % (1000 * 60 * 60)) / (1000 * 60));// 분부분만 출력   23분
 var carcu_second= Math.floor((milisecond % (1000 * 60)) / 1000);//초 부분만출력   32초
 var use_time=carcu_time+'시간' + carcu_minute +'분'+ carcu_second+'초';
 console.log(`사용시간${carcu_time}/사용분${carcu_minute}/사용초${carcu_second}/use_time${use_time}`);

 // 배열변수화시켜 출력하기
 const total_time={
  '총사용시간':carcu_time, '총사용분':carcu_minute, '총사용초':carcu_second,'use_time':use_time
 }
 console.log('종합'+JSON.stringify(total_time));
*/

//수정 / data 연결후   dataIndex= db의 필드명과 동일해야 리스트에 출력됨
const columns: ColumnsType = [
  {
    title: "No",
    dataIndex: "num",
    align: "center",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  { title: "회원아이디", dataIndex: "m_id", align: "center" },
  {
    title: "회원구분",
    dataIndex: "m_grade",
    filters: [
      { text: "관리자", value: 1 },
      { text: "매니저", value: 2 },
      { text: "초등학생", value: 3 },
      { text: "중학생", value: 4 },
      { text: "고등학생", value: 5 },
      { text: "대학생", value: 6 },
      { text: "시험준비생", value: 7 },
      { text: "교사강사", value: 8 },
      { text: "기타", value: 9 },
    ], //필터는 리스트에서 클릭시 자동 정렬되는 구문임 불필요시 삭제할것
  },
  { title: "학년", dataIndex: "grade" },
  {
    title: "총사용시간(분)",
    dataIndex: "caltime",
    align: "center",
  },
  { title: "통계월", dataIndex: "reg_date", align: "center" },
];

const Utimemonthlist = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //체크박스체크시 넘겨온no_no 키값을 selectedRowKeys에 배열로저장및 업데이트한다
  const [data, setData] = useState();
  const [dateRange, setDateRange] = useState(["", ""]); // 기간검색  시작일과 종료일이 저장되고 업데이트된다
  const [searchTarget, setSearchTarget] = useState(); //검색필드 담는 변수와 업데이트 함수
  const [dateTarget, setDateTarget] = useState(); // 기간을 검색하는 여러필드들을 사전에  선택하고 업데이트 하게 한다
  const [loading, setLoading] = useState(false);
  const [csvComponent, setCsvCompnent] = useState(<></>);

  //기본 테이블 설정
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  //console.log('테이블명정보' + JSON.stringify( tableParams));

  //++++++++++++++++++++++ 살행해  유저상태별값에따를 총계를 가져온다userdata를 가져온다+++++++++++++++++++++++++++++++
  //테이블  체크박스를 클릭할때 select가 변경되었을때 faq_no값을 리턴해서  setSelectedRowKeys()로 SelectedRowKeys를 업데이트한다
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  //이object변수 에  채크박스로 선택한 no들이있는 selectedRowKeys이있고  object변수>onChange속성이 실행되면(채크박스값들이변동되면)
  //위 onSelectChange()로  selectedRowKeys값을 업데이트시킨다
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //+++++++++++++++++++++++++++++++++++++++++선택삭제  끝+++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문+++++++++++++++++++++++++++++++++++++++

  //기간선택부분  //날짜 선택시 검색 시작일,종료일선택해서 setDateRange()함수 업데이트//
  const onSearchDateChange = (dates, dateStrings) => {
    console.log("선택한 날짜는" + dateStrings); //시작일dateStrings[0]   종료일dateStrings[1]    2023-01-11식으로출력
    setDateRange(dateStrings);
  };

  //검색필드 선택시 value와 option값을 인수로 넘겨  setSearchTarget()를 업데이트한다
  const onSelectSearch = (value, option) => {
    setSearchTarget(value);
  };

  //없어도 되는부분 이선택
  const onSelectSearchDate = (value, option) => {
    // 원래 이부분은 기간이지만 검색할 날짜필드값이 많아  여러칼럼을 select에서 option으로 선택하게 했음
    setDateTarget(value);
  };

  //실제 검색함수==> antd 라이브러리에서 검색 input박스 에 버튼까지 연결시킨 input 박스임 입력검색 버튼을 눌렀을때 이 컴포의 value
  const onSearch = (value) => {
    // (검색값value가 있고 검색필드에서 값이 선택됬다면 ) || (검색시작일과종료일이 선택된  검색구간)
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
      searchDate: dateRange, // 검색기간
      dateTarget: dateTarget, // 기간을검색할필드로 기간선택 텍스트로 통일가능 없어두됨
      searchParam: { target: searchTarget, value: value }, //searchTarget  검색할 필드값   value=검색어
    });
  };

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문 end+++++++++++++++++++++++++++++++++++++++

  //+++++++++++++++++++++++++++++++3. data를 가져와  리턴받은 값을 setData()로 data변수에 리턴한다+++++++++++++++++++++++++++++++//+++++수정+++++
  const fetchData = async () => {
    //alert('3');
    setLoading(true);
    /*fetch(
        `//xn--pn3b68hmps.com/api/user/list?${qs.stringify(
          (tableParams)
        )}`
      )*/
    console.log("tableParams1", tableParams);
    const utimedayresult = await utimemonth_list(tableParams); //// service>userService.js >  getUserList()  함수를 실행해서 node에 던지고 결과를 리턴받는다
    console.log("utimedayresult" + JSON.stringify(utimedayresult, null, 2));
    setData(utimedayresult.list);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: utimedayresult.total,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  //+++++++++++++++++++++++++++++++2.  tableParams 변경시  fetchData();함수를 1번실행해서 데이타를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    //alert('2');
    fetchData();
    setCsvCompnent(<></>);
  }, [JSON.stringify(tableParams)]);

  //console.log('datasms'+JSON.stringify( fetchData(),null,2))

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter
  ) => {
    console.log("table chagne는", pagination, filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters,
      sorter,
    });
  };

  {
    /*======>수정  await이후 함수*/
  }
  const downloadCSV = async () => {
    const { csv_data, csv_header } = await getutimemonthFullList(
      "fullList1",
      tableParams
    );
    setCsvCompnent(
      <CSVLink
        data={csv_data}
        headers={csv_header}
        filename={dayjs().format("YYYYMMDD") + "_utimemonth_list.csv"}
      >
        <Button className="exelButton">
          <FileExcelOutlined />
          받기
        </Button>
      </CSVLink>
    );
  };

  //데이터 갱신
  const refreshData = () => {
    fetchData();
    //엑셀항목 초기화
    setCsvCompnent(<></>);
    //선택된 항목 초기화
    setSelectedRowKeys([]);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/log/utimedaylist" style={{ color: "#333333" }}>
            로그
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            월별사용시간 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => navigate(0)}
          ></Button>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="contentArea"
        style={{
          borderRadius: 15,
          marginBottom: 15,
          padding: 30,
          border: "0px solid red",
        }}
      >
        <Row
          gutter={24}
          style={{ width: "100%", marginBottom: 15, border: "0px solid red" }}
        >
          <Col span={6}>
            <Space wrap>
              {/* <Button   danger     onClick={() => {deletechecks2(selectedRowKeys).then((result) => {refreshData();}); }}>선택 삭제</Button>*/}
              <Button onClick={downloadCSV}>현재 목록 엑셀로</Button>
              {csvComponent}
            </Space>
          </Col>
          <Col
            span={18}
            style={{ textAlign: "right", border: "0px solid #999" }}
          >
            <Space style={{ float: "right" }}>
              <Input.Group
                compact
                style={{
                  float: "left",
                  minWidth: "50%",
                  border: "0px solid blue",
                }}
              >
                <Select
                  placeholder="검색필드"
                  allowClear
                  style={{ width: "30%" }}
                  onChange={onSelectSearch}
                >
                  {/*이하 부분만 수정하면 자동으로 다른것 수정하지 않아도 수정됨*/}
                  <Option value="m_id">회원아이디</Option>
                </Select>
                <Search
                  placeholder="검색어 입력"
                  allowClear
                  enterButton
                  defaultValue={""}
                  onSearch={onSearch}
                  style={{
                    width: "70%",
                    border: "0px solid red",
                  }}
                />
              </Input.Group>
            </Space>

            <Space style={{ float: "right" }}>
              <Input.Group compact>
                <Select
                  placeholder="날짜검색조건"
                  allowClear
                  style={{ minWidth: 120 }}
                  onChange={onSelectSearchDate}
                >
                  <Option value="reg_date">등록일</Option>
                </Select>
                <DatePicker.RangePicker
                  style={{ width: 200 }}
                  locale="ko_KR"
                  onChange={onSearchDateChange}
                />
              </Input.Group>
            </Space>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              columns={columns} //위에서지정한 컬럼타입을 저장한변수
              dataSource={data}
              pagination={{
                //이부분으로 pagenation이출력됨
                ...tableParams.pagination,
                position: ["bottomCenter"],
              }}
              onChange={handleTableChange}
              rowKey={(record) => record.ut_no}
              className="userlist-table"
              style={{ border: "0px solid #333" }}
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Utimemonthlist;
