import React, { useState, useEffect } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";

const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/gif"; // 업로드 허용할파일들지정
  if (!isJpgOrPng) {
    message.error("JPG, PNG 나 gif 파일을 등록해주세요.");
  }
  const isLt5M = file.size / 1024 / 1024 < 2; //파일용량제한
  if (!isLt5M) {
    message.error("파일용량은 5MB보다 작아야 합니다.");
  }
  return isJpgOrPng && isLt5M;
};
//target은 업로드시 파일용도에 따라 저장 경로를 분리하기 위함
//onChange는 antd Form.Item에서 호출시 자동으로 상속됨 등록 결과를 onChange에 넣어줘야 Form에서 확인 가능
//value는 antd Form.Item에서 호출시 자동으로 상속됨 initialValue로 초기값 설정 시 상속
//form에 리턴되는 업로드 결과값은 아래 형식
/**
        file: {
          uid: info.file.uid, //고유값
          status: info.file.status, //업로드 상태
          url: `//xn--pn3b68hmps.com/${info.file.response.fileInfo.path}`, //접근가능한 URL
          name: info.file.name, //파일명
        },
 */
const ImageUploader = ({ target, onChange, value }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(value?.file?.url);
  //console.log(value?.file?.url);

  const handleChange = (info) => {
    //console.log("file info", info);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      setLoading(false);
      setImageUrl(`http://3.37.116.50/${info.file.response.fileInfo.path}`);

      onChange({
        file: {
          uid: info.file.uid,
          status: info.file.status,
          url: `http://3.37.116.50/${info.file.response.fileInfo.path}`,
          name: info.file.name,
        },
      });
    }
  };

  useEffect(() => {
    //name="title"의 value 값을 가지고 왔다
    setImageUrl(value?.file?.url);
    console.log("imageUploader value", value);
  }, [value]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload
      multiple={false}
      listType="picture-card"
      className="avatar-uploader"
      name="image"
      showUploadList={false}
      action={"http://3.37.116.50/uploader/" + target}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default ImageUploader;
