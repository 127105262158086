import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  Table,
  Space,
  DatePicker,
  Input,
  Select,
  message,
  Form,
  Divider,
} from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, Link } from "react-router-dom";
import type { FilterValue } from "antd/es/table/interface";
import { CSVLink } from "react-csv";
import {
  moredata,
  getipblocklist,
  getipblockFullList,
  deletechecks,
  getSimpleStats,
} from "../../service/ipblockService"; //===>수정
import {
  reqnumber,
  userGradeInfo,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  userState,
  genderInfo,
  regPhone,
  regPassword,
  removeblank,
} from "../../config/staticInfo";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
//==>수정순서   각 영역별로 faq or notice의 리스트를열고 복사 붙혀넣기를 한다  수정이라고 체크된부분을 수정한다
//수정요소: listdata 출력, 선택삭제, 엑셀다운, 등록버튼링크
//회원테이블에서 ip 등록후 리스트 출력에서   별도ip_block을 만들어 제한할것  (회원페널티가 아니므로 별도 관리)
//db table: ip_block
const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

//테이블의  column 헤더에 출력되는 1row 값

console.log("날짜" + dayjs().format("YYYY-MM-DD hh:mm:ss"));

//===>수정/ data 연결후   dataIndex= db의 필드명과 동일해야 리스트에 출력됨
const columns: ColumnsType = [
  { title: "No", dataIndex: "ip_no", width: "10%", align: "center" },
  {
    title: "접근제한아이피",
    dataIndex: "ipnumber",
    width: "75%",
    align: "center",
  },
  { title: "등록일", dataIndex: "reg_date", width: "15%", align: "center" },
];

const Ipblocklist = () => {
  const { user } = useSelector((state) => state.auth); ///==>무슨의미? 어디서 호출?
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //체크박스체크시 넘겨온faq_no 키값을 selectedRowKeys에 배열로저장및 업데이트한다
  const [data, setData] = useState();
  const [dateRange, setDateRange] = useState(["", ""]); // 기간검색  시작일과 종료일이 저장되고 업데이트된다
  const [searchTarget, setSearchTarget] = useState(); //검색필드 담는 변수와 업데이트 함수
  const [dateTarget, setDateTarget] = useState(); // 기간을 검색하는 여러필드들을 사전에  선택하고 업데이트 하게 한다
  const [loading, setLoading] = useState(false);
  const [csvComponent, setCsvCompnent] = useState(<></>);

  const [form] = Form.useForm();

  //++++++++++++++초기로딩시  유저상태값을가져온다  아무것도 안찍히고 초기값만찍힘
  const [userStats, setUserStats] = useState({
    today: 0,
    total: 0,
    use_state: { 1: 0, 2: 0, 3: 0 }, //무슨의미인지?
  });

  //기본 테이블 설정   페이징처리시 필요한 초기세팅값
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  //console.log('테이블명정보' + JSON.stringify( tableParams));

  //++++++++++++이하 앱> FAQ의 더보기 클릭시 출력되는 갯수를 세팅하는 구문 으로   통합적용할것인지 고민하고 이하는  나중에 수정할것++++++++++++++++
  const onFinish = async (values) => {
    const registParam = {
      ...values,
      birth: dayjs(values.birth).format("YYYYMMDD"),
      pic_route: values.pic_route.file.url,
    };

    const result = await moredata(registParam);

    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("등록이 완료되었습니다.");
        navigate("/user/detail/" + result.result.insertId);
        break;
      case "exist id":
        message.error("이미 존재하는 ID입니다.");
        break;
      case "exist nname":
        message.error("이미 존재하는 닉네임입니다.");
        break;
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateMessages = {
    required: "'${label}'을(를) 입력해주세요.",
    pattern: {
      mismatch: "'${label}' 형식에 맞게 작성해주세요.",
    },
    types: {
      email: "'${label}' 형식에 맞게 작성해주세요.",
    },

    // ...
  };

  //+++++++++++++++++++++++++++++++0. 로그인시 저장된 세션 정보를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    //// 세션정보를 가져올수 없음   ===> 원격지에서는 같은서버로 세션정보를 가져올수 있음 //xn--pn3b68hmps.com/user
    //  alert('0');
    const users = JSON.parse(sessionStorage.getItem("user"));
    console.log("userlist>session정보는" + JSON.stringify(users));
  }, []);

  //+++++++++++++++++ 회원목록리스트에서  회원들의 계략적인 회원상태별  숫자 통계출력위한 정보를 가져온다===>수정안함 나중확인+++++++++++++++++++++++++++++++
  //console.log('테이블명정보' + JSON.stringify( tableParams));
  //use_state별로 가져온 total수를 (3개상태별로 카운트를한숫자3개를  use_state: { 1: 0, 2: 0, 3: 0 }, 순서대로  리턴한다
  const getUserStats = async () => {
    const statsResult = await getSimpleStats(); // service>userService.js >  getSimpleStats()  함수를 실행해서 mode에 던지고 결과를 리텀받는다
    setUserStats({
      today: statsResult.todayJoin?.total, //오늘의 가입자  계: service>userService.js > getSimpleStats()를 실행해서 node>user 로 todayJoin 변수를 리턴받음
      use_state: {
        // 상태별 가입자 계: service>userService.js > getSimpleStats()를 실행해서 node>user 로 todayJoin 변수를 리턴받음
        [statsResult.stateCount[0]?.use_state]:
          statsResult.stateCount[0]?.total,
        [statsResult.stateCount[1]?.use_state]:
          statsResult.stateCount[1]?.total,
        [statsResult.stateCount[2]?.use_state]:
          statsResult.stateCount[2]?.total,
      },
      // ㅇ위 상태별 가입자들을 모두더한 값이 total임
      total:
        statsResult.stateCount[0]?.total +
        statsResult.stateCount[1]?.total +
        statsResult.stateCount[2]?.total,
    });
    console.log("페이지로드시 가져온 회원정보", statsResult);
  };
  //++++++++++++++++++++++현재불필요: 1. getUserStats 살행해  유저상태별값에따를 총계를 가져온다userdata를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    sessionStorage.setItem("backUrl", "/user"); ///backUrl에 /user란 경로 등록==> 무슨의미인가???
    getUserStats();
    //alert('1');
  }, []);

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //+++++++++++++++++++++++++++++++++++++++++선택삭제+++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++++++++++++++++++++ 살행해  유저상태별값에따를 총계를 가져온다userdata를 가져온다+++++++++++++++++++++++++++++++
  //테이블  체크박스를 클릭할때 select가 변경되었을때 faq_no값을 리턴해서  setSelectedRowKeys()로 SelectedRowKeys를 업데이트한다
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  //이object변수 에  채크박스로 선택한 no들이있는 selectedRowKeys이있고  object변수>onChange속성이 실행되면(채크박스값들이변동되면)
  //위 onSelectChange()로  selectedRowKeys값을 업데이트시킨다
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //+++++++++++++++++++++++++++++++++++++++++선택삭제  끝+++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문+++++++++++++++++++++++++++++++++++++++

  //기간선택부분  //날짜 선택시 검색 시작일,종료일선택해서 setDateRange()함수 업데이트//
  const onSearchDateChange = (dates, dateStrings) => {
    console.log("선택한 날짜는" + dateStrings); //시작일dateStrings[0]   종료일dateStrings[1]    2023-01-11식으로출력
    setDateRange(dateStrings);
  };

  //검색필드 선택시 value와 option값을 인수로 넘겨  setSearchTarget()를 업데이트한다
  const onSelectSearch = (value, option) => {
    setSearchTarget(value);
  };

  //없어도 되는부분 이선택
  const onSelectSearchDate = (value, option) => {
    // 원래 이부분은 기간이지만 검색할 날짜필드값이 많아  여러칼럼을 select에서 option으로 선택하게 했음
    setDateTarget(value);
  };

  //실제 검색함수==> antd 라이브러리에서 검색 input박스 에 버튼까지 연결시킨 input 박스임 입력검색 버튼을 눌렀을때 이 컴포의 value
  const onSearch = (value) => {
    // (검색값value가 있고 검색필드에서 값이 선택됬다면 ) || (검색시작일과종료일이 선택된  검색구간)
    if (
      (value !== "" && searchTarget) ||
      (dateRange[0] !== "" && dateRange[1] !== "")
    ) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: 1,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
        searchDate: dateRange, // 검색기간
        dateTarget: dateTarget, // 기간을검색할필드로 기간선택 텍스트로 통일가능 없어두됨
        searchParam: { target: searchTarget, value: value }, //searchTarget  검색할 필드값   value=검색어
      });
    } else {
      //message.error("검색하실 항목을 입력해주세요.");
      alert(
        "검색날짜필드/검색필드선택 및  검색어 입력  or 2개의 조건중 한가지라도 입력해주세요."
      );
    }
  };

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문 end+++++++++++++++++++++++++++++++++++++++

  //+++++++++++++++++++++++++++++++3. data를 가져와  리턴받은 값을 setData()로 data변수에 리턴한다+++++++++++++++++++++++++++++++//+++++수정+++++
  const fetchData = async () => {
    //alert('3');
    setLoading(true);
    /*fetch(
        `//xn--pn3b68hmps.com/api/user/list?${qs.stringify(
          (tableParams)
        )}`
      )*/
    console.log("tableParams1", tableParams);
    const listresult = await getipblocklist(tableParams); //// service>userService.js >  getUserList()  함수를 실행해서 node에 던지고 결과를 리턴받는다  ====>수정
    console.log("listresult" + JSON.stringify(listresult, null, 2));
    setData(listresult.list);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: listresult.total,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  //+++++++++++++++++++++++++++++++2.  tableParams 변경시  fetchData();함수를 1번실행해서 데이타를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    //alert('2');
    fetchData();
    setCsvCompnent(<></>);
  }, [JSON.stringify(tableParams)]);

  //console.log('datasms'+JSON.stringify( fetchData(),null,2))

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>
  ) => {
    console.log("table chagne는", pagination, filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters,
    });
  };

  {
    /*======>수정  await이후 함수*/
  }
  const downloadCSV = async () => {
    const { csv_data, csv_header } = await getipblockFullList(
      "fullList",
      tableParams
    );
    setCsvCompnent(
      <CSVLink
        data={csv_data}
        headers={csv_header}
        filename={dayjs().format("YYYYMMDD") + "_ipblock_list.csv"}
      >
        <Button className="exelButton">
          <FileExcelOutlined />
          받기
        </Button>
      </CSVLink>
    );
  };

  //데이터 갱신
  const refreshData = () => {
    fetchData();
    //엑셀항목 초기화
    setCsvCompnent(<></>);
    //선택된 항목 초기화
    setSelectedRowKeys([]);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          border: "0px solid orange",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <div style={{ border: "0px solid red", width: "50%", marginTop: 15 }}>
          <Breadcrumb.Item>IP접근허용/IP접근허용 목록</Breadcrumb.Item>{" "}
        </div>
        <div
          style={{
            border: "0px solid red",
            width: "50%",
            textAlign: "end",
            paddingRight: 20,
            marginTop: 10,
          }}
        >
          <Breadcrumb.Item>
            <Link to={"/ipblock/regist"}>
              <Button type="dashed" htmlType="button">
                IP등록
              </Button>
            </Link>
          </Breadcrumb.Item>{" "}
        </div>
      </Breadcrumb>
      <Divider />

      <div
        className="contentArea"
        style={{
          borderRadius: 15,
          marginBottom: 15,
          padding: 30,
          border: "0px solid red",
        }}
      >
        <Row
          gutter={24}
          style={{ paddingBottom: 15, border: "0px solid #999" }}
        >
          <Col span={6}>
            <Space wrap>
              {user.m_grade === 1 ? (
                <Button
                  danger
                  onClick={() => {
                    deletechecks(selectedRowKeys).then((result) => {
                      refreshData();
                    });
                  }}
                >
                  선택 삭제
                </Button>
              ) : (
                ""
              )}
              <Button onClick={downloadCSV}>현재 목록 엑셀로</Button>
              {csvComponent}
            </Space>
          </Col>

          <Col
            span={18}
            style={{ textAlign: "right", border: "0px solid #999" }}
          >
            <Space style={{ float: "right" }}>
              <Input.Group
                compact
                style={{
                  float: "left",
                  minWidth: "50%",
                  border: "0px solid blue",
                }}
              >
                <Select
                  placeholder="검색필드"
                  allowClear
                  style={{ width: "30%" }}
                  onChange={onSelectSearch}
                >
                  {/*이하 부분만 수정하면 자동으로 다른것 수정하지 않아도 수정됨*/}
                  <Option value="ip_no">고유번호</Option>
                  <Option value="ipnumber">접근제한아이피</Option>
                </Select>
                <Search
                  placeholder="검색어 입력"
                  allowClear
                  enterButton
                  defaultValue={""}
                  onSearch={onSearch}
                  style={{
                    width: "70%",
                    border: "0px solid red",
                  }}
                />
              </Input.Group>
            </Space>

            <Space style={{ float: "right" }}>
              <Input.Group compact>
                <Select
                  placeholder="검색날짜필드"
                  allowClear
                  style={{ minWidth: 80 }}
                  onChange={onSelectSearchDate}
                >
                  <Option value="reg_date">등록일</Option>
                </Select>
                <DatePicker.RangePicker
                  style={{ width: 200 }}
                  locale="ko_KR"
                  onChange={onSearchDateChange}
                />
              </Input.Group>
            </Space>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection} // 체크박스 선택한 값들을 실시간으로 변동하게 하는 object형 변수==> 전체 삭제기능필수
              columns={columns} //위에서지정한 컬럼타입을 저장한변수
              dataSource={data}
              pagination={{
                //이부분으로 pagenation이출력됨
                ...tableParams.pagination,
                position: ["bottomCenter"],
              }}
              onChange={handleTableChange}
              rowKey={(record) => record.ip_no} //===> 수정
              className="userlist-table"
              style={{ border: "0px solid #333" }}
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Ipblocklist;
