import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Col,
  Select,
  Row,
  Radio,
  message,
  InputNumber,
} from "antd";
import {
  printornot,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  userState,
  genderInfo,
  regPhone,
  regPassword,
} from "../../config/staticInfo";
import ImageUploader from "../../components/ImageUploader";
import { registrandom } from "../../service/randomService"; //==>수정
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

const validateMessages = {
  required: "'${label}'을(를) 입력해주세요.",
  pattern: {
    mismatch: "'${label}' 형식에 맞게 작성해주세요.",
  },
  types: {
    email: "'${label}' 형식에 맞게 작성해주세요.",
  },

  // ...
};

const Randomregist = () => {
  const [form] = Form.useForm(); //
  const navigate = useNavigate();
  /* 
  //중복검사가 필요한 항목만 설정
   const [dpCheck, setDpCheck] = useState({
    m_id: "",m_nname: "",
  });

  useEffect(() => {
    //라디오버튼의 초기값 설정으로 한번 실행
    form.setFieldsValue({
      conf_yn: 1,useminfo_yn: 1,push_yn: 2,email_yn: 2,use_state: 1,
    });
  }, []);
  */
  const onFinish = async (values) => {
    // 중복확인 2개 진행이후 등록하기 로직
    console.log("넘긴값" + JSON.stringify(values));
    const todaydate = dayjs().format("YYYY-MM-DD");
    const registParam = {
      //위 values에  아래 폼의 name값들이  자동저장되고 배열끝에 todaydate(오늘날짜)추가됨
      ...values,
      reg_date: todaydate,
    };
    console.log("넘긴값>날짜추가" + JSON.stringify(registParam));

    const result = await registrandom(registParam);
    console.log("result message" + JSON.stringify(result));
    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("등록이 완료되었습니다.");
        navigate("/service/random");
        break;
      case "exist id":
        message.error("이미 존재하는 ID입니다.");
        break;
      case "exist nname":
        message.error("이미 존재하는 닉네임입니다.");
        break;
    }
  }; //onFinish  end

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/service/random" style={{ color: "#333333" }}>
            서비스관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/service/random" style={{ color: "#333333" }}>
            랜덤카피관리 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item reloadDocument style={{ color: "#333333" }}>
          <Link reloadDocument style={{ color: "#333333" }}>
            랜덤카피등록
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea" style={{ border: "0px solid #333" }}>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }} //라밸폭비율
          wrapperCol={{ span: 20 }} //input box 폭 비율
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages} // 이부분이있어야 아래 요소들의 rules를 자동으로 검사함
          style={{ textAlign: "left", border: "0px solid #333" }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="제목"
                name="ra_subject"
                hasFeedback
                rules={[{ required: true }, { min: 5, max: 80 }]}
                style={{ textAlign: "left", border: "0px solid #333" }}
                //validateStatus={dpCheck.m_id}
              >
                <Input
                  allowClear
                  maxLength={80}
                  //onChange={() => initCheck("m_id")}
                />
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left", border: "0px solid #333" }}
                label="내용"
                labelAlign="right"
                name="ra_detail"
                hasFeedback
                rules={[{ required: true }, { min: 5, max: 500 }]}
                //validateStatus={dpCheck.faq_detail}
              >
                <TextArea
                  placeholder=""
                  maxLength={500}
                  autoSize={{ minRows: 10, maxRows: 15 }}
                />
              </Form.Item>
              <Form.Item
                label="출력여부"
                name="view_yn"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={1}>{printornot[1]}</Radio>
                  <Radio value={2}>{printornot[2]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                {/*<Button type='primary' htmlType="submit" style={{ width:300,height:100,textAlign:'center',border:'1px solid #fff',borderRadius:15}}>
           htmlType="submit"클릭하면-> onFinish(values)함수를 호출해서  Form.Items 의 name값들이 values인수에 전달되어 db에 저장된다
           */}
                <Button type="primary" htmlType="submit">
                  등록
                </Button>
                <Button onClick={() => navigate(-1)} style={{ marginLeft: 5 }}>
                  {" "}
                  취소
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Content>
  );
};

export default Randomregist;
