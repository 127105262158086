import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  Table,
  Space,
  DatePicker,
  Input,
  Select,
  message,
} from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import type { FilterValue } from "antd/es/table/interface";
import { CSVLink } from "react-csv";

import {
  getUserList,
  getUserFullList,
  deleteUser,
  getSimpleStats,
} from "../../service/userService";
import dayjs from "dayjs";

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

//web to push발송서비스  https://uracle.co.kr/morpheus-suite/push/
//테이블의  column 헤더에 출력되는 1row 값

console.log("날짜" + dayjs().format("YYYY-MM-DD hh:mm:ss"));

const columns: ColumnsType = [
  { title: "No", dataIndex: "m_no" },
  { title: "아이디", dataIndex: "m_id" },
  { title: "이메일", dataIndex: "m_email" },
  {
    title: "성별",
    dataIndex: "gender",
    render: (gender) => {
      switch (gender) {
        default:
        case 1:
          return `남자`;
        case 2:
          return `여자`;
        case 3:
          return `기타`;
      }
    },
    filters: [
      { text: "남자", value: 1 },
      { text: "여자", value: 2 },
      { text: "기타", value: 3 },
    ],
  },
  {
    title: "사용여부",
    dataIndex: "use_state",
    render: (use_state) => {
      switch (use_state) {
        default:
        case 1:
          return `사용중`;
        case 2:
          return `탈퇴`;
        case 3:
          return `휴면`;
      }
    },
    filters: [
      { text: "사용중", value: 1 },
      { text: "탈퇴", value: 2 },
      { text: "휴면", value: 3 },
    ],
  },
  {
    title: "가입일",
    dataIndex: "intime",
  },
];

const Pushlist = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState();
  const [dateRange, setDateRange] = useState(["", ""]);
  const [searchTarget, setSearchTarget] = useState();
  const [dateTarget, setDateTarget] = useState();
  const [loading, setLoading] = useState(false);
  const [csvComponent, setCsvCompnent] = useState(<></>);

  const [userStats, setUserStats] = useState({
    today: 0,
    total: 0,
    use_state: { 1: 0, 2: 0, 3: 0 }, //무슨의미인지?
  });

  //기본 테이블 설정
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  //console.log('테이블명정보' + JSON.stringify( tableParams));
  const getUserStats = async () => {
    const statsResult = await getSimpleStats(); // service>userService.js >  getSimpleStats()  함수를 실행해서 mode에 던지고 결과를 리텀받는다
    setUserStats({
      today: statsResult.todayJoin?.total,
      use_state: {
        [statsResult.stateCount[0]?.use_state]:
          statsResult.stateCount[0]?.total,
        [statsResult.stateCount[1]?.use_state]:
          statsResult.stateCount[1]?.total,
        [statsResult.stateCount[2]?.use_state]:
          statsResult.stateCount[2]?.total,
      },
      total:
        statsResult.stateCount[0]?.total +
        statsResult.stateCount[1]?.total +
        statsResult.stateCount[2]?.total,
    });
    console.log("userStats", userStats);
  };

  useEffect(() => {
    sessionStorage.setItem("backUrl", "/user"); ///
    getUserStats();
  }, []);

  //테이블 select가 변경되었을때
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  //날짜 선택시
  const onSearchDateChange = (dates, dateStrings) => {
    console.log("선택한 날짜는" + dateStrings); //시작일dateStrings[0]   종료일dateStrings[1]    2023-01-11식으로출력
    setDateRange(dateStrings);
  };

  //검색조건 선택시
  const onSelectSearch = (value, option) => {
    setSearchTarget(value);
  };
  const onSelectSearchDate = (value, option) => {
    setDateTarget(value);
  };

  //검색 버튼을 눌렀을때
  const onSearch = (value) => {
    //검색값이 있을 경우
    if (
      (value !== "" && searchTarget) ||
      (dateRange[0] !== "" && dateRange[1] !== "")
    ) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: 1,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
        searchDate: dateRange,
        dateTarget: dateTarget,
        searchParam: { target: searchTarget, value: value },
      });
    } else {
      message.error("검색하실 항목을 입력해주세요.");
      alert("검색하실 항목을 입력해주세요.");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    /*fetch(
      `//xn--pn3b68hmps.com/api/user/list?${qs.stringify(
        (tableParams)
      )}`
    )*/
    console.log("tableParams", tableParams);
    const memberResult = await getUserList(tableParams); //// service>userService.js >  getUserList()  함수를 실행해서 node에 던지고 결과를 리턴받는다
    //console.log('memberResult'+JSON.stringify( memberResult,null,2))
    setData(memberResult.list);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: memberResult.total,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  useEffect(() => {
    fetchData();
    setCsvCompnent(<></>);
  }, [JSON.stringify(tableParams)]);

  //console.log('datasms'+JSON.stringify( fetchData(),null,2))

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>
  ) => {
    console.log("table chagne는", pagination, filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters,
    });
  };

  const downloadCSV = async () => {
    const { csv_data, csv_header } = await getUserFullList(
      "fullList",
      tableParams
    );
    setCsvCompnent(
      <CSVLink
        data={csv_data}
        headers={csv_header}
        filename={dayjs().format("YYYYMMDD") + "_user_list.csv"}
      >
        <Button className="exelButton">
          <FileExcelOutlined />
          받기
        </Button>
      </CSVLink>
    );
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>푸시알림관리</Breadcrumb.Item>
        <Breadcrumb.Item>푸시알림전송이력</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="contentArea"
        style={{ borderRadius: 15, marginBottom: 15, padding: 30 }}
      >
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col span={24}>
            <div style={{ textAlign: "right", fontSize: 16 }}>
              오늘 가입자:{userStats.today}명, 총 회원:{userStats.total}명 (
              사용중:{userStats.use_state[1]}명, 휴면:{userStats.use_state[2]}
              명, 탈퇴: {userStats.use_state[3]}명 )
            </div>
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{ paddingBottom: 15, border: "0px solid #999" }}
        >
          <Col span={8}>
            <Space wrap>
              <Button danger onClick={() => deleteUser("")}>
                선택 삭제
              </Button>
              <Button onClick={downloadCSV}>현재 목록 엑셀로</Button>
              {csvComponent}
            </Space>
          </Col>
          <Col
            span={16}
            style={{ textAlign: "right", border: "0px solid #999" }}
          >
            <Space style={{ float: "right" }}>
              <Input.Group
                compact
                style={{
                  float: "left",
                  minWidth: 330,
                  border: "0px solid #999",
                }}
              >
                <Select
                  placeholder="검색필드"
                  allowClear
                  style={{ width: "30%" }}
                  onChange={onSelectSearch}
                >
                  <Option value="m_id">아이디</Option>
                  <Option value="m_nname">닉네임</Option>
                  <Option value="m_email">이메일</Option>
                  <Option value="grade">학년</Option>
                </Select>
                <Search
                  placeholder="검색어 입력"
                  allowClear
                  enterButton
                  defaultValue={""}
                  onSearch={onSearch}
                  style={{
                    width: "70%",
                  }}
                />
              </Input.Group>
            </Space>
            <Space style={{ float: "right" }}>
              <Input.Group compact>
                <Select
                  placeholder="검색일자"
                  allowClear
                  style={{ minWidth: 120 }}
                  onChange={onSelectSearchDate}
                >
                  <Option value="intime">가입일</Option>
                  <Option value="outtime">탈퇴일</Option>
                  <Option value="last_login_time">최근 로그인</Option>
                  <Option value="last_logout_time">최근 로그아웃</Option>
                </Select>
                <DatePicker.RangePicker
                  style={{ width: 240 }}
                  locale="ko_KR"
                  onChange={onSearchDateChange}
                />
              </Input.Group>
            </Space>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              columns={columns} //위에서지정한 컬럼타입을 저장한변수
              dataSource={data}
              pagination={{
                ...tableParams.pagination,
                position: ["bottomCenter"],
              }}
              onChange={handleTableChange}
              rowKey={(record) => record.m_no}
              className="userlist-table"
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Pushlist;
