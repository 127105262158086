import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { authLogin, authLogout, authGetInfo } from "../service/authService";

const user = JSON.parse(sessionStorage.getItem("user"));//세션저장값을 가져와 저장

const initialState = user? { isLogin: true, user }: { isLogin: false, user: null };//세션값이 있으면  isLogin를  true

//로그인 비동기
export const authUserLogin = createAsyncThunk(
  "auth/login",
  async (loginInfo) => {
    const response = await authLogin(loginInfo.userid, loginInfo.password,loginInfo.ipinformation);//../service/authService> 

    return response;
  }
);

//로그인 정보확인
export const authUserInfo = createAsyncThunk("auth/info", async () => {
  const response = await authGetInfo();

  return response;
});

//로그인 비동기
export const authUserLogout = createAsyncThunk("auth/logout", async () => {
  const response = await authLogout();

  return response;
});

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  // extraReducers에 케이스 리듀서를 추가하면
  // 프로미스의 진행 상태에 따라서 리듀서를 실행할 수 있습니다.
  extraReducers: (builder) => {
    builder
      .addCase(authUserLogin.pending, (state) => {//Pending(대기) : 비동기 처리 로직이 아직 완료되지 않은 상태
        console.log("auth action pending", state);
      })
      .addCase(authUserLogin.fulfilled, (state, action) => {//Fulfilled(이행) : 비동기 처리가 완료되어 프로미스가 결과 값을 반환해준 상태
        console.log("auth action fulfilled", action);
        if (!action.payload.isLogin) {
          //로그인 실패
          console.log("auth login fail", action.payload.isLogin);
        } else {
          //로그인 성공
          state.isLogin = action.payload.isLogin;
          state.user = action.payload.user;
          console.log("auth login state", state);
        }
        //state = action.payload;
      })
      .addCase(authUserLogin.rejected, (state) => {//Rejected(실패) : 비동기 처리가 실패하거나 오류가 발생한 상태
        console.log("auth action rejected", state);
      })
      .addCase(authUserInfo.fulfilled, (state, action) => {
        console.log("info fulfilled", action);
        //로그인 성공
        if (action.payload.isLogin) {
          state.isLogin = action.payload.isLogin;
          state.user = action.payload.user;
        }
        console.log("auth info state", state);
        //state = action.payload;
      })
      .addCase(authUserLogout.fulfilled, (state, action) => {
        console.log("logout fulfilled", action);
        //로그인 성공
        state.isLogin = action.payload.isLogin;
        state.user = action.payload.user;
        console.log("auth logout state", state);
        //state = action.payload;
      });
  },
});

//export const { login } = authSlice.actions;

export default authSlice.reducer;
