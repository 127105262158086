import React, { useEffect, useState, View, Text } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  Table,
  Space,
  DatePicker,
  Input,
  Select,
  message,
  Divider,
  Form,
} from "antd";
import { FileExcelOutlined, ReloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, Link } from "react-router-dom";
import type { FilterValue } from "antd/es/table/interface";
import { CSVLink } from "react-csv";
import {
  foldrank_list,
  getfoldrankFullList,
} from "../../service/staticService";
import dayjs from "dayjs";

/*SELECT 
count(fo_name) as num,
fo_name,
row_number() over(order by count(fo_name) desc) as ranking
FROM tmdboksup.folder_statistic 
group by fo_name 
order by ranking asc
*/
//+++++++++++++++++total_usetime table에서 저장된 사용시간의 합을 가져올것  실시간 데이타의 합이므로+++++++++++++++++++++++++

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

//테이블의  column 헤더에 출력되는 1row 값

//수정 / data 연결후   dataIndex= db의 필드명과 동일해야 리스트에 출력됨

const columns: ColumnsType = [
  {
    title: "rank",
    dataIndex: "ranking",
    width: "10%",
    align: "center",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  { title: "생성폴더수", dataIndex: "num", width: "45%", align: "center" },
  { title: "폴더명", dataIndex: "fo_name", width: "45%", align: "center" },
];

const Foldrank = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //체크박스체크시 넘겨온no_no 키값을 selectedRowKeys에 배열로저장및 업데이트한다
  const [data, setData] = useState();
  const [dateRange, setDateRange] = useState(["", ""]); // 기간검색  시작일과 종료일이 저장되고 업데이트된다
  const [searchTarget, setSearchTarget] = useState(); //검색필드 담는 변수와 업데이트 함수
  const [dateTarget, setDateTarget] = useState(); // 기간을 검색하는 여러필드들을 사전에  선택하고 업데이트 하게 한다
  const [loading, setLoading] = useState(false);
  const [csvComponent, setCsvCompnent] = useState(<></>);

  //기본 테이블 설정
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  console.log("테이블tableParams" + JSON.stringify(tableParams));
  console.log(
    "검색기간" + JSON.stringify(dateTarget + dateRange[0] + dateRange[1])
  );

  //++++++++++++++++++++++ 살행해  유저상태별값에따를 총계를 가져온다userdata를 가져온다+++++++++++++++++++++++++++++++
  //테이블  체크박스를 클릭할때 select가 변경되었을때 faq_no값을 리턴해서  setSelectedRowKeys()로 SelectedRowKeys를 업데이트한다
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  //이object변수 에  채크박스로 선택한 no들이있는 selectedRowKeys이있고  object변수>onChange속성이 실행되면(채크박스값들이변동되면)
  //위 onSelectChange()로  selectedRowKeys값을 업데이트시킨다
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문+++++++++++++++++++++++++++++++++++++++

  //기간선택부분  //날짜 선택시 검색 시작일,종료일선택해서 setDateRange()함수 업데이트//
  const onSearchDateChange = (dates, dateStrings) => {
    console.log("선택한 날짜는" + dateStrings); //시작일dateStrings[0]   종료일dateStrings[1]    2023-01-11식으로출력
    setDateRange(dateStrings);
  };

  //검색필드 선택시 value와 option값을 인수로 넘겨  setSearchTarget()를 업데이트한다
  const onSelectSearch = (value, option) => {
    setSearchTarget(value);
  };

  //없어도 되는부분 이선택
  const onSelectSearchDate = (value, option) => {
    // 원래 이부분은 기간이지만 검색할 날짜필드값이 많아  여러칼럼을 select에서 option으로 선택하게 했음
    setDateTarget(value);
  };

  //실제 검색함수==> antd 라이브러리에서 검색 input박스 에 버튼까지 연결시킨 input 박스임 입력검색 버튼을 눌렀을때 이 컴포의 value
  const onSearch = (value) => {
    //alert(dateRange+'/'+dateTarget);
    // (검색값value가 있고 검색필드에서 값이 선택됬다면 ) || (검색시작일과종료일이 선택된  검색구간)
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
      searchDate: dateRange, // 검색기간
      dateTarget: dateTarget, // 기간을검색할필드로 기간선택 텍스트로 통일가능 없어두됨
      searchParam: { target: searchTarget, value: value }, //searchTarget  검색할 필드값   value=검색어
    });
  };

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문 end+++++++++++++++++++++++++++++++++++++++

  //+++++++++++++++++++++++++++++++3. data를 가져와  리턴받은 값을 setData()로 data변수에 리턴한다+++++++++++++++++++++++++++++++//+++++수정+++++
  const fetchData = async () => {
    //alert('3');
    setLoading(true);
    /*fetch(
        `//xn--pn3b68hmps.com/api/user/list?${qs.stringify(
          (tableParams)
        )}`
      )*/
    console.log("tableParams1", tableParams);
    const foldrankresult = await foldrank_list(tableParams); //// service>userService.js >  getUserList()  함수를 실행해서 node에 던지고 결과를 리턴받는다
    console.log(
      "foldstaticdayresult" + JSON.stringify(foldrankresult, null, 2)
    );
    setData(foldrankresult.list);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: foldrankresult.total,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  //+++++++++++++++++++++++++++++++2.  tableParams 변경시  fetchData();함수를 1번실행해서 데이타를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    //alert('2');
    setData("");
    fetchData();
    setCsvCompnent(<></>);
  }, [JSON.stringify(tableParams)]);

  //console.log('datasms'+JSON.stringify( tableParams,null,2));

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter
  ) => {
    console.log("table chagne는", pagination, filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters,
      sorter,
    });
  };

  {
    /*======>수정  await이후 함수*/
  }
  const downloadCSV = async () => {
    const { csv_data, csv_header } = await getfoldrankFullList(
      "fullList10",
      tableParams
    );
    setCsvCompnent(
      <CSVLink
        data={csv_data}
        headers={csv_header}
        filename={dayjs().format("YYYYMMDD") + "_foldrank_list.csv"}
      >
        <Button className="exelButton">
          <FileExcelOutlined />
          받기
        </Button>
      </CSVLink>
    );
  };

  //데이터 갱신
  const refreshData = () => {
    fetchData();
    //엑셀항목 초기화
    setCsvCompnent(<></>);
    //선택된 항목 초기화
    setSelectedRowKeys([]);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/static/cardstaticday" style={{ color: "#333333" }}>
            통계
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            폴더순위 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => navigate(0)}
          ></Button>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="contentArea"
        style={{
          borderRadius: 15,
          marginBottom: 15,
          padding: 30,
          border: "0px solid red",
        }}
      >
        <Row
          gutter={24}
          style={{ width: "100%", marginBottom: 15, border: "0px solid red" }}
        >
          <div
            style={{
              width: "50%",
              borderRadius: 15,
              marginBottom: 0,
              padding: 0,
              border: "0px solid red",
              paddingLeft: 15,
            }}
          >
            <Space wrap>
              {/* <Button   danger     onClick={() => {deletechecks2(selectedRowKeys).then((result) => {refreshData();}); }}>선택 삭제</Button>*/}
              <Button onClick={downloadCSV}>현재 목록 엑셀로</Button>
              {csvComponent}
            </Space>
          </div>

          {/*<Button onClick={onSearch}>검색</Button>  위 Search삭제후 검색하려했으나 안됨 빈페이지가 출력됨      */}
          <div
            style={{
              textAlign: "end",
              width: "50%",
              borderRadius: 15,
              marginBottom: 0,
              padding: 0,
              border: "0px dotted red",
            }}
          >
            <div
              style={{
                position: "relative",
                float: "right",
                width: "10%",
                border: "0px solid black",
                textAlign: "left",
                zIndex: 0,
              }}
            >
              <Input.Group
                compact
                style={{ border: "0px solid blue", left: -20 }}
              >
                <Search
                  placeholder=""
                  enterButton
                  defaultValue={""}
                  onSearch={onSearch}
                  style={{ width: "0%", border: "0px solid red" }}
                />
              </Input.Group>
            </div>
            <div
              style={{
                position: "relative",
                float: "right",
                width: "90%",
                border: "0px solid black",
                zIndex: 15,
              }}
            >
              <Input.Group compact>
                <DatePicker.RangePicker
                  style={{
                    position: "relative",
                    float: "right",
                    width: 210,
                    zIndex: 15,
                  }}
                  locale="ko_KR"
                  onChange={onSearchDateChange}
                />
                <Select
                  placeholder="검색조건"
                  allowClear
                  style={{
                    position: "relative",
                    float: "right",
                    minWidth: 100,
                    border: "0px solid green",
                  }}
                  onChange={onSelectSearchDate}
                >
                  <Option value="fo_st_date">등록일</Option>
                </Select>
              </Input.Group>
            </div>
          </div>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              columns={columns} //위에서지정한 컬럼타입을 저장한변수
              dataSource={data}
              pagination={{
                //이부분으로 pagenation이출력됨
                ...tableParams.pagination,
                position: ["bottomCenter"],
              }}
              onChange={handleTableChange}
              rowKey={(record) => record.num}
              className="userlist-table"
              style={{ border: "0px solid #333" }}
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Foldrank;
