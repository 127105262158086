import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Col,
  Select,
  Row,
  Radio,
  message,
  InputNumber,
  Space,
} from "antd";
import {
  printornot,
  userGradeInfo,
  agreeYN,
  useYN,
  userState,
  genderInfo,
  regPhone,
  regPassword,
  outReason,
} from "../../config/staticInfo";
import ImageUploader from "../../components/ImageUploader";
import {
  checkUserDuplicate,
  getrandomInfo,
  updaterandom,
} from "../../service/randomService"; //===수정
import dayjs from "dayjs";
import { useParams, useNavigate, Link } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

//no필드명수정, 기타필드명수정    service페이지의 필드명 일치관련 수정
const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const validateMessages = {
  required: "'${label}'을(를) 입력해주세요.",
  pattern: {
    mismatch: "'${label}' 형식에 맞게 작성해주세요.",
  },
  types: {
    email: "'${label}' 형식에 맞게 작성해주세요.",
  },

  // ...
};

const Randommodify = ({ history }) => {
  const [form] = Form.useForm();
  const { ra_no } = useParams(); //===>수정
  const navigate = useNavigate();

  //중복검사가 필요한 항목만 설정
  //const [dpCheck, setDpCheck] = useState({m_nname: "",});

  console.log("ra_no" + JSON.stringify("useParams" + JSON.stringify(ra_no))); //값을 못가져오는경우 main>route지정경로에서 파라미터 네임이 틀린경우이다
  const [originalInfo, setOriginalInfo] = useState();
  //const [withdrawComponent, setWithdrawComponent] = useState(<></>);

  //+++++++++++++++0. 위 faq_no 를 인수로 던져서 일단 가져와 출력한다 ===>수정 ++++++++++++++++++++
  const fetchData = async () => {
    //=====> 아래에서 useeffect로 호출해서   originalInfo를 업데이트시킨다
    //console.log("edit4444>faq_no"+ faq_no);
    const randomInfo = await getrandomInfo(ra_no);
    console.log("randominfo>no는", JSON.stringify(randomInfo.ra_no));
    setOriginalInfo({
      ra_no: randomInfo.ra_no,
      ra_subject: randomInfo.ra_subject,
      ra_detail: randomInfo.ra_detail,
      reg_date: randomInfo.reg_date ? dayjs(randomInfo.reg_date) : "",
      view_yn: randomInfo.view_yn,
    });

    console.log("userinfo2", randomInfo);
    //form.setFieldsValue(originalInfo);//===> 이부분이 있어야  각필드의 input에 값을  초기로딩시 출력할수 있음==> 초기로딩시 보여지지않고 save시에만 보여 아래  useEffect안에 넣어해결
  };

  useEffect(() => {
    //=====> 제일먼저호출
    fetchData();
    //초기값 설정
    //console.log(navigate);
  }, []);

  //+++++++++++++++0. 위 faq_no 를 인수로 던져서 일단 가져와 출력한다  end===>수정 ++++++++++++++++++++

  /*/받아온 정보로 use_state=2  탈퇴 로 변경되었다면 form에 삽입*/
  useEffect(() => {
    form.setFieldsValue(originalInfo); //===> 이부분이 있어야  각필드의 input에 값을  초기로딩시 출력할수 있음==> 초기로딩시 보여지지 않는 문제 해결
    //초기에 페이지 로드시 데이타를 1차로 업데이트 상태변경시 form에 데이타를 출력하기 위한 구문임
  }, [originalInfo]);

  //++++++++++1.//onFinish  하면 form 의 name값이 values로 넘어간다/originalInfo=> 초기로딩시 select한값 이고 values는 입력박스의 값들의 배열변수 이다 ===>수정+++++++++
  const onFinish = async (values) => {
    const formValues = {
      //form에서 넘겨받은  values를 나열후 뒤에 ra_no는 앞부분배열순서에 reg_date필드에 다시 날짜포멧을 변경후 뒷순서배열에 리턴한다 ( 수정된것이든 안되든 무조건 반환)
      ...values,
      reg_date: dayjs(values.reg_date).format("YYYY-MM-DD"),
      //pic_route: values.pic_route.file.url,
    };
    console.log("form에서 넘겨받은값" + JSON.stringify(formValues)); //   console.log('form에서 넘겨받은값'+JSON.stringify(values.no_subject))
    console.log("초기 select한값" + JSON.stringify(originalInfo)); //   console.log('form에서 넘겨받은값'+JSON.stringify(values.no_subject))

    const result = await updaterandom(formValues, ra_no); //==>수정
    console.log("result message1" + JSON.stringify(result));
    //console.log('node에서 받은값'+ JSON.stringify(result.accepetdata));
    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("수정이 완료되었습니다.");
        navigate("/random/detail/" + ra_no);
        break;
      case "exist nname":
        message.error("이미 존재하는 닉네임입니다.");
        break;
    }
  }; //+++++++++++++++++++++++onfinish   end+++++++++++++++++++++++++++

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/service/random" style={{ color: "#333333" }}>
            서비스관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/service/random" style={{ color: "#333333" }}>
            랜덤카피관리 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item reloadDocument style={{ color: "#333333" }}>
          <Link reloadDocument style={{ color: "#333333" }}>
            랜덤카피수정
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea" style={{ border: "0px solid #333" }}>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }} //라밸폭비율
          wrapperCol={{ span: 20 }} //input box 폭 비율
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages} // 이부분이있어야 아래 요소들의 rules를 자동으로 검사함
          style={{ textAlign: "left", border: "0px solid #333" }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="제목"
                name="ra_subject"
                hasFeedback
                rules={[{ required: true }, { min: 5, max: 80 }]}
                style={{ textAlign: "left", border: "0px solid #333" }}
                //validateStatus={dpCheck.m_id}
              >
                <Input
                  allowClear
                  maxLength={80}
                  //onChange={() => initCheck("m_id")}
                />
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left", border: "0px solid #333" }}
                label="내용입력"
                labelAlign="right"
                name="ra_detail"
                hasFeedback
                rules={[{ required: true }, { min: 5, max: 500 }]}
                //validateStatus={dpCheck.faq_detail}
              >
                <TextArea
                  placeholder="Controlled autosize"
                  maxLength={500}
                  autoSize={{ minRows: 10, maxRows: 15 }}
                />
              </Form.Item>
              <Form.Item
                label="출력여부"
                name="view_yn"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={1}>{printornot[1]}</Radio>
                  <Radio value={2}>{printornot[2]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                {/*<Button type='primary' htmlType="submit" style={{ width:300,height:100,textAlign:'center',border:'1px solid #fff',borderRadius:15}}>
           htmlType="submit"클릭하면-> onFinish(values)함수를 호출해서  Form.Items 의 name값들이 values인수에 전달되어 db에 저장된다
           */}

                <Space wrap>
                  <Button type="primary" htmlType="submit">
                    수정
                  </Button>
                  <Button onClick={() => navigate(-1)}>취소</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Content>
  );
};

export default Randommodify;
