import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const gridStyle: React.CSSProperties = {
  width: "50%",
  textAlign: "center",
  padding: "10px",
  border: "none",
  boxShadow: "none",
};

const DashboardCard = ({ icon, title, content, link, theme }) => {
  const { titles, values } = content;
  const [cardBackground, setCardBackground] = useState({
    background: "#f1f1f1",
  });
  const [cardColor, setCardColor] = useState({
    color: "#000000",
  });

  useEffect(() => {
    if (theme === "dark") {
      console.log(theme);
      setCardBackground({
        background: "#4c4c4c",
      });
      setCardColor({
        color: "#ffffff",
      });
    }
  }, [theme]);

  return (
    <>
      <div style={{ padding: "10px" }}>
        <Row gutter={24}>
          <Col span={20}>
            <Typography style={{ fontWeight: "bold", fontSize: 17 ,border:'0px solid red'}}>
              {title}
            </Typography>
          </Col>
          <Col span={4} style={{ paddingTop: "4px",border:'0px solid red' }}>
            <Link to={link}>{/*"/faq/detail/" + result.faq_no*/}
              <RightCircleOutlined />
            </Link>
          </Col>
        </Row>
      </div>
      <Card bordered={false} style={{ ...cardBackground, padding: "15px 0",border:'0px solid red'  }}>
        <Card.Grid hoverable={false} style={gridStyle}>
          {icon}
        </Card.Grid>
        <Card.Grid hoverable={false} style={gridStyle}>
          <Statistic
            title={
              <Typography style={{ fontSize: 13, ...cardColor }}>
                {titles[0]}
              </Typography>
            }
            value={values[0]}
            valueStyle={{ ...cardColor, fontSize: 20, fontWeight: "bold" }}
          />
        </Card.Grid>
        <Card.Grid hoverable={false} style={gridStyle}>
          <Statistic
            title={
              <Typography style={{ fontSize: 15, ...cardColor }}>
                {titles[1]}
              </Typography>
            }
            value={values[1]}
            valueStyle={{
              fontSize: 25,
              fontWeight: "bold",
              ...cardColor,
            }}
          />
        </Card.Grid>
        <Card.Grid hoverable={false} style={gridStyle}>
          <Statistic
            title={
              <Typography style={{ fontSize: 13, ...cardColor }}>
                {titles[2]}
              </Typography>
            }
            value={values[2]}
            valueStyle={{
              fontSize: 20,
              fontWeight: "bold",
              ...cardColor,
            }}
          />
        </Card.Grid>
      </Card>
    </>
  );
};

export default DashboardCard;
