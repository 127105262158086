import { request } from "../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지
import {
  userGradeInfo,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  outReason,
  userState,
  genderInfo,
  qnaornot,
} from "../config/staticInfo";
import {
  Link,
  useParams,
  useNavigate,
  Navigate,
  Redirect,
} from "react-router-dom";
import dayjs from "dayjs";
import { message, Typography } from "antd";

//+++++++++++++++++++++++++++++++++++++++이하 수정은 호출페이지에서 요청시 한개의 함수씩 수정할것+++++++++++++++++++++++++++
const { Paragraph, Text } = Typography;

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

const datetimeFormatmonth = (date) => {
  return date ? dayjs(date).format("YYYY-M") : "";
};

//유저 메인박스 통계
export const userstatic1 = async () => {
  return request("POST", `/dashboard/staticuser`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("유저통계정보result", result);
    return result;
  });
};

//카드 메인박스통계
export const cardstatic1 = async () => {
  return request("POST", `/dashboard/staticcard`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("카드통계정보result", result);
    return result;
  });
};

//폴더 메인박스통계
export const foldstatic1 = async () => {
  return request("POST", `/dashboard/staticfold`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("폴더통계정보result", result);
    return result;
  });
};

//폴더 게임박스통계
export const gamestatic1 = async () => {
  return request("POST", `/dashboard/staticgame`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("게임통계정보result", result);
    return result;
  });
};

export const foldrank1 = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/dashboard/foldrank1", JSON.stringify(tableParams))
    .then((res) => {
      console.log(
        "반환온파람foldrank1" + JSON.stringify(res.data.foldranklist)
      );
      //리스트에 사용 가능하도록 리스트 맵핑
      const foldranklist1 = res.data.foldranklist;
      const foldranklist = res.data.foldranklist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          ranking: <div style={{ fontWeight: "bold" }}>{result.ranking}</div>,
          num: result.num,
          fo_name: result.fo_name ? result.fo_name : "없음 ",
        };
      });
      return { list: foldranklist, list1: foldranklist1 }; //, total: res.data.memberTotal.total  부분을 삭제++++++++++++++++수정+++++++++++++++
      //return ('ssss');//++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const sharefoldrank1 = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/dashboard/sharefoldrank1",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log(
        "반환온파람foldrank1" + JSON.stringify(res.data.sharefoldranklist)
      );
      //리스트에 사용 가능하도록 리스트 맵핑
      const sharefoldranklist1 = res.data.sharefoldranklist;
      const sharefoldranklist = res.data.sharefoldranklist.map(
        (result, idx) => {
          // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
          return {
            key: idx,
            ranking: <div style={{ fontWeight: "bold" }}>{result.ranking}</div>,
            num: result.num,
            fo_name: result.fo_name,
          };
        }
      );
      return { list: sharefoldranklist, list1: sharefoldranklist1 }; //, total: res.data.memberTotal.total  부분을 삭제++++++++++++++++수정+++++++++++++++
      //return ('ssss');//++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const gamedaystatic1 = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/dashboard/gamedaystatic1",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log(
        "반환온파람게임생성" + JSON.stringify(res.data.gamedaystaticlist)
      );
      //리스트에 사용 가능하도록 리스트 맵핑
      const gamedaystaticlist = res.data.gamedaystaticlist.map(
        (result, idx) => {
          // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
          return {
            key: idx,
            num: result.num,
            daytotal: result.daytotal,
            savedate: result.savedate,
          };
        }
      );
      return { list: gamedaystaticlist }; //, total: res.data.memberTotal.total  부분을 삭제++++++++++++++++수정+++++++++++++++
      //return ('ssss');//++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};
export const getRecentNotice = async () => {
  //++++++++++++++++수정+++++++++++++++
  return request("POST", "/dashboard/recentnotice")
    .then((res) => {
      console.log("noticeList" + JSON.stringify(res.data.noticeList));
      //리스트에 사용 가능하도록 리스트 맵핑
      const list = res.data.noticeList.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          num: <div style={{ fontWeight: "bold" }}>{result.no_no}</div>,
          no_subject: (
            <Link to={"/service/notice/detail/" + result.no_no}>
              {result.no_subject}
            </Link>
          ),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: list }; //, total: res.data.memberTotal.total  부분을 삭제++++++++++++++++수정+++++++++++++++
      //return ('ssss');//++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const qnastatic1 = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/dashboard/qnastatic1", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온1:1생성" + JSON.stringify(res.data.qnastaticlist));
      //리스트에 사용 가능하도록 리스트 맵핑
      const qnastaticlist = res.data.qnastaticlist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          qa_subject: (
            <Link to={"/service/qna/detail/" + result.qa_no}>
              {result.qa_subject}
            </Link>
          ),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
          ans_yn: qnaornot[result.ans_yn],
        };
      });
      return { list: qnastaticlist }; //, total: res.data.memberTotal.total  부분을 삭제++++++++++++++++수정+++++++++++++++
      //return ('ssss');//++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

//유저 상세정보 가져오기
export const getTerms = async () => {
  return request("POST", `/user/terms`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const termsInfo = res.data;
    console.log("userinfo1", termsInfo);
    return termsInfo;
  });
};

//유저 등록
export const updateTerms = async (params) => {
  return request("POST", "/user/updateTerms/", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//유저 로그 리스트 가져오기
export const getUserLogList = async (tableParams) => {
  return request("POST", "/user/logList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          key: idx,
          us_no: result.us_no,
          m_id: result.m_id,
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: logList, total: res.data.logTotal.total };
    })
    .catch((err) => console.log(err));
};
