import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Col, Button, Row, Space, Image, List } from "antd";
import { useParams, Link } from "react-router-dom";
import { getgamedetail, getgamedetail1 } from "../../service/gameService"; //===>수정
import ReviewCard from "../../components/ReviewCard";
import { useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import "../../css//Totalstyle.css";
import { SearchOutlined, StarOutlined, StarFilled } from "@ant-design/icons";
import { request } from "../../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지  삭제후 이동이 안되 여기서 직접 axios로 node를 호출
import { message } from "antd";

const { Content } = Layout;

const Gamelistdetail = () => {
  const navigate = useNavigate();
  const { gh_index, m_id } = useParams(); //===>수정
  const [data, setData] = useState();
  const [data1, setData1] = useState([]);
  const [backUrl, setbackUrl] = useState("/ipblock"); //===> 수정 목록버튼클릭후 이동으로 변경했으나

  console.log("backUrl" + JSON.stringify(backUrl));
  //console.log("ip_no"+ JSON.stringify(useParams()));//값을 못가져오는경우 main>route지정경로에서 파라미터 네임이 틀린경우이다
  const fetchData = async () => {
    //===>수정
    const getgameinfo = await getgamedetail(gh_index, m_id); //==>수정
    const getcardinfo = await getgamedetail1(gh_index, m_id); //==>수정       list: cardinfolist,
    console.log("getgameinfo", JSON.stringify(getgameinfo, null, 2)); //==>수정
    console.log("getcardinfo11", JSON.stringify(getcardinfo)); //==>수정///값을 못가져옴
    setData(getgameinfo); //==>수정
    setData1(getcardinfo); //==>수정
    //setData(getgameinfo);//==>수정
    console.log("data1", JSON.stringify(data1, null, 2)); //==>수정
  };

  useEffect(() => {
    sessionStorage.setItem("backUrl", `/game/detail/${gh_index}/${m_id}`);

    fetchData();

    //상세 페이지를 회원목록, 탈퇴목록에서 사용하기위한 목록 버튼 URL
    setbackUrl(sessionStorage.getItem("backUrl"));
  }, []);

  const onRemove = () => {
    if (window.confirm("삭제 하시겠습니까? 삭제하시면 복구가 불가능합니다.")) {
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        //삭제처리
        alert("삭제되었습니다.");
      }
    }
  };

  const deletepopup = async (gh_index) => {
    //==>수정 이하////  nodeserver로 삭제요청함/삭제후 이동이 안되 여기서 직접 axios로 node를 호출
    console.log("삭제번호넘긴값>" + JSON.stringify(gh_index));

    if (gh_index) {
      if (
        window.confirm(
          gh_index + "를 삭제 하시겠습니까? 삭제하시면 복구가불가능합니다"
        )
      ) {
        return request("DELETE", `/popup/delete/${gh_index}`)
          .then((res) => {
            console.log("삭제결과>" + JSON.stringify(res.data.message));

            //res?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${ip_no}`" />// 삭제후 이동안됨

            if (res.data.message === "success") {
              //const history = useHistory();
              alert("삭제되었습니다");
              navigate("/popup"); //

              //history.push('/notice');// 삭제후 이동안됨
              //navigate("/notice");// 삭제후 이동안됨
              //res.data.message==='success'?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${ip_no}`" />
              //<Redirect to={{pathname: '/notice', }}/>
            }
            //<Navigate to="http://localhost:3000/notice/" /> 삭제후이동
            return res.data;
          })
          .catch((err) => console.log(err));
      }
    } else {
      message.error("선택한 항목이 없습니다.");
      return false;
    }
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/game" style={{ color: "#333333" }}>
            복습게임기록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            복습게임기록 상세
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Row style={{ border: "0px solid #333", marginBottom: 20 }}>
          {/*gutter={[컬럼왼쪽 마진, 컬럼 상하마진]} */}
          {/*//data가있으면 for문처첨 반복출력을 하는데   팝업이미지라는 name값이있으면 이미지를 출력해주고 기리고 나머지를 출력해준다*/}
          <Col span={24}>
            <div
              style={{
                width: "100%",
                height: 30,
                border: "0px solid #ebebeb",
                paddingLeft: 35,
                marginTop: 30,
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              게임진행정보
            </div>
          </Col>
          {data?.map((result, idx) => {
            return (
              <>
                <Col span={3} className="rightTitle">
                  <div
                    style={{
                      position: "relative",
                      float: "right",
                      border: "0px solid #333",
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      backgroundColor: "#fff",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    {result.name}
                  </div>
                </Col>
                <Col span={21}>
                  <div
                    style={{
                      position: "relative",
                      float: "left",
                      width: "80%",
                      border: "0px solid #333",
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      backgroundColor: "#fff",
                    }}
                  >
                    {result.value}
                  </div>
                </Col>
              </>
            );
          })}
          <Col span={24}>
            <div
              style={{
                width: "100%",
                height: 30,
                border: "0px solid #ebebeb",
                paddingLeft: 35,
                marginTop: 30,
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              게임카드목록
            </div>
          </Col>
          <Col span={24}>
            <List
              grid={{ gutter: 24 }}
              dataSource={data1}
              renderItem={(item) => (
                <List.Item style={{ width: 170 }}>
                  <ReviewCard cardInfo={item} onSetData={setData} flag="game" />
                </List.Item>
              )}
            ></List>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space wrap>
              {/*  <Button  className="editButton" size="middle"  onClick={() => navigate(`/popup/modify/${gh_index}`)}
              >
                수정
              </Button>
              <Button danger size="middle" onClick={() => deletepopup(gh_index)}>
                삭제
              </Button>
              */}
              <Button
                size="middle"
                type="default"
                style={{ backgroundColor: "", color: "" }}
                onClick={() => navigate("/game", gh_index)}
              >
                목록
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Gamelistdetail;
