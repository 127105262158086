import React, { Component, useState } from "react";
import { Layout, Breadcrumb } from "antd";
import { Route, Routes } from "react-router-dom";
import "../App.css";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import SideMenu from "./SideMenu";
import Dashboard from "../pages/Dashboard";

import UserList from "../pages/user/UserList";
import UserRegist from "../pages/user/UserRegist";
import UserWithdraw from "../pages/user/UserWithdraw";
import UserDetail from "../pages/user/UserDetail";
import Terms from "../pages/user/Terms";
import UserLog from "../pages/user/UserLog";
import UserModify from "../pages/user/UserModify";
import CardList from "../pages/card/CardList";
import NotFound from "../pages/error/NotFound";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

import CardRegist from "../pages/card/CardRegist";
import CardDetail from "../pages/card/CardDetail";
import CardModify from "../pages/card/CardModify";
import WrongCardList from "../pages/card/WrongCardList";
import LikeCardList from "../pages/card/LikeCardList";
import FolderList from "../pages/folder/FolderList";
import FolderDetail from "../pages/folder/FolderDetail";
import PublicFolderList from "../pages/folder/PublicFolderList";
import FolderRegist from "../pages/folder/FolderRegist";
import FolderModify from "../pages/folder/FolderModify";
import Gamelist from "../pages/game/Gamelist";
import Gamelistdetail from "../pages/game/Gamelistdetail";
import Pushsend from "../pages/push/Pushsend";
import Pushlist from "../pages/push/Pushlist";
import Pushdetail from "../pages/push/Pushdetail";
import Pushbooklist from "../pages/push/Pushbooklist";
import Pushregist from "../pages/push/Pushregist";
import Pushmessagelist from "../pages/push/Pushmessagelist";
import Pushmdetail from "../pages/push/Pushmdetail";
import Pushmmodify from "../pages/push/Pushmmodify";
import Randomlist from "../pages/random/Randomlist";
import Randomdetail from "../pages/random/Randomdetail";
import Randommodify from "../pages/random/Randommodify";
import Randomregist from "../pages/random/Randomregist";
import Popuplist from "../pages/popup/Popuplist";
import Popupdetail from "../pages/popup/Popupdetail";
import Popupmodify from "../pages/popup/Popupmodify";
import Popupregist from "../pages/popup/Popupregist";
import Qnalist from "../pages/qna/Qnalist";
import Qnadetail from "../pages/qna/Qnadetail";
import Faqlist from "../pages/faq/Faqlist";
import Faqdetail from "../pages/faq/Faqdetail";
import Faqmodify from "../pages/faq/Faqmodify";
import Faqregist from "../pages/faq/Faqregist";
import Surveylist from "../pages/survey/Surveylist";
import Surveydetail from "../pages/survey/Surveydetail";
import Surveymodify from "../pages/survey/Surveymodify";
import Surveyregist from "../pages/survey/Surveyregist";
import Surveyanslist from "../pages/surveyans/Surveyanslist";
import Surveyansdetail from "../pages/surveyans/Surveyansdetail";
import Noticelist from "../pages/notice/Noticelist";
import Noticedetail from "../pages/notice/Noticedetail";
import Noticemodify from "../pages/notice/Noticemodify";
import Noticeregist from "../pages/notice/Noticeregist";
import Ipblocklist from "../pages/ipblock/Ipblocklist";
import Ipblockdetail from "../pages/ipblock/Ipblockdetail";
import Ipblockmodify from "../pages/ipblock/Ipblockmodify";
import Ipblockregist from "../pages/ipblock/Ipblockregist";
import Reportlist from "../pages/report/Reportlist";
import Reportdetail from "../pages/report/Reportdetail";
import Utimedaylist from "../pages/static/Utimedaylist";
import Utimemonthlist from "../pages/static/Utimemonthlist";
import Cardstaticlist from "../pages/static/Cardstaticlist";
import Cardstaticday from "../pages/static/Cardstaticday";
import Cardstaticmonth from "../pages/static/Cardstaticmonth";
import Foldstaticlist from "../pages/static/Foldstaticlist";
import Foldstaticday from "../pages/static/Foldstaticday";
import Foldstaticmonth from "../pages/static/Foldstaticmonth";
import Gamestaticlist from "../pages/static/Gamestaticlist";
import Gamestaticday from "../pages/static/Gamestaticday";
import Gamestaticmonth from "../pages/static/Gamestaticmonth";
import Foldrank from "../pages/static/Foldrank";
import Sharefoldrank from "../pages/static/Sharefoldrank";
import Tutoriallist from "../pages/tutorial/Tutoriallist";
import Tutorialdetail from "../pages/tutorial/Tutorialdetail";
import Tutorialmodify from "../pages/tutorial/Tutorialmodify";
import Tutorialregist from "../pages/tutorial/Tutorialregist";
import Fomulalist from "../pages/fomula/Fomulalist";
import Fomuladetail from "../pages/fomula/Fomuladetail";
import Fomulamodify from "../pages/fomula/Fomulamodify";
import Fomularegist from "../pages/fomula/Fomularegist";
import Foldnamelist from "../pages/foldname/Foldnamelist";
import Foldnamedetail from "../pages/foldname/Foldnamedetail";
import Foldnamemodify from "../pages/foldname/Foldnamemodify";
import Foldnameregist from "../pages/foldname/Foldnameregist";
import Moreviewdetail from "../pages/moreview/Moreviewdetail";
import Cardfomula from "../pages/card/Cardfomula";
const { Header, Sider, Footer } = Layout;

//1.아래처럼 router를 추가한다 2.component>sidemenu에 왼쪽메뉴를 추가한다 3. 페이지로드가 안되면 app의 하단에 keyname값을 등록한다

const Main = (props) => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
        border: "0px solid orange",
        marginBottom: 15,
      }}
    >
      {" "}
      {/*헤더 사이드바 컨텐츠 영역 antd ui라이브러리의 경우 이런식으로 출력됨, 페이지는 컨텐츠영역부분만 만들면됨*/}
      <Header
        style={{
          background: "#fff",
          padding: 0,
          border: "0px solid red",
          marginBottom: 0,
          borderBottom: "1px solid #f8f8f8",
          boxShadow: "1px 1.5px 5px 1px #ebebeb",
        }}
      >
        <AppHeader />
        {/*해더, 로그인 관련 처리 필요*/}
      </Header>
      <Layout style={{ border: "0px solid orange", marginBottom: 15 }}>
        {" "}
        {/*컨텐츠 영역*/}
        {/*사이드 메뉴*/}
        <Sider
          style={{
            border: "0px solid orange",
            marginBottom: 0,
            marginTop: 15,
            paddingTop: 15,
            borderRadius: 20,
          }}
        >
          <SideMenu />
        </Sider>
        <Routes>
          {/*대시보드*/}
          <Route exact path="" element={<Dashboard />} />

          {/*유저 관리*/}
          <Route exact path="user" element={<UserList />} />
          <Route exact path="user/detail/:m_no" element={<UserDetail />} />
          <Route exact path="user/modify/:m_no" element={<UserModify />} />
          <Route exact path="user/regist" element={<UserRegist />} />
          <Route exact path="user/withdraw" element={<UserWithdraw />} />
          <Route exact path="user/terms" element={<Terms />} />
          <Route exact path="user/log" element={<UserLog />} />

          {/*복습카드 관리*/}
          <Route exact path="card" element={<CardList />} />
          <Route exact path="card/regist" element={<CardRegist />} />
          <Route exact path="card/detail/:ca_no" element={<CardDetail />} />
          <Route exact path="card/modify/:ca_no" element={<CardModify />} />
          <Route exact path="card/wrong" element={<WrongCardList />} />
          <Route exact path="card/favorite" element={<LikeCardList />} />

          {/*복습폴더 관리,공유폴더관리*/}
          <Route exact path="folder" element={<FolderList />} />
          <Route
            exact
            path="folder/detail/:fo_index"
            element={<FolderDetail />}
          />
          <Route exact path="folder/public" element={<PublicFolderList />} />
          <Route exact path="folder/regist" element={<FolderRegist />} />
          <Route
            exact
            path="folder/modify/:fo_index"
            element={<FolderModify />}
          />

          {/*복습게임기록관리*/}
          <Route exact path="game" element={<Gamelist />} />
          <Route
            exact
            path="game/detail/:gh_index/:m_id"
            element={<Gamelistdetail />}
          />

          {/*푸시알림관리*/}
          <Route exact path="push" element={<Pushsend />} />
          <Route exact path="push/pushlist" element={<Pushlist />} />
          <Route exact path="push/detail/:pm_no" element={<Pushdetail />} />
          <Route exact path="push/pushbooklist" element={<Pushbooklist />} />
          <Route exact path="push/regist" element={<Pushregist />} />
          <Route exact path="push/message" element={<Pushmessagelist />} />
          <Route exact path="push/mdetail/:pm_no" element={<Pushmdetail />} />
          <Route exact path="push/mmodify/:pm_no" element={<Pushmmodify />} />

          {/* 서비스> 랜덤카피관리*/}
          <Route exact path="service/random" element={<Randomlist />} />
          <Route
            exact
            path="service/random/detail/:ra_no"
            element={<Randomdetail />}
          />
          <Route
            exact
            path="service/random/modify/:ra_no"
            element={<Randommodify />}
          />
          <Route
            exact
            path="service/random/regist"
            element={<Randomregist />}
          />
          {/* 서비스> 팝업관리*/}
          <Route exact path="service/popup" element={<Popuplist />} />
          <Route
            exact
            path="service/popup/detail/:pop_no"
            element={<Popupdetail />}
          />
          <Route
            exact
            path="service/popup/modify/:pop_no"
            element={<Popupmodify />}
          />
          <Route exact path="service/popup/regist" element={<Popupregist />} />
          {/* 서비스> 1:1문의관리*/}
          <Route exact path="service/qna" element={<Qnalist />} />
          <Route
            exact
            path="service/qna/detail/:qa_no"
            element={<Qnadetail />}
          />
          {/* 서비스> faq관리*/}
          <Route exact path="service/faq" element={<Faqlist />} />
          <Route
            exact
            path="service/faq/detail/:faq_no"
            element={<Faqdetail />}
          />
          <Route
            exact
            path="service/faq/modify/:faq_no"
            element={<Faqmodify />}
          />
          <Route exact path="service/faq/regist" element={<Faqregist />} />

          {/* 서비스> 설문관리*/}
          <Route exact path="service/survey" element={<Surveylist />} />
          <Route
            exact
            path="service/survey/detail/:sv_no"
            element={<Surveydetail />}
          />
          <Route
            exact
            path="service/survey/modify/:sv_no"
            element={<Surveymodify />}
          />
          <Route
            exact
            path="service/survey/regist"
            element={<Surveyregist />}
          />
          {/* 서비스> 설문참여자관리*/}
          <Route exact path="service/surveyans" element={<Surveyanslist />} />
          <Route
            exact
            path="service/surveyans/:sv_no"
            element={<Surveyanslist />}
          />

          {/* 서비스> 공지사항*/}
          <Route exact path="service/notice" element={<Noticelist />} />
          <Route
            exact
            path="service/notice/detail/:no_no"
            element={<Noticedetail />}
          />
          <Route
            exact
            path="service/notice/modify/:no_no"
            element={<Noticemodify />}
          />
          <Route
            exact
            path="service/notice/regist"
            element={<Noticeregist />}
          />
          {/* 서비스> ipblock*/}
          <Route exact path="service/ipblock" element={<Ipblocklist />} />
          <Route
            exact
            path="service/ipblock/detail/:ip_no"
            element={<Ipblockdetail />}
          />
          <Route
            exact
            path="service/ipblock/modify/:ip_no"
            element={<Ipblockmodify />}
          />
          <Route
            exact
            path="service/ipblock/regist"
            element={<Ipblockregist />}
          />
          {/* 서비스> 신고카드관리*/}
          <Route exact path="service/report" element={<Reportlist />} />
          <Route
            exact
            path="service/report/detail/:ba_no"
            element={<Reportdetail />}
          />
          {/* 서비스> 더보기관리*/}
          <Route exact path="service/moreview" element={<Moreviewdetail />} />

          {/* 통계> */}
          <Route exact path="log" element={<Utimedaylist />} />
          <Route exact path="static" element={<Cardstaticday />} />
          <Route exact path="log/utimedaylist" element={<Utimedaylist />} />
          <Route exact path="log/utimemonthlist" element={<Utimemonthlist />} />
          <Route exact path="log/cardstaticlist" element={<Cardstaticlist />} />
          <Route
            exact
            path="static/cardstaticday"
            element={<Cardstaticday />}
          />
          <Route
            exact
            path="static/cardstaticmonth"
            element={<Cardstaticmonth />}
          />
          <Route exact path="log/foldstaticlist" element={<Foldstaticlist />} />
          <Route
            exact
            path="static/foldstaticday"
            element={<Foldstaticday />}
          />
          <Route
            exact
            path="static/foldstaticmonth"
            element={<Foldstaticmonth />}
          />
          <Route exact path="log/gamestaticlist" element={<Gamestaticlist />} />
          <Route
            exact
            path="static/gamestaticday"
            element={<Gamestaticday />}
          />
          <Route
            exact
            path="static/gamestaticmonth"
            element={<Gamestaticmonth />}
          />
          <Route exact path="static/foldrank" element={<Foldrank />} />
          <Route
            exact
            path="static/sharefoldrank"
            element={<Sharefoldrank />}
          />
          {/* 튜토리얼관리*/}
          <Route exact path="tutorial" element={<Tutoriallist />} />
          <Route
            exact
            path="tutorial/detail/:tu_no"
            element={<Tutorialdetail />}
          />
          <Route
            exact
            path="tutorial/modify/:tu_no"
            element={<Tutorialmodify />}
          />
          <Route exact path="tutorial/regist" element={<Tutorialregist />} />
          {/* 회원구분별폴더명관리/ app> 폴더등록시 회원구분별 등록된 폴더리스트를 선택할수 있게한다*/}
          <Route exact path="foldname" element={<Foldnamelist />} />
          <Route
            exact
            path="foldname/detail/:fn_no"
            element={<Foldnamedetail />}
          />
          <Route
            exact
            path="foldname/modify/:fn_no"
            element={<Foldnamemodify />}
          />
          <Route exact path="foldname/regist" element={<Foldnameregist />} />
          {/* 공식관리*/}
          <Route exact path="fomula" element={<Fomulalist />} />
          <Route
            exact
            path="fomula/detail/:fom_no"
            element={<Fomuladetail />}
          />
          <Route
            exact
            path="fomula/modify/:fom_no"
            element={<Fomulamodify />}
          />
          <Route exact path="fomula/regist" element={<Fomularegist />} />

          {/* 회원구분별폴더명관리/ app> 폴더등록시 회원구분별 등록된 폴더리스트를 선택할수 있게한다*/}
          <Route exact path="foldname" element={<Foldnamelist />} />
          <Route
            exact
            path="foldname/detail/:fn_no"
            element={<Foldnamedetail />}
          />
          <Route
            exact
            path="foldname/modify/:fn_no"
            element={<Foldnamemodify />}
          />
          <Route exact path="foldname/regist" element={<Foldnameregist />} />

          {/*카드등록시 공식입력 팝업
          <Route exact path="card/Cardfomula" element={<Cardfomula />} />*/}
          {/*에러페이지*/}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
      <Footer style={{ padding: 0, border: "0px solid red", marginBottom: 15 }}>
        <AppFooter />
      </Footer>
    </Layout>
  );
};

export default Main;
