import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  List,
  Space,
  DatePicker,
  Input,
  Select,
  message,
  Divider,
  Checkbox,
  Radio,
} from "antd";
import { FileExcelOutlined, ReloadOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import ReviewCard from "../../components/ReviewCard";
import {
  getCardList,
  getCardFullList,
  deleteCard,
} from "../../service/cardService";
import { getFolderInfo } from "../../service/folderService";

import dayjs from "dayjs";
import { useSelector } from "react-redux";

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

const FolderDetail = () => {
  const { user } = useSelector((state) => state.auth); ///==>무슨의미? 어디서 호출?
  const navigate = useNavigate();
  const { fo_index } = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dateRange, setDateRange] = useState(["", ""]);
  const [selectFlag, setSelectFlag] = useState();
  const [flagValue, setFlagValue] = useState(1);

  const [faviValue, setFaviValue] = useState(0);
  const [useValue, setUseValue] = useState(0);
  const [reportValue, setReportValue] = useState(0);

  const [searchTarget, setSearchTarget] = useState();
  const [dateTarget, setDateTarget] = useState();
  const [folderInfo, setFolderInfo] = useState();
  const [csvComponent, setCsvCompnent] = useState(<></>);
  const [data, setData] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  //기본 테이블 설정
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 15,
    },
    board: "folder",
    fo_index: fo_index,
  });

  //날짜 선택시
  const onSearchDateChange = (dates, dateStrings) => {
    //console.log(dateStrings);
    setDateRange(dateStrings);
  };

  useEffect(() => {
    //뒤로갈 경로 저장
    sessionStorage.setItem("backUrl", "/folder/detail/" + fo_index);
    fetchFolderData();
  }, []);

  const fetchFolderData = async () => {
    const info = await getFolderInfo(fo_index);
    setFolderInfo(info);
  };

  useEffect(() => {
    setIndeterminate(
      !!selectedRowKeys.length && selectedRowKeys.length < data.length
    );
    setCheckAll(selectedRowKeys.length === data.length);
    //console.log(selectedRowKeys);
  }, [selectedRowKeys]);

  //선택조건 선택시
  const onSelectFlag = (value, option) => {
    setSelectFlag(value);
  };
  const onChangeFlagValue = (e, option) => {
    setFlagValue(e.target.value);
  };

  const onChangeFaviValue = (e, option) => {
    setFaviValue(e.target.value);
    if (e.target.value > 0) {
      setTableParams({
        ...tableParams,
        filters: {
          ...tableParams.filters,
          ca_liked: e.target.value,
        },
      });
    } else {
      setTableParams({
        ...tableParams,
        filters: {
          ...tableParams.filters,
          ca_liked: [1, 2],
        },
      });
    }
  };
  const onChangeUseValue = (e, option) => {
    setUseValue(e.target.value);
    if (e.target.value > 0) {
      setTableParams({
        ...tableParams,
        filters: {
          ...tableParams.filters,
          del_yn: e.target.value,
        },
      });
    } else {
      setTableParams({
        ...tableParams,
        filters: {
          ...tableParams.filters,
          del_yn: [1, 2],
        },
      });
    }
  };
  const onChangeReportValue = (e, option) => {
    setReportValue(e.target.value);
    if (e.target.value > 0) {
      setTableParams({
        ...tableParams,
        filters: {
          ...tableParams.filters,
          bad_yn: e.target.value,
        },
      });
    } else {
      setTableParams({
        ...tableParams,
        filters: {
          ...tableParams.filters,
          bad_yn: [1, 2],
        },
      });
    }
  };

  //검색조건 선택시
  const onSelectSearch = (value, option) => {
    setSearchTarget(value);
  };
  const onSelectSearchDate = (value, option) => {
    setDateTarget(value);
  };

  const onSearch = (value) => {
    if (
      (value !== "" && searchTarget) ||
      (dateRange[0] !== "" && dateRange[1] !== "") ||
      selectFlag
    ) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: 1,
        },
        searchDate: dateRange,
        dateTarget: dateTarget,
        searchParam: { target: searchTarget, value: value },
        //searchFlag: { target: selectFlag, value: flagValue },
      });
    } else if (
      value === "" &&
      !searchTarget &&
      dateRange[0] === "" &&
      dateRange[1] === "" &&
      !selectFlag
    ) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: 1,
        },
        searchDate: "",
        dateTarget: "",
        searchParam: "",
        searchFlag: "",
      });
    } else {
      message.error("검색하실 항목을 입력해주세요.");
    }
  };

  const onCheckAllChange = (e) => {
    //setCheckedList(e.target.checked ? plainOptions : []);
    setSelectedRowKeys(
      e.target.checked ? data?.map((value, idx) => value.ca_no) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  //유저 구분 변경
  const onUserGradeChange = (value) => {
    //console.log(`selected ${value}`);
    //tableparam 변경 - filter에 m_grade 추가
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        m_grade: value,
      },
    });
  };

  //카드 리스트 가져오기
  const fetchData = async () => {
    /*fetch(
      `//xn--pn3b68hmps.com/api/user/list?${qs.stringify(
        (tableParams)
      )}`
    )*/
    //console.log("tableParams", tableParams);
    const cardResult = await getCardList(tableParams);
    setData(cardResult.list);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: cardResult.total,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  //데이터 갱신
  const refreshData = () => {
    fetchData();
    //엑셀항목 초기화
    setCsvCompnent(<></>);
    //선택된 항목 초기화
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    refreshData();
    //console.log(tableParams);
  }, [JSON.stringify(tableParams)]);

  const handlePage = (page, pageSize) => {
    console.log("page chagne", page, pageSize);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: page,
        pageSize: pageSize,
      },
    });
  };

  const onListChange = (e) => {
    console.log(e);
    setTableParams({
      ...tableParams,
      extra: e.target.value,
    });
  };
  const downloadCSV = async () => {
    const { csv_data, csv_header } = await getCardFullList(tableParams);
    setCsvCompnent(
      <CSVLink
        data={csv_data}
        headers={csv_header}
        filename={dayjs().format("YYYYMMDD") + "_card_log.csv"}
      >
        <Button className="exelButton">
          <FileExcelOutlined />
          받기
        </Button>
      </CSVLink>
    );
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/folder" style={{ color: "#333333" }}>
            복습폴더
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/folder" style={{ color: "#333333" }}>
            복습폴더 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            폴더 카드 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => navigate(0)}
          ></Button>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Row gutter={16} style={{ paddingBottom: 10 }}>
          <Col
            span={24}
            style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}
          >
            {folderInfo?.fo_name}
          </Col>
        </Row>
        <Row gutter={16} style={{ paddingBottom: 0 }}>
          <Col
            span={24}
            style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}
          >
            <Radio.Group
              defaultValue="card"
              size="large"
              onChange={onListChange}
              style={{ width: "100%" }}
            >
              <Radio.Button value="card" style={{ width: "50%" }}>
                복습카드 목록
              </Radio.Button>
              <Radio.Button value="wrong" style={{ width: "50%" }}>
                틀린카드 목록
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col span={24}>
            <Space wrap>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                전체
              </Checkbox>

              {user.m_grade === 1 ? (
                <Button
                  danger
                  onClick={() => {
                    deleteCard(selectedRowKeys).then((result) => {
                      refreshData();
                    });
                  }}
                >
                  선택 삭제
                </Button>
              ) : (
                ""
              )}

              <Button onClick={downloadCSV}>현재 목록 엑셀로</Button>
              {csvComponent}
            </Space>
          </Col>
        </Row>
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col span={8} style={{ textAlign: "center" }}>
            <div>
              <div>즐겨찾기 여부</div>
              <Radio.Group
                options={[
                  { label: "모두", value: 0 },
                  { label: "예", value: 1 },
                  { label: "아니오", value: 2 },
                ]}
                onChange={onChangeFaviValue}
                value={faviValue}
                optionType="button"
              />
            </div>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <div>
              <div>사용여부</div>
              <Radio.Group
                options={[
                  { label: "모두", value: 0 },
                  { label: "예", value: 1 },
                  { label: "아니오", value: 2 },
                ]}
                onChange={onChangeUseValue}
                value={useValue}
                optionType="button"
              />
            </div>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <div>
              <div>신고여부</div>
              <Radio.Group
                options={[
                  { label: "모두", value: 0 },
                  { label: "예", value: 1 },
                  { label: "아니오", value: 2 },
                ]}
                onChange={onChangeReportValue}
                value={reportValue}
                optionType="button"
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space style={{ float: "right" }}>
              <Input.Group
                compact
                style={{ float: "left", minWidth: 330, paddingLeft: 10 }}
              >
                <Select
                  placeholder="검색필드"
                  allowClear
                  style={{ width: "30%" }}
                  onChange={onSelectSearch}
                >
                  <Option value="m_id">아이디</Option>
                  <Option value="ca_keyword">키워드</Option>
                  <Option value="ca_detail">카드 설명</Option>
                </Select>
                <Search
                  placeholder="검색어 입력"
                  allowClear
                  enterButton
                  defaultValue={""}
                  onSearch={onSearch}
                  style={{
                    width: "70%",
                  }}
                />
              </Input.Group>
            </Space>
            <Space style={{ float: "right", paddingLeft: 10 }}>
              <Input.Group compact>
                <Select
                  placeholder="검색일자"
                  style={{ minWidth: 120 }}
                  onChange={onSelectSearchDate}
                  defaultValue="reg_date"
                >
                  <Option value="reg_date">등록일</Option>
                </Select>
                <DatePicker.RangePicker
                  style={{ width: 240 }}
                  locale="ko_KR"
                  onChange={onSearchDateChange}
                />
              </Input.Group>
            </Space>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <List
              grid={{ gutter: 16 }}
              dataSource={data}
              pagination={{
                ...tableParams.pagination,
                onChange: handlePage,
                pageSizeOptions: ["15", "30", "60"],
                showSizeChanger: true,
              }}
              renderItem={(item) => (
                <List.Item style={{ width: 170 }}>
                  <ReviewCard
                    cardInfo={item}
                    onSelect={setSelectedRowKeys}
                    selectedRow={selectedRowKeys}
                    onSetData={setData}
                  />
                </List.Item>
              )}
            ></List>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default FolderDetail;
