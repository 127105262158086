import { request } from "../utils/axios";

export const authLogin = async (userid, password, ipinformation) => {
  // nodeserver의 index.js로넘겨 지정한 데이타 실행 파일로 이동해서 값을  리턴 받는다
  return request("POST", "/auth/login", {
    userid: userid,
    password: password,
    //ipinformation: ipinformation,
  })
    .then((res) => {
      //로그인 데이터 처리
      console.log(
        "login result",
        JSON.stringify(res.data) + sessionStorage.getItem("user")
      );
      //1.ip정보가없으면 등록된 ip가 아닙니다 관리자에게문의하세요  02-595~2222
      //2.ip정보가 있고 회원정보가 있으면 로그인
      //리턴받은 결과가  없으면  session을 null 저장하고 아니면 user[0]로 넘겨온 값을 세션에 저장한다
      //if(res.data.ipinfo[0].ipcount>0){
      if (res.data === 400) {
        alert(
          "아이디 or 비번이 틀립니다. 다시확인하시거나, 관리자에게문의하세요  02-595~2222"
        );
      } else if (res.data === 500) {
        alert("접속 권한이 없습니다.");
      } /* else if (
        res.data.ipinfo[0].ipcount < 1 ||
        res.data.ipinfo[0].ipcount === null ||
        res.data.ipinfo[0].ipcount === ""
      ) {
        alert(
          " 등록된 ip가없거나,아이디 or 비번이 틀립니다. 다시확인하시거나, 관리자에게문의하세요  02-595~2222 "
        );
        sessionStorage.setItem("user", null);
        return { isLogin: false, user: null };
      }*/ else {
        sessionStorage.setItem("user", JSON.stringify(res.data));
        alert("환영합니다. " + userid + "님");
        //alert('횐영합니다' + userid +'님, 회원님grade는:' + res.data.user.m_grade +'입니다');
        //console.log("모든회원정보" + JSON.stringify(res.data.user.m_grade));
        return { isLogin: true, user: res.data };
      }
      /*  
        if( res.data.ipinfo[0].ipcount<1 || res.data.ipinfo[0].ipcount===null || res.data.ipinfo[0].ipcount===''){
          alert(' 등록된 ip가아닙니다. 다시확인하시거나 관리자에게문의하세요  02-595~2222 ');
        }else if(res.data === "not match"){
        alert('아이디 or 비번이 틀립니다. 다시확인하시거나, 관리자에게문의하세요  02-595~2222 ');
        sessionStorage.setItem("user", null);
        return { isLogin: false, user: null };
        } else {
        sessionStorage.setItem("user", JSON.stringify(res.data));
        alert('횐영합니다' + userid +'님' +JSON.stringify(res.data));
        console.log('모든회원정보'+JSON.stringify(res.data.ipinfo[0].ipcount))
        return { isLogin: true, user: res.data };
      }
    */
      /*
if(res.data !== "400" && res.data.ipinfo[0].ipcount>0){
  sessionStorage.setItem("user", JSON.stringify(res.data));
  alert('횐영합니다' + userid +'님' +JSON.stringify(res.data));
  console.log('모든회원정보'+JSON.stringify(res.data.ipinfo[0].ipcount))
  return { isLogin: true, user: res.data };
}else if(res.data.ipinfo[0].ipcount<1 || res.data.ipinfo[0].ipcount>0===null || res.data.ipinfo[0].ipcount===''){
  alert(' 등록된 ip가아닙니다. 다시확인하시거나 관리자에게문의하세요  02-595~2222 ');
  return { isLogin: false, user: null };
}else{
  alert('아이디 or 비번이 틀립니다. 다시확인하시거나, 관리자에게문의하세요  02-595~2222 ');
  sessionStorage.setItem("user", null);
  return { isLogin: false, user: null };
}
*/
      /*
  
    }else{
      //sessionStorage.setItem("user", null);
      alert('등록된 ip가 아닙니다 관리자에게문의하세요  02-595~2222'+ JSON.stringify(res.data.ipinfo.ipcount,null,2));
      return { isLogin: false, user: null };
    }
    */
      /*  원본
    .then((res) => {
      //로그인 데이터 처리
      console.log("login result", res);

      //리턴받은 결과가  없으면  session을 null 저장하고 아니면 user[0]로 넘겨온 값을 세션에 저장한다 
      if (res.data === "not match") {
        sessionStorage.setItem("user", null);
        return { isLogin: false, user: null };
      } else {
        sessionStorage.setItem("user", JSON.stringify(res.data));
        return { isLogin: true, user: res.data };
      }

*/
    })
    .catch((err) => {
      console.log(err);
      return { isLogin: false, user: null };
    });
};

export const authLogout = async () => {
  return request("POST", "/auth/logout")
    .then((res) => {
      //로그아웃 데이터 처리
      console.log("logout result", res);
      sessionStorage.setItem("user", null);
      //결과 없음
      return { isLogin: false, user: null };
    })
    .catch((err) => console.log(err));
};

export const authGetInfo = async () => {
  return request("POST", "/auth/info")
    .then((res) => {
      //로그인 데이터 처리
      console.log("info result", res);

      //결과 없음
      if (res.data === "logout") {
        console.log("info result f", res.data);
        sessionStorage.setItem("user", null);
        return { isLogin: false, user: null };
      } else {
        console.log("info result s", res.data);
        sessionStorage.setItem("user", JSON.stringify(res.data));
        return { isLogin: true, user: res.data };
      }
    })
    .catch((err) => console.log(err));
};
