import { request } from "../utils/axios";//  nodeserver로 데이타를 요청하는 함수가있는 페이지
import {userGradeInfo,agreeYN,useYN,loginYN,readYN,outReason,userState,genderInfo,} from "../config/staticInfo";
import { Link, useParams, useNavigate,Navigate, Redirect } from "react-router-dom";
import dayjs from "dayjs";
import { message } from "antd";

//+++++++++++++++++++++++++++++++++++++++이하 수정은 호출페이지에서 요청시 한개의 함수씩 수정할것+++++++++++++++++++++++++++

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

//유저 리스트 가져오기   axios페이지에서 생성한   request함수로 post형식으로 uri = /user/list 을 넘기면  node서버의 db접속 해서
//자동으로 데이타를 가져오면서 m_id: <Link to={"/user/detail/" + result.mo_no}>{result.m_id}</Link>,를건다
export const getnoticelist = async (tableParams) => {  //++++++++++++++++수정+++++++++++++++
  console.log('넘겨온파람'+JSON.stringify(tableParams))
  return request("POST", "/notice/list", JSON.stringify(tableParams))
    .then((res) => {
   console.log('반환온파람'+JSON.stringify(res.data))
      //리스트에 사용 가능하도록 리스트 맵핑
      const noticeList = res.data.noticelist.map((result, idx) => { // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          mo_no: result.mo_no,
          no_subject: <Link to={"/notice/detail/" + result.mo_no}>{result.no_subject}</Link>,
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"), 
        };
      });
      return { list: noticeList, total: res.data.memberTotal.total };//++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log('nodata'+err));
};



//엑셀다운 공지 리스트 가져오기  //엑셀파일 다운시  리스트 가져오기//==>위  리스트의 리턴받은 데이타를 담은 변수 ipblocklist와  아래  ipblocklist 와동일해야 엑셀다운 버튼이 출력됨
//유저 리스트 가져오기
export const getnoticeFullList = async (target, tableParams) => {//target: nodeserver>notice>에서 실행할 함수명
  console.log('넘겨온파람'+JSON.stringify(tableParams) +'/'+ target)//{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
   return request("POST", "/notice/" + target, JSON.stringify(tableParams))// nodeserver>notice> /notice/fullList을 실행한다
  //return request("POST", "/user/" + target, JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
      const noticerList = res.data.noticerList.map((result, idx) => {
        return {
          mo_no: result.mo_no,
          no_subject: result.no_subject,
          no_detail: result.no_detail,
          reg_date: datetimeFormat(result.reg_date),          
        };
      });

      const csv_headers = [
        { label: "고유번호", key: "mo_no" },
        { label: "제목", key: "no_subject" },
        { label: "내용", key: "no_detail" },       
        { label: "등록일", key: "reg_date" },
      ];

      return { csv_data: noticerList, csv_header: csv_headers };
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기, 상세페이지====>수정
export const getnoticedetail = async (mo_no) => {
  return request("POST", `/notice/detail/${mo_no}`)
    .then((res) => {
      //상세 정보에서 사용 가능하도록 처리
      console.log("resnotice",JSON.stringify(res));
      const noticeInfo = res.data[0];
      const renderInfo = [
        { name: "고유번호", value: noticeInfo.mo_no },
        { name: "제목", value: noticeInfo.no_subject },
        { name: "상세내용", value: noticeInfo.no_detail },
        { name: "등록일", value: datetimeFormat(noticeInfo.reg_date) },
      ];
      return renderInfo;//node에 요청해서 받아정리한 renderInfo 배열을 이함수를 호출한 페이지에 리턴한다
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기  수정및 기타 파일들에서 호출====>수정
export const getmoreviewInfo = async (mo_no) => {
  console.log("editmoreview>mo_no", mo_no);
  return request("POST", `/moreview/detail/${mo_no}`).then((res) => {
  //return request("POST", `/notice/detail/${mo_no}`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const moreview = res.data[0];
    console.log("edit>getmoreviewInfo", moreview);
    return moreview;
  });
};
/*
//유저 정보 중복 검사
export const checkUserDuplicate = async (param) => {
  return request("POST", "/user/duplicate", JSON.stringify(param))
    .then((res) => {
      //결과가 0보다 크다면 중복됨, 아니면 없음
      return res.data.count > 0 ? true : false;
    })
    .catch((err) => console.log(err));
};
*/
//app더보기 등록==> 수정 보완할것
export const moredata = async (params) => {
  return request("POST", "/user/regist", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//공지 등록 regist===>수정
export const registnotice = async (params) => {
  console.log("넘긴값1>날자추가" +JSON.stringify(params));
  return request("POST", "/notice/regist", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};


//공지  수정페이지
export const updatemoreview = async (params,mo_no) => {
    console.log("수정넘긴값1>" + JSON.stringify(mo_no));
  return request("POST", "/moreview/update/"+mo_no, JSON.stringify(params))

  //return request("POST", `/notice/update/`+ JSON.stringify(params.no_subject))
  .then((res) => {
    return res.data;
   })
  .catch((err) => console.log(err));
};

//공지삭제===> 삭제후 페이지이동이 되지않아 여기에 이부분을 faqdetail로 옯김 
export const deletenotice = async (mo_no) => {
  console.log("삭제번호넘긴값>" + JSON.stringify(mo_no));
  
  if(mo_no){
    
    if (window.confirm(mo_no + "를 삭제 하시겠습니까? 삭제하시면 복구가불가능합니다")) {
    return request("DELETE", `/notice/delete/${mo_no}`)
  
    .then((res) => {    
      console.log("삭제결과>" + JSON.stringify(res.data.message));
      
      //res?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${mo_no}`" />// 삭제후 이동안됨
      
      if(res.data.message==='success'){
        //const history = useHistory();
        alert('삭제되었습니다');
        const navigate = useNavigate();
        navigate("/notice");// 삭제후 이동안됨

        //history.push('/notice');// 삭제후 이동안됨
        //navigate("/notice");// 삭제후 이동안됨
        //res.data.message==='success'?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${mo_no}`" />
        //<Redirect to={{pathname: '/notice', }}/>
      }
      //<Navigate to="http://localhost:3000/notice/" /> 삭제후이동
      return res.data;
     })
    .catch((err) => console.log(err));
    }

  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }

};


//공지 리스트에서  다중체크후  삭제처리
export const deletechecks = async (list) => {   //LIST(인수명selectedRowKeys와 틀려도 상관없음) 는 NOTICESERVICE> deletechecks(selectedRowKeys)로 넘겨온 체크된 NO_NO리스트들임
  if (list.length > 0) {
    if (window.confirm("선택한 데이타를 삭제 하시겠습니까? .")) {
      return request("DELETE", "/notice/deletechecks/", JSON.stringify(list))
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));
          if(res.data.message==='success'){
            //const history = useHistory();
            alert('삭제되었습니다');
            const navigate = useNavigate();
            navigate("/notice/detail");// 삭제후 이동안됨
            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
          }
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//유저 상세정보 가져오기
export const getTerms = async () => {
  return request("POST", `/user/terms`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const termsInfo = res.data;
    console.log("userinfo1", termsInfo);
    return termsInfo;
  });
};

//유저 등록
export const updateTerms = async (params) => {
  return request("POST", "/user/updateTerms/", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//유저 로그 리스트 가져오기
export const getUserLogList = async (tableParams) => {
  return request("POST", "/user/logList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          key: idx,
          us_no: result.us_no,
          m_id: result.m_id,
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: logList, total: res.data.logTotal.total };
    })
    .catch((err) => console.log(err));
};

//엑셀다운로드 버튼클릭시 유저 리스트 가져오기
export const getLogFullList = async (tableParams) => {
  return request("POST", "/user/logFullList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          us_no: result.us_no,
          m_id: result.m_id,
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: datetimeFormat(result.reg_date),
        };
      });

      const csv_headers = [
        { label: "로그 고유번호", key: "us_no" },
        { label: "아이디", key: "m_id" },
        { label: "로그인 일시", key: "login_time" },
        { label: "로그아웃 일시", key: "logout_time" },
        { label: "등록일", key: "reg_date" },
      ];

      return { csv_data: logList, csv_header: csv_headers };
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기
export const getSimpleStats = async () => {
  return request("POST", `/user/simpleStats`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("유저상세정보result", result);
    return result;
  });
};

//유저 아이디 리스트 가져오기
export const getUserIdList = async (param) => {
  const target = param?.target ? param.target : null;
  const value = param?.value ? param.value : null;
  return request(
    "POST",
    `/simpleQuery`,
    JSON.stringify({
      col: ["m_id"],
      table: "mem_info",
      target: target,
      value: value,
    })
  ).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    //console.log("result", result);
    return result;
  });
};
