import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Col, Button, Row, Space, Image } from "antd";
import { useParams } from "react-router-dom";
import { getUserDetail, deleteUser } from "../../service/userService";
import { useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const { Content } = Layout;

const Pushdetail = () => {
  const navigate = useNavigate();
  const { m_no } = useParams();
  const [data, setData] = useState();
  const [backUrl, setbackUrl] = useState("/user");

  const fetchData = async () => {
    const userInfo = await getUserDetail(m_no);
    console.log("userinfo", userInfo);
    setData(userInfo);
  };

  useEffect(() => {
    fetchData();

    //상세 페이지를 회원목록, 탈퇴목록에서 사용하기위한 목록 버튼 URL
    setbackUrl(sessionStorage.getItem("backUrl"));
  }, []);

  const onRemove = () => {
    if (window.confirm("삭제 하시겠습니까? 삭제하시면 복구가 불가능합니다.")) {
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        //삭제처리
        alert("삭제되었습니다.");
      }
    }
  };

  return (
    <Content style={{ margin: "0 16px",border:'1px solid #f8f8f8',backgroundColor:'#fff',marginTop:15 ,boxShadow:'1px 1.5px 5px 1px #ebebeb',borderRadius:15,}}>
      <Breadcrumb style={{ padding: "20px 0px 0px 30px " ,borderBottom :'1px solid #ebebeb',fontWeight:'bold',color:'#333', fontSize:14,height:55,}}>
        <Breadcrumb.Item>푸시알림관리</Breadcrumb.Item>
        <Breadcrumb.Item>푸시알림상세</Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Row gutter={[16, 16]} className="detailTalbe">
          {data?.map((result, idx) => {
            if (result.name === "프로필사진") {
              return (
                <>
                  <Col span={4} className="rightTitle">
                    {result.name}
                  </Col>
                  <Col span={8}>
                    <div style={{ wordBreak: "break-word" }}>
                      {result.value}
                      <br />
                      <Image width={200} src={result.value} />
                    </div>
                  </Col>
                </>
              );
            }
            return (
              <>
                <Col span={4} className="rightTitle">
                  {result.name}
                </Col>
                <Col span={8}>{result.value}</Col>
              </>
            );
          })}
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space wrap>
              <Button
                className="editButton"
                size="large"
                onClick={() => navigate(`/user/modify/${m_no}`)}
              >
                수정
              </Button>
              <Button danger size="large" onClick={() => deleteUser(m_no)}>
                삭제
              </Button>
              <Button size="large" onClick={() => navigate(backUrl)}>
                목록
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Pushdetail;
