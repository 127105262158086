export const removeblank = (text) => {
  const regex = /\s/g;
  return text.replace(regex, "");
};

//동의 구분 정보
export const agreeYN = {
  1: "동의",
  2: "동의안함",
};

//기본 구분 정보
export const commonYN = {
  1: "예",
  2: "아니오",
};

//사용 구분 정보
export const useYN = {
  1: "사용",
  2: "미사용",
};

export const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
export const reqnumber = (e) => {};

export const regPassword =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

//게시물 구분 정보
export const readYN = {
  1: "읽음",
  2: "안읽음",
};

//신고 구분 정보
export const badYN = {
  1: "신고",
  2: "미신고",
};

//삭제 구분 정보
export const delYN = {
  1: "사용중",
  2: "삭제",
};

//폴더, 카드 로그 상태 정보
export const staticState = {
  1: "생성",
  2: "삭제",
  3: "이동",
  4: "공유",
};

//로그인 구분 정보
export const loginYN = {
  1: "로그인",
  2: "로그아웃",
};

//성별 구분 정보
export const genderInfo = {
  1: "남자",
  2: "여자",
};

//회원 구분 정보
export const userGradeInfo = {
  1: "관리자",
  2: "매니저",
  3: "초등학생",
  4: "중학생",
  5: "고등학생",
  6: "대학생",
  7: "시험준비생",
  8: "교사강사",
  9: "기타",
};

//회원 구분 정보
export const userState = {
  1: "사용중",
  2: "탈퇴",
  3: "휴면",
};

//회원 구분 정보
export const parentInout = {
  0: "내국인",
  1: "외국인",
};

//회원 구분 정보
export const outReason = {
  1: "사용빈도 낮음",
  2: "서비스상태 불안",
  3: "개인정보(기록) 삭제",
  4: "앱 용량이 커서",
  5: "기타",
};

//랜덤카피 출력여부 1,2를 다운파일에 출력,미출력으로 다운받게하는 사전객체변수
export const printornot = {
  1: "출력",
  2: "미출력",
};

//t신고카드상태와 신고사유cacondition,careason=> 상세페이지에서 reportservice에서 호출시 사용
export const cacondition = {
  1: "공유불가",
  2: "틀린카드",
};
export const careason = {
  1: "정확하지 않은 정보가 있습니다",
  2: "부적절한 내용입니다",
  3: "부정행위를 목적으로 사용되고 있습니다",
  4: "타인의 지적 재산권을 침해 합니다",
  5: "기타",
};

export const reportReason = {
  1: "부정확",
  2: "부적절",
  3: "부정행위",
  4: "지적재산권",
  5: "기타",
};

//qna  엑셀다운시 답변여부를 선택 출력해주는부분
export const qnaornot = {
  1: "답변완료",
  2: "답변대기",
};
export const popupFlag = {
  0: "설문",
  1: "이벤트",
  2: "공지사항",
  3: "기타",
};

//gamehistory  엑셀다운시 정답여부를 선택 출력해주는부분
export const oxconf = {
  1: "정답",
  2: "오답",
};
