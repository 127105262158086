import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Col,
  Select,
  Row,
  Radio,
  message,
  InputNumber,
} from "antd";
import ImageUploader from "../../components/ImageUploader";
import { registtutorial } from "../../service/tutorialService"; //==>수정
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

const validateMessages = {
  required: "'${label}'을(를) 입력해주세요.",
  pattern: {
    mismatch: "'${label}' 형식에 맞게 작성해주세요.",
  },
  types: {
    email: "'${label}' 형식에 맞게 작성해주세요.",
  },

  // ...
};

const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

const Tutorialregist = () => {
  const [form] = Form.useForm(); //
  const navigate = useNavigate();
  /* 
  //중복검사가 필요한 항목만 설정
   const [dpCheck, setDpCheck] = useState({
    m_id: "",m_nname: "",
  });

  useEffect(() => {
    //라디오버튼의 초기값 설정으로 한번 실행
    form.setFieldsValue({
      conf_yn: 1,useminfo_yn: 1,push_yn: 2,email_yn: 2,use_state: 1,
    });
  }, []);
  */
  const onFinish = async (values) => {
    // 중복확인 2개 진행이후 등록하기 로직
    console.log("넘긴값" + JSON.stringify(values));
    const todaydate = dayjs().format("YYYY-MM-DD"); //2022-11-11 식으로 저장
    const registParam = {
      //위 values에  아래 폼의 name값들이  자동저장되고 배열끝에 todaydate(오늘날짜)추가됨// 아래 변형해서 쓰는경우 알아서 변형된 값으로 업데이트됨
      ...values,
      reg_date: todaydate,
      tutorial_image: values.tutorial_image.file.url,
    };

    //+++++++++++주의 위formValues안에 주석 처리부분이없어야 pop_image부분에서 file과 url이 합쳐서 전달저장된다+++++++
    console.log("넘긴값>날짜추가" + JSON.stringify(registParam));

    const result = await registtutorial(registParam); //form의 name=value값들을 object배열형태로 모아 전달해서 저장
    console.log("result message" + JSON.stringify(result, null, 2));
    if (!result.message) {
      alert("출력순서가다르거나 오류가 발생했습니다..");
      //message.error("출력순서가다르거나 오류가 발생했습니다.");
      //return false;
    }

    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case undefined:
        alert("출력순서가다르거나 오류가 발생했습니다..");
        message.error("출력순서가다르거나 오류가 발생했습니다.");
        break;
      case "success":
        alert("등록이 완료되었습니다.");
        navigate("/tutorial");
        break;
      case "exist id":
        message.error("이미 존재하는 ID입니다.");
        break;
      case "exist nname":
        message.error("이미 존재하는 닉네임입니다.");
        break;
    }
  }; //onFinish  end

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/tutorial" style={{ color: "#333333" }}>
            튜토리얼 관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/tutorial" style={{ color: "#333333" }}>
            튜토리얼 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item reloadDocument style={{ color: "#333333" }}>
          <Link reloadDocument style={{ color: "#333333" }}>
            튜토리얼 등록
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea" style={{ border: "0px solid #333" }}>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }} //라밸폭비율
          wrapperCol={{ span: 20 }} //input box 폭 비율
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages} // 이부분이있어야 아래 요소들의 rules를 자동으로 검사함
          style={{ textAlign: "left", border: "0px solid #333" }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="제목"
                name="tu_subject"
                hasFeedback
                rules={[{ required: true }, { min: 1, max: 20 }]}
                style={{ textAlign: "left", border: "0px solid #333" }}
              >
                <Input allowClear maxLength={80} />
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left", border: "0px solid #333" }}
                label="내용"
                labelAlign="right"
                name="tu_detail"
                hasFeedback
                rules={[{ required: true }]}
              >
                <TextArea
                  placeholder="Controlled autosize"
                  maxLength={500}
                  autoSize={{ minRows: 5, maxRows: 7 }}
                />
              </Form.Item>
              <Form.Item
                label="이미지"
                name="tutorial_image"
                extra={
                  <div
                    style={{
                      color: "#3c3c3c",
                      fontSize: "12px",
                    }}
                  >
                    *이미지는 JPG, PNG 나 gif 확장자로 용량은 5MB보다 작아야
                    합니다.
                  </div>
                }
              >
                <ImageUploader target="tutorial" />
              </Form.Item>
              <Form.Item
                label="출력순서"
                name="view_num"
                rules={[{ required: true }]}
              >
                <InputNumber
                  allowClear
                  maxLength={2}
                  style={{ width: 70, border: "1px solid #ebebeb" }}
                />
              </Form.Item>
              {/*  팝업상세 로 연결하기로 함 이부분은 불필요
          <Form.Item  label="링크메뉴명" name="link_name"  hasFeedback  rules={[{ required: true }, { min: 5, max: 5 }]}  style={{textAlign:'left',border:'0px solid #333'}}>
           <Input  allowClear  maxLength={30} />
         </Form.Item>
         */}
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                {/*<Button type='primary' htmlType="submit" style={{ width:300,height:100,textAlign:'center',border:'1px solid #fff',borderRadius:15}}>
           htmlType="submit"클릭하면-> onFinish(values)함수를 호출해서  Form.Items 의 name값들이 values인수에 전달되어 db에 저장된다
           */}
                <Button type="primary" htmlType="submit">
                  등록
                </Button>
                <Button onClick={() => navigate(-1)} style={{ marginLeft: 5 }}>
                  {" "}
                  취소
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Content>
  );
};

export default Tutorialregist;
