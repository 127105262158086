import { request } from "../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지
import {
  userGradeInfo,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  outReason,
  userState,
  genderInfo,
} from "../config/staticInfo";
import {
  Link,
  useParams,
  useNavigate,
  Navigate,
  Redirect,
} from "react-router-dom";
import dayjs from "dayjs";
import { message } from "antd";

//+++++++++++++++++++++++++++++++++++++++이하 수정은 호출페이지에서 요청시 한개의 함수씩 수정할것+++++++++++++++++++++++++++

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

//유저 리스트 가져오기   axios페이지에서 생성한   request함수로 post형식으로 uri = /user/list 을 넘기면  node서버의 db접속 해서
//자동으로 데이타를 가져오면서 m_id: <Link to={"/user/detail/" + result.no_no}>{result.m_id}</Link>,를건다
export const getfoldnamelist = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/foldname/list", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const foldnamelist = res.data.foldnamelist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          fn_no: result.fn_no,
          m_grade: userGradeInfo[result.m_grade],
          foldname: (
            <Link to={"/foldname/detail/" + result.fn_no}>
              {result.foldname}
            </Link>
          ),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: foldnamelist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

/*
//탈퇴유저 리스트 가져오기
export const getUserWithdraw = async (tableParams) => {
  return request("POST", "/user/withdrawList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const noticeList = res.data.memberList.map((result, idx) => {
        return {
          key: idx,
          no_no: result.no_no,
          no_subject: <Link to={"/notice/detail/" + result.no_no}>{result.no_subject}</Link>,
          reg_date: result.reg_date,
        };
      });
      return { list: noticeList, total: res.data.memberTotal.total };
    })
    .catch((err) => console.log(err));
};
*/

//엑셀다운 공지 리스트 가져오기  //엑셀파일 다운시  리스트 가져오기//==>위  리스트의 리턴받은 데이타를 담은 변수 ipblocklist와  아래  ipblocklist 와동일해야 엑셀다운 버튼이 출력됨
//유저 리스트 가져오기
export const getfoldnameFullList = async (target, tableParams) => {
  //target: nodeserver>notice>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/foldname/" + target, JSON.stringify(tableParams)) // nodeserver>notice> /notice/fullList을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const foldnameList = res.data.foldnameList.map((result, idx) => {
          return {
            fn_no: result.fn_no,
            m_grade: userGradeInfo[result.m_grade],
            foldname: result.foldname,
            reg_date: datetimeFormat(result.reg_date),
          };
        });

        const csv_headers = [
          { label: "고유번호", key: "fn_no" },
          { label: "회원등급", key: "m_grade" },
          { label: "폴더명", key: "foldname" },
          { label: "등록일", key: "reg_date" },
        ];

        return { csv_data: foldnameList, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

//유저 상세정보 가져오기, 상세페이지====>수정
export const getfoldnamedetail = async (fn_no) => {
  return request("POST", `/foldname/detail/${fn_no}`)
    .then((res) => {
      //상세 정보에서 사용 가능하도록 처리
      console.log("resfoldname", JSON.stringify(res));
      const foldnameInfo = res.data[0];
      const renderInfo = [
        { name: "고유번호", value: foldnameInfo.fn_no },
        { name: "회원등급", value: userGradeInfo[foldnameInfo.m_grade] },
        { name: "폴더명", value: foldnameInfo.foldname },
        { name: "등록일", value: datetimeFormat(foldnameInfo.reg_date) },
      ];
      return renderInfo; //node에 요청해서 받아정리한 renderInfo 배열을 이함수를 호출한 페이지에 리턴한다
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기  수정및 기타 파일들에서 호출====>수정
export const getfoldnameInfo = async (fn_no) => {
  console.log("editgetlist>fn_no", fn_no);
  return request("POST", `/foldname/detail/${fn_no}`).then((res) => {
    //return request("POST", `/notice/detail/${no_no}`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const foldname = res.data[0];
    console.log("edit>getfoldnameInfo", foldname);
    return foldname;
  });
};
/*
//유저 정보 중복 검사
export const checkUserDuplicate = async (param) => {
  return request("POST", "/user/duplicate", JSON.stringify(param))
    .then((res) => {
      //결과가 0보다 크다면 중복됨, 아니면 없음
      return res.data.count > 0 ? true : false;
    })
    .catch((err) => console.log(err));
};
*/
//app더보기 등록==> 수정 보완할것
export const moredata = async (params) => {
  return request("POST", "/user/regist", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

// 등록 regist===>수정
export const registfoldname = async (params) => {
  console.log("넘긴값1>날자추가" + JSON.stringify(params));
  return request("POST", "/foldname/regist", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//수정페이지
export const updatefoldname = async (params, fn_no) => {
  console.log("수정넘긴값1>" + JSON.stringify(params));
  return (
    request("POST", "/foldname/update/" + fn_no, JSON.stringify(params))
      //return request("POST", `/notice/update/`+ JSON.stringify(params.no_subject))
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );
};

//공지삭제===> 삭제후 페이지이동이 되지않아 여기에 이부분을 faqdetail로 옯김
export const deletenotice = async (no_no) => {
  console.log("삭제번호넘긴값>" + JSON.stringify(no_no));

  if (no_no) {
    if (
      window.confirm(
        no_no + "를 삭제 하시겠습니까? 삭제하시면 복구가불가능합니다"
      )
    ) {
      return request("DELETE", `/notice/delete/${no_no}`)
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));

          //res?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${no_no}`" />// 삭제후 이동안됨

          if (res.data.message === "success") {
            //const history = useHistory();
            alert("삭제되었습니다");
            const navigate = useNavigate();
            navigate("/notice"); // 삭제후 이동안됨

            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
            //res.data.message==='success'?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${no_no}`" />
            //<Redirect to={{pathname: '/notice', }}/>
          }
          //<Navigate to="http://localhost:3000/notice/" /> 삭제후이동
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//공지 리스트에서  다중체크후  삭제처리
export const deletechecks = async (list) => {
  //LIST(인수명selectedRowKeys와 틀려도 상관없음) 는 NOTICESERVICE> deletechecks(selectedRowKeys)로 넘겨온 체크된 NO_NO리스트들임
  if (list.length > 0) {
    if (window.confirm("선택한 데이타를 삭제 하시겠습니까? .")) {
      return request("DELETE", "/foldname/deletechecks/", JSON.stringify(list))
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));
          if (res.data.message === "success") {
            //const history = useHistory();
            alert("삭제되었습니다");
            const navigate = useNavigate();
            navigate("/foldnema/detail"); // 삭제후 이동안됨
            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
          }
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//유저 상세정보 가져오기
export const getTerms = async () => {
  return request("POST", `/user/terms`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const termsInfo = res.data;
    console.log("userinfo1", termsInfo);
    return termsInfo;
  });
};

//유저 등록
export const updateTerms = async (params) => {
  return request("POST", "/user/updateTerms/", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//유저 로그 리스트 가져오기
export const getUserLogList = async (tableParams) => {
  return request("POST", "/user/logList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          key: idx,
          us_no: result.us_no,
          m_id: result.m_id,
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: logList, total: res.data.logTotal.total };
    })
    .catch((err) => console.log(err));
};

//엑셀다운로드 버튼클릭시 유저 리스트 가져오기
export const getLogFullList = async (tableParams) => {
  return request("POST", "/user/logFullList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          us_no: result.us_no,
          m_id: result.m_id,
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: datetimeFormat(result.reg_date),
        };
      });

      const csv_headers = [
        { label: "로그 고유번호", key: "us_no" },
        { label: "아이디", key: "m_id" },
        { label: "로그인 일시", key: "login_time" },
        { label: "로그아웃 일시", key: "logout_time" },
        { label: "등록일", key: "reg_date" },
      ];

      return { csv_data: logList, csv_header: csv_headers };
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기
export const getSimpleStats = async () => {
  return request("POST", `/user/simpleStats`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("유저상세정보result", result);
    return result;
  });
};

//유저 아이디 리스트 가져오기
export const getUserIdList = async (param) => {
  const target = param?.target ? param.target : null;
  const value = param?.value ? param.value : null;
  return request(
    "POST",
    `/simpleQuery`,
    JSON.stringify({
      col: ["m_id"],
      table: "mem_info",
      target: target,
      value: value,
    })
  ).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    //console.log("result", result);
    return result;
  });
};
