import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  Table,
  Space,
  DatePicker,
  Input,
  Select,
  message,
} from "antd";
import { FileExcelOutlined, ReloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { Link, useNavigate } from "react-router-dom";
import type { FilterValue } from "antd/es/table/interface";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

import {
  getUserWithdraw,
  getUserFullList,
  deleteUser,
} from "../../service/userService";
import { useSelector } from "react-redux";

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

//테이블 column값
const columns: ColumnsType = [
  {
    title: "No",
    dataIndex: "m_no",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "아이디",
    dataIndex: "m_id",
  },
  {
    title: "회원구분",
    dataIndex: "m_grade",
    filters: [
      { text: "관리자", value: 1 },
      { text: "매니저", value: 2 },
      { text: "초등학생", value: 3 },
      { text: "중학생", value: 4 },
      { text: "고등학생", value: 5 },
      { text: "대학생", value: 6 },
      { text: "시험준비생", value: 7 },
      { text: "교사강사", value: 8 },
      { text: "기타", value: 9 },
    ], //필터는 리스트에서 클릭시 자동 정렬되는 구문임 불필요시 삭제할것
  },
  {
    title: "최근 로그인일자",
    dataIndex: "last_login_time",
  },
  {
    title: "탈퇴일",
    dataIndex: "outtime",
  },
];

const UserWithdraw = () => {
  const { user } = useSelector((state) => state.auth); ///==>무슨의미? 어디서 호출?
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState();
  const [dateRange, setDateRange] = useState(["", ""]);
  const [searchTarget, setSearchTarget] = useState();
  const [dateTarget, setDateTarget] = useState();
  const [loading, setLoading] = useState(false);
  const [csvComponent, setCsvCompnent] = useState(<></>);

  //기본 테이블 설정
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    sessionStorage.setItem("backUrl", "/user/withdraw");
  }, []);

  //테이블 select가 변경되었을때
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  //날짜 선택시
  const onSearchDateChange = (dates, dateStrings) => {
    console.log(dateStrings);
    setDateRange(dateStrings);
  };

  //검색조건 선택시
  const onSelectSearch = (value, option) => {
    setSearchTarget(value);
  };
  const onSelectSearchDate = (value, option) => {
    setDateTarget(value);
  };

  //검색 버튼을 눌렀을때
  const onSearch = (value) => {
    //검색값이 있을 경우
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
      searchDate: dateRange,
      dateTarget: dateTarget,
      searchParam: { target: searchTarget, value: value },
    });
  };

  const fetchData = async () => {
    setLoading(true);
    /*fetch(
      `//xn--pn3b68hmps.com/api/user/list?${qs.stringify(
        (tableParams)
      )}`
    )*/
    console.log("tableParams", tableParams);
    const memberResult = await getUserWithdraw(tableParams);
    setData(memberResult.list);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: memberResult.total,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter
  ) => {
    console.log("table chagne", pagination, filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters,
      sorter,
    });
  };
  const downloadCSV = async () => {
    const { csv_data, csv_header } = await getUserFullList(
      "withdrawFullList",
      tableParams
    );
    setCsvCompnent(
      <CSVLink
        data={csv_data}
        headers={csv_header}
        filename={dayjs().format("YYYYMMDD") + "_withdraw_user_list.csv"}
      >
        <Button className="exelButton">
          <FileExcelOutlined />
          받기
        </Button>
      </CSVLink>
    );
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/user" style={{ color: "#333333" }}>
            회원관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            탈퇴회원목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => navigate(0)}
          ></Button>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col span={8}>
            <Space wrap>
              {/*아래는  서버에서 사용시코드이고 local에서사용시{user.user.m_grade} 한다*/}
              {user.m_grade === 1 ? (
                <Button danger onClick={() => deleteUser("")}>
                  선택 삭제
                </Button>
              ) : (
                ""
              )}

              <Button onClick={downloadCSV}>현재 목록 엑셀로</Button>
              {csvComponent}
            </Space>
          </Col>
          <Col span={16} style={{ textAlign: "right" }}>
            <Space style={{ float: "right" }}>
              <Input.Group compact style={{ float: "left", minWidth: 330 }}>
                <Select
                  placeholder="검색필드"
                  style={{ width: "30%" }}
                  allowClear
                  onChange={onSelectSearch}
                >
                  <Option value="m_id">아이디</Option>
                </Select>
                <Search
                  placeholder="검색어 입력"
                  allowClear
                  enterButton
                  defaultValue={""}
                  onSearch={onSearch}
                  style={{
                    width: "70%",
                  }}
                />
              </Input.Group>
            </Space>
            <Space style={{ float: "right" }}>
              <Input.Group compact>
                <Select
                  placeholder="검색일자"
                  style={{ minWidth: 120 }}
                  allowClear
                  onChange={onSelectSearchDate}
                >
                  <Option value="intime">가입일</Option>
                  <Option value="outtime">탈퇴일</Option>
                  <Option value="last_login_time">최근 로그인</Option>
                  <Option value="last_logout_time">최근 로그아웃</Option>
                </Select>
                <DatePicker.RangePicker
                  style={{ width: 240 }}
                  locale="ko_KR"
                  onChange={onSearchDateChange}
                />
              </Input.Group>
            </Space>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              pagination={{
                ...tableParams.pagination,
                position: ["bottomCenter"],
              }}
              onChange={handleTableChange}
              rowKey={(record) => record.m_no}
              className="userlist-table"
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default UserWithdraw;
