import React, { Component, useState } from "react";
import { Layout, Breadcrumb } from "antd";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import { Navigate,Link } from "react-router-dom";

const { Content } = Layout;

const NotFound = () => {
  return (
    <Content style={{ margin: "16px" }}>
      <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
        404 NOT FOUND: 잘못접근하셨거나 없는페이지입니다
      {/*
         ()=>{alert('404 NOT FOUND: 잘못접근하셨거나 없는페이지입니다');
        <Navigate to="/main" replace={true} />
  */}
      </div>
    </Content>
  );
};

export default NotFound;
