import { request } from "../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지
import {
  userGradeInfo,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  outReason,
  userState,
  genderInfo,
  parentInout,
} from "../config/staticInfo";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

//유저 리스트 가져오기   axios페이지에서 생성한   request함수로 post형식으로 uri = /user/list 을 넘기면  node서버의 db접속 해서
//자동으로 데이타를 가져오면서 m_id: <Link to={"/user/detail/" + result.m_no}>{result.m_id}</Link>,를건다
export const getUserList = async (tableParams) => {
  return request("POST", "/user/list", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const memberList = res.data.memberList.map((result, idx) => {
        return {
          key: idx,
          m_no: result.m_no,
          m_id: <Link to={"/user/detail/" + result.m_no}>{result.m_id}</Link>,
          m_email: result.m_email,
          m_nname: result.m_nname,
          grade: result.grade,
          m_grade: userGradeInfo[result.m_grade],
          gender: genderInfo[result.gender],
          birth: result.birth ? dayjs(result.birth).format("YYYY-MM-DD") : "",
          use_state: result.use_state,
          intime: dayjs(result.intime).format("YYYY-MM-DD"),
        };
      });
      return { list: memberList, total: res.data.memberTotal.total };
    })
    .catch((err) => console.log(err));
};

//탈퇴유저 리스트 가져오기
export const getUserWithdraw = async (tableParams) => {
  return request("POST", "/user/withdrawList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const memberList = res.data.memberList.map((result, idx) => {
        return {
          key: idx,
          m_no: result.m_no,
          m_id: <Link to={"/user/detail/" + result.m_no}>{result.m_id}</Link>,
          m_grade: userGradeInfo[result.m_grade],
          last_login_time: result.last_login_time
            ? dayjs(result.last_login_time).format("YYYY-MM-DD")
            : "",
          outtime: result.outtime
            ? dayjs(result.outtime).format("YYYY-MM-DD")
            : "",
        };
      });
      return { list: memberList, total: res.data.memberTotal.total };
    })
    .catch((err) => console.log(err));
};

//유저 리스트 가져오기
export const getUserFullList = async (target, tableParams) => {
  return request("POST", "/user/" + target, JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const memberList = res.data.memberList.map((result, idx) => {
        return {
          m_no: result.m_no,
          m_id: result.m_id,
          m_email: result.m_email,
          m_nname: result.m_nname,
          m_hp: result.m_hp,
          grade: result.grade,
          m_grade: userGradeInfo[result.m_grade],
          gender: genderInfo[result.gender],
          birth: result.birth,
          auto_yn: useYN[result.auto_yn],
          login_yn: loginYN[result.login_yn],
          pic_route: result.pic_route,
          parent_name: result.parent_name,
          parent_hp_com: result.parent_hp_com,
          parent_inout: result.parent_inout,
          out_reason: outReason[result.out_reason],
          tutorial_yn: readYN[result.tutorial_yn],
          conf_yn: agreeYN[result.conf_yn],
          useminfo_yn: agreeYN[result.useminfo_yn],
          push_yn: agreeYN[result.push_yn],
          email_yn: agreeYN[result.email_yn],
          parent_yn: agreeYN[result.parent_yn],
          out_yn: agreeYN[result.out_yn],
          use_state: userState[result.use_state],
          openid_channel: result.openid_channel,
          openid_id: result.openid_id,
          fcm_token: result.fcm_token,
          kakao_login_yn: result.kakao_login_yn
            ? useYN[result.apple_login_yn]
            : "미사용",
          google_login_yn: result.google_login_yn
            ? useYN[result.apple_login_yn]
            : "미사용",
          apple_login_yn: result.apple_login_yn
            ? useYN[result.apple_login_yn]
            : "미사용",
          intime: datetimeFormat(result.intime),
          outtime: datetimeFormat(result.outtime),
          cf_yn_time: datetimeFormat(result.cf_yn_time),
          uinfo_yn_time: datetimeFormat(result.uinfo_yn_time),
          pu_yn_time: datetimeFormat(result.pu_yn_time),
          em_yn_time: datetimeFormat(result.em_yn_time),
          parent_y_date: datetimeFormat(result.parent_y_date),
        };
      });

      const csv_headers = [
        { label: "고유번호", key: "m_no" },
        { label: "아이디", key: "m_id" },
        { label: "닉네임", key: "m_nname" },
        { label: "이메일", key: "m_email" },
        { label: "회원구분", key: "m_grade" },
        { label: "전화번호", key: "m_hp" },
        { label: "성별", key: "gender" },
        { label: "학년", key: "grade" },
        { label: "생년월일", key: "birth" },
        { label: "회원상태", key: "use_state" },
        { label: "자동로그인체크일시", key: "auto_time" },
        { label: "자동로그인여부", key: "auto_yn" },
        { label: "로그인여부", key: "login_yn" },
        { label: "푸시알림수신동의", key: "push_yn" },
        { label: "푸시알림수신동의 일자", key: "pu_yn_time" },
        { label: "이메일수신동의", key: "email_yn" },
        { label: "이메일수신동의 일자", key: "em_yn_time" },
        { label: "이용약관동의", key: "conf_yn" },
        { label: "이용약관동의 일자", key: "cf_yn_time" },
        { label: "개인정보사용동의", key: "useminfo_yn" },
        { label: "개인정보사용동의 일자", key: "uinfo_yn_time" },
        { label: "부모님동의여부", key: "parent_yn" },
        { label: "부모님동의 일자", key: "parent_y_date" },
        { label: "부모님이름", key: "parent_name" },
        { label: "부모님휴대폰번호", key: "parent_hp" },
        { label: "부모님통신사정보", key: "parent_hp_com" },
        { label: "부모님내국인여부", key: "parent_inout" },
        { label: "가입일", key: "intime" },
        { label: "탈퇴동의여부", key: "out_yn" },
        { label: "탈퇴일", key: "outtime" },
        { label: "탈퇴사유", key: "out_reason" },
        { label: "프로필사진경로", key: "pic_route" },
        { label: "튜토리얼일기 여부", key: "tutorial_yn" },
        { label: "카카오 간편로그인 여부", key: "kakao_login_yn" },
        { label: "구글 간편로그인 여부", key: "google_login_yn" },
        { label: "애플 간편로그인 여부", key: "m_emaapple_login_ynil" },
      ];

      return { csv_data: memberList, csv_header: csv_headers };
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기
export const getUserDetail = async (m_no) => {
  return request("POST", `/user/detail/${m_no}`)
    .then((res) => {
      //상세 정보에서 사용 가능하도록 처리
      console.log("resuser", JSON.stringify(res));
      const userInfo = res.data[0];
      const renderInfo = [
        { name: "고유번호", value: userInfo.m_no },
        { name: "회원구분", value: userGradeInfo[userInfo.m_grade] },

        { name: "아이디", value: userInfo.m_id },
        { name: "성별", value: genderInfo[userInfo.gender] },

        { name: "닉네임", value: userInfo.m_nname },
        { name: "학년", value: userInfo.grade },

        { name: "이메일", value: userInfo.m_email },
        { name: "생년월일", value: userInfo.birth },

        /*{ name: "자동로그인여부", value: useYN[userInfo.auto_yn] },*/

        {
          name: "최근 로그인일시",
          value: datetimeFormat(userInfo.last_login_time),
        },
        { name: "회원상태", value: userState[userInfo.use_state] },

        {
          name: "최근 로그아웃일시",
          value: datetimeFormat(userInfo.last_logout_time),
        },

        { name: "밑줄", value: "" },

        { name: "이용약관동의", value: agreeYN[userInfo.conf_yn] },
        {
          name: "이용약관동의 일자",
          value: datetimeFormat(userInfo.cf_yn_time),
        },

        { name: "개인정보사용동의", value: agreeYN[userInfo.useminfo_yn] },
        {
          name: "개인정보사용동의 일자",
          value: datetimeFormat(userInfo.uinfo_yn_time),
        },

        { name: "푸시알림수신동의", value: agreeYN[userInfo.push_yn] },
        {
          name: "푸시알림수신동의 일자",
          value: datetimeFormat(userInfo.pu_yn_time),
        },

        { name: "이메일수신동의", value: agreeYN[userInfo.email_yn] },
        {
          name: "이메일수신동의 일자",
          value: datetimeFormat(userInfo.em_yn_time),
        },

        { name: "밑줄", value: "" },

        { name: "부모님동의", value: agreeYN[userInfo.parent_yn] },
        {
          name: "부모님동의 일자",
          value: datetimeFormat(userInfo.parent_y_date),
        },

        { name: "부모님 이름", value: userInfo.parent_name },
        { name: "부모님 휴대폰번호", value: userInfo.parent_hp },

        { name: "부모님 통신사정보", value: userInfo.parent_hp_com },
        {
          name: "부모님(내/외국인)",
          value: parentInout[userInfo.parent_inout]
            ? parentInout[userInfo.parent_inout]
            : "",
        },

        { name: "밑줄", value: "" },

        {
          name: "가입일",
          value: datetimeFormat(userInfo.intime),
        },
        {
          name: "탈퇴일",
          value: datetimeFormat(userInfo.outtime),
        },

        { name: "탈퇴동의여부", value: agreeYN[userInfo.out_yn] },
        { name: "탈퇴사유", value: outReason[userInfo.out_reason] },

        { name: "튜토리얼 읽기여부", value: readYN[userInfo.tutorial_yn] },
        { name: "", value: "" },

        { name: "프로필사진", value: userInfo.pic_route },
        /*
        { name: "SNS 로그인 플랫폼", value: userInfo.openid_channel },

        { name: "SNS 회원 고유번호", value: userInfo.openid_id },
        {
          name: "카카오 간편로그인",
          value: userInfo.kakao_login_yn
            ? useYN[userInfo.kakao_login_yn]
            : "미사용",
        },

        {
          name: "구글 간편로그인",
          value: userInfo.google_login_yn
            ? useYN[userInfo.google_login_yn]
            : "미사용",
        },
        {
          name: "애플 간편로그인",
          value: userInfo.apple_login_yn
            ? useYN[userInfo.apple_login_yn]
            : "미사용",
        },
*/
      ];
      return renderInfo;
    })
    .catch((err) => console.log(err));
};

//유저  여러페이지에서 사용하는 modify등등 상세정보 가져오기
export const getUserInfo = async (m_no) => {
  console.log("edit>m_no", m_no);
  return request("POST", `/user/detail/${m_no}`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const userInfo = res.data[0];
    console.log("userinfo1", userInfo);
    return userInfo;
  });
};
//유저 정보 중복 검사
export const checkUserDuplicate = async (param) => {
  return request("POST", "/user/duplicate", JSON.stringify(param))
    .then((res) => {
      //결과가 0보다 크다면 중복됨, 아니면 없음
      return res.data.count > 0 ? true : false;
    })
    .catch((err) => console.log(err));
};

//유저 등록
export const registUser = async (params) => {
  return request("POST", "/user/regist", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//유저 등록
export const updateUser = async (params, m_no) => {
  return request("POST", "/user/update/" + m_no, JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//유저 리스트 가져오기
export const deleteUser = async (m_no) => {
  alert("개인정보 삭제는 본인만 가능합니다.");
  return false;
};

//유저 상세정보 가져오기
export const getTerms = async () => {
  return request("POST", `/user/terms`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const termsInfo = res.data;
    console.log("userinfo1", termsInfo);
    return termsInfo;
  });
};

//유저 등록
export const updateTerms = async (params) => {
  return request("POST", "/user/updateTerms/", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//유저 로그 리스트 가져오기
export const getUserLogList = async (tableParams) => {
  return request("POST", "/user/logList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          key: idx,
          us_no: result.us_no,
          m_id: result.m_id,
          m_email: result.m_email,
          m_nname: result.m_nname,
          grade: result.grade,
          m_grade: userGradeInfo[result.m_grade],
          gender: genderInfo[result.gender],
          birth: result.birth ? dayjs(result.birth).format("YYYY-MM-DD") : "",
          use_state: result.use_state,
          intime: dayjs(result.intime).format("YYYY-MM-DD"),
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: logList, total: res.data.logTotal.total };
    })
    .catch((err) => console.log(err));
};

//엑셀다운로드 버튼클릭시 유저 리스트 가져오기
export const getLogFullList = async (tableParams) => {
  return request("POST", "/user/logFullList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          us_no: result.us_no,
          m_id: result.m_id,
          m_email: result.m_email,
          m_nname: result.m_nname,
          grade: result.grade,
          m_grade: userGradeInfo[result.m_grade],
          gender: genderInfo[result.gender],
          birth: result.birth ? dayjs(result.birth).format("YYYY-MM-DD") : "",
          use_state: result.use_state,
          intime: dayjs(result.intime).format("YYYY-MM-DD"),
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: datetimeFormat(result.reg_date),
        };
      });

      const csv_headers = [
        { label: "로그 고유번호", key: "us_no" },
        { label: "아이디", key: "m_id" },
        { label: "닉네임", key: "m_nname" },
        { label: "이메일", key: "m_email" },
        { label: "회원구분", key: "m_grade" },
        { label: "성별", key: "gender" },
        { label: "학년", key: "grade" },
        { label: "생년월일", key: "birth" },
        { label: "회원상태", key: "use_state" },
        { label: "가입일", key: "intime" },
        { label: "로그인 일시", key: "login_time" },
        { label: "로그아웃 일시", key: "logout_time" },
        { label: "등록일", key: "reg_date" },
      ];

      return { csv_data: logList, csv_header: csv_headers };
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기
export const getSimpleStats = async () => {
  return request("POST", `/user/simpleStats`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("유저상세정보result", result);
    return result;
  });
};

//유저 아이디 리스트 가져오기
export const getUserIdList = async (param) => {
  const target = param?.target ? param.target : null;
  const value = param?.value ? param.value : null;
  return request(
    "POST",
    `/simpleQuery`,
    JSON.stringify({
      col: ["m_id"],
      table: "mem_info",
      target: target,
      value: value,
    })
  ).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    //console.log("result", result);
    return result;
  });
};
