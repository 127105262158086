import { request } from "../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지
import {
  userGradeInfo,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  outReason,
  userState,
  genderInfo,
} from "../config/staticInfo";
import {
  Link,
  useParams,
  useNavigate,
  Navigate,
  Redirect,
} from "react-router-dom";
import dayjs from "dayjs";
import { message } from "antd";

//+++++++++++++++++++++++++++++++++++++++이하 수정은 호출페이지에서 요청시 한개의 함수씩 수정할것+++++++++++++++++++++++++++

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

//유저 리스트 가져오기   axios페이지에서 생성한   request함수로 post형식으로 uri = /user/list 을 넘기면  node서버의 db접속 해서
//자동으로 데이타를 가져오면서 m_id: <Link to={"/user/detail/" + result.no_no}>{result.m_id}</Link>,를건다
export const getsurveyanslist = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/surveyans/list", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const surveyanslist = res.data.surveyanslist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          sva_no: result.sva_no,
          sv_no: result.sv_no,
          //m_id: <Link to={"/user/detail/" + result.sv_no}>{result.m_id}</Link>,// 요청시 해줄것
          m_id: result.m_id,
          m_grade: userGradeInfo[result.m_grade],
          ans_num: result.ans_num,
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: surveyanslist, total: res.data.total.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

/*
//탈퇴유저 리스트 가져오기
export const getUserWithdraw = async (tableParams) => {
  return request("POST", "/user/withdrawList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const noticeList = res.data.memberList.map((result, idx) => {
        return {
          key: idx,
          no_no: result.no_no,
          no_subject: <Link to={"/notice/detail/" + result.no_no}>{result.no_subject}</Link>,
          reg_date: result.reg_date,
        };
      });
      return { list: noticeList, total: res.data.memberTotal.total };
    })
    .catch((err) => console.log(err));
};
*/

//엑셀다운 공지 리스트 가져오기  //엑셀파일 다운시  리스트 가져오기//==>위  리스트의 리턴받은 데이타를 담은 변수 ipblocklist와  아래  ipblocklist 와동일해야 엑셀다운 버튼이 출력됨
//유저 리스트 가져오기
export const getsurveyansFullList = async (target, tableParams) => {
  //target: nodeserver>notice>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/surveyans/" + target, JSON.stringify(tableParams)) // nodeserver>notice> /notice/fullList을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const surveyansList = res.data.surveyansList.map((result, idx) => {
          return {
            sva_no: result.sva_no,
            sv_no: result.sv_no,
            m_id: result.m_id,
            m_grade: userGradeInfo[result.m_grade],
            ans_num: result.ans_num,
            reg_date: datetimeFormat(result.reg_date),
          };
        });

        const csv_headers = [
          { label: "고유번호", key: "sva_no" },
          { label: "설문고유번호", key: "sv_no" },
          { label: "참여자아이디", key: "m_id" },
          { label: "회원구분", key: "m_grade" },
          { label: "정답번호", key: "ans_num" },
          { label: "등록일", key: "reg_date" },
        ];

        return { csv_data: surveyansList, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};
