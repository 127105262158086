import React, { Component, useEffect, useState } from "react";
import { Table, Typography } from "antd";
import { Link } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const DashboardList = ({ title, content, link }) => {
  const { data, colums } = content;

  return (
    <>
      <div style={{ padding: "10px" }}>
        <Row gutter={24}>
          <Col span={20}>
            <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
              {title}
            </Typography>
          </Col>
          <Col span={4} style={{ paddingTop: "4px", textAlign: "right" }}>
            <Link to={link}>
              <RightCircleOutlined />
            </Link>
          </Col>
        </Row>
      </div>
      <Table
        dataSource={data}
        columns={colums}
        pagination={false}
        ellipsis={true}
        className="dashboard-list"
      />
    </>
  );
};

export default DashboardList;
