import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  Table,
  Space,
  DatePicker,
  Input,
  Select,
  message,
  Form,
  Divider,
} from "antd";
import { FileExcelOutlined, ReloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, Link } from "react-router-dom";
import type { FilterValue } from "antd/es/table/interface";
import { getUserIdList } from "../../service/userService";
import { CSVLink } from "react-csv";
import {
  moredata,
  getgamelist,
  getgamelist1,
  getgameFullList,
  deletechecks,
  getSimpleStats,
  getSimpleStats1,
} from "../../service/gameService"; //===>수정
import {
  reqnumber,
  userGradeInfo,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  userState,
  genderInfo,
  regPhone,
  regPassword,
  removeblank,
} from "../../config/staticInfo";
import dayjs from "dayjs";

//==>수정순서   각 영역별로 faq or notice의 리스트를열고 복사 붙혀넣기를 한다  수정이라고 체크된부분을 수정한다
//수정요소: listdata 출력, 선택삭제, 엑셀다운, 등록버튼링크
//db table: game_history
//게임기록> 복습게임기록 리스트와동일하고 출력되는 컬럼면 변경했음

//+++++++++++++++++total_usetime table에서 저장된 사용시간의 합을 가져올것+++++++++++++++++++++++++

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

//테이블의  column 헤더에 출력되는 1row 값

console.log("날짜" + dayjs().format("YYYY-MM-DD hh:mm:ss"));

const columns: ColumnsType = [
  {
    title: "고유번호",
    dataIndex: "num",
    align: "center",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "게임고유번호",
    dataIndex: "gh_index",
    align: "center",
  },
  { title: "아이디", dataIndex: "m_id", align: "center" },
  {
    title: "회원구분",
    dataIndex: "m_grade",
    align: "center",
    filters: [
      { text: "관리자", value: 1 },
      { text: "매니저", value: 2 },
      { text: "초등학생", value: 3 },
      { text: "중학생", value: 4 },
      { text: "고등학생", value: 5 },
      { text: "대학생", value: 6 },
      { text: "시험준비생", value: 7 },
      { text: "교사강사", value: 8 },
      { text: "기타", value: 9 },
    ], //필터는 리스트에서 클릭시 자동 정렬되는 구문임 불필요시 삭제할것
  },
  { title: "학년", dataIndex: "grade", align: "center" },
  { title: "등록일", dataIndex: "save_time", align: "center" },
];

const Gamestaticlist = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //체크박스체크시 넘겨온faq_no 키값을 selectedRowKeys에 배열로저장및 업데이트한다
  const [data, setData] = useState();
  const [data1, setData1] = useState();
  const [dateRange, setDateRange] = useState(["", ""]); // 기간검색  시작일과 종료일이 저장되고 업데이트된다
  const [searchTarget, setSearchTarget] = useState(); //검색필드 담는 변수와 업데이트 함수
  const [dateTarget, setDateTarget] = useState(); // 기간을 검색하는 여러필드들을 사전에  선택하고 업데이트 하게 한다
  const [loading, setLoading] = useState(false);
  const [csvComponent, setCsvCompnent] = useState(<></>);
  const [userIdData, setUserIdData] = useState();

  const [form] = Form.useForm();

  const [gameStats, setgameStats] = useState({
    todaygame: 0,
    totalgame: 0,
  });

  //기본 테이블 설정   페이징처리시 필요한 초기세팅값
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  //console.log('테이블명정보' + JSON.stringify( tableParams));

  const getUserStats1 = async () => {
    const statsResult1 = await getSimpleStats1(); // service>userService.js >  getSimpleStats()  함수를 실행해서 mode에 던지고 결과를 리텀받는다
    console.log(
      "통계정보",
      JSON.stringify(statsResult1.todaygame[0].total, null, 2)
    );
    setgameStats({
      todaygame: statsResult1.todaygame[0].total,
      totalgame: statsResult1.totalgame[0].totalgame,
    });
  };
  //++++++++++++++++++++++현재불필요: 1. getUserStats 살행해  유저상태별값에따를 총계를 가져온다userdata를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    sessionStorage.setItem("backUrl", "/gamestaticlist"); ///backUrl에 /user란 경로 등록==> 무슨의미인가???
    getUserStats1(); //신규 리스트상단 우측의  오늘및 총게임진행수 통계

    //기본 유저목록, 유저 전체 대상  회원별 게임이력데이타출력위한 구문
    getUserIdList().then((result) => {
      setUserIdData(
        result.map((info, idx) => {
          return { label: info.m_id, value: info.m_id };
        })
      );
    });

    //alert('1');
  }, []);

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //+++++++++++++++++++++++++++++++++++++++++선택삭제+++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //++++++++++++++++합산해서 가져오므로 고유번호는 없고 전체 체크삭제불필요+++++++++++++++++++++++++++++

  //++++++++++++++++++++++ 살행해  유저상태별값에따를 총계를 가져온다userdata를 가져온다+++++++++++++++++++++++++++++++
  //테이블  체크박스를 클릭할때 select가 변경되었을때 faq_no값을 리턴해서  setSelectedRowKeys()로 SelectedRowKeys를 업데이트한다
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  //이object변수 에  채크박스로 선택한 no들이있는 selectedRowKeys이있고  object변수>onChange속성이 실행되면(채크박스값들이변동되면)
  //위 onSelectChange()로  selectedRowKeys값을 업데이트시킨다
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문+++++++++++++++++++++++++++++++++++++++

  //기간선택부분  //날짜 선택시 검색 시작일,종료일선택해서 setDateRange()함수 업데이트//
  const onSearchDateChange = (dates, dateStrings) => {
    console.log("선택한 날짜는" + dateStrings); //시작일dateStrings[0]   종료일dateStrings[1]    2023-01-11식으로출력
    setDateRange(dateStrings);
  };

  //검색필드 선택시 value와 option값을 인수로 넘겨  setSearchTarget()를 업데이트한다
  const onSelectSearch = (value, option) => {
    setSearchTarget(value);
  };

  //없어도 되는부분 이선택
  const onSelectSearchDate = (value, option) => {
    // 원래 이부분은 기간이지만 검색할 날짜필드값이 많아  여러칼럼을 select에서 option으로 선택하게 했음
    setDateTarget(value);
  };

  //실제 검색함수==> antd 라이브러리에서 검색 input박스 에 버튼까지 연결시킨 input 박스임 입력검색 버튼을 눌렀을때 이 컴포의 value
  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
      searchDate: dateRange, // 검색기간
      dateTarget: dateTarget, // 기간을검색할필드로 기간선택 텍스트로 통일가능 없어두됨
      searchParam: { target: searchTarget, value: value }, //searchTarget  검색할 필드값   value=검색어
    });
  };

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문 end+++++++++++++++++++++++++++++++++++++++

  //+++++++++++++++++++++++++++++++3. data를 가져와  리턴받은 값을 setData()로 data변수에 리턴한다+++++++++++++++++++++++++++++++//+++++수정+++++
  const fetchData = async () => {
    //alert('3');
    setLoading(true);
    /*fetch(
        `//xn--pn3b68hmps.com/api/user/list?${qs.stringify(
          (tableParams)
        )}`
      )*/
    console.log("tableParams1", tableParams);
    const listresult = await getgamelist(tableParams); //// service>userService.js >  getUserList()  함수를 실행해서 node에 던지고 결과를 리턴받는다  ====>수정
    console.log("listresult111" + JSON.stringify(listresult, null, 2));
    setData(listresult.list);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: listresult.total,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  //+++++++++++++++++++++++++++++++2.  tableParams 변경시  fetchData();함수를 1번실행해서 데이타를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    //alert('2');
    fetchData();
    setCsvCompnent(<></>);
  }, [JSON.stringify(tableParams)]);

  //console.log('datasms'+JSON.stringify( fetchData(),null,2))

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>
  ) => {
    console.log("table chagne는", pagination, filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters,
    });
  };

  {
    /*======>수정  await이후 함수*/
  }
  const downloadCSV = async () => {
    const { csv_data, csv_header } = await getgameFullList(
      "fullList",
      tableParams
    );
    setCsvCompnent(
      <CSVLink
        data={csv_data}
        headers={csv_header}
        filename={dayjs().format("YYYYMMDD") + "_game_list.csv"}
      >
        <Button className="exelButton">
          <FileExcelOutlined />
          받기
        </Button>
      </CSVLink>
    );
  };

  //데이터 갱신
  const refreshData = () => {
    fetchData();
    //엑셀항목 초기화
    setCsvCompnent(<></>);
    //선택된 항목 초기화
    setSelectedRowKeys([]);
  };

  //+++++++++++++++++회원별 게임 이력을 출력하기
  //0.초기 페이지로드시 select박스에   데이타를 가져와 아래처럼 출력하고 setUserIdData()로 userIdData를 업데이트하고 select박스의    options={userIdData}에 연동한다
  //getUserIdList().then((result) => {
  // setUserIdData( result.map((info, idx) => { return { label: info.m_id, value: info.m_id }; })  );
  //});
  //1.초기리스트에 회원아이디 m_id를 출력하게한다. 2.select의 option선택시   searchTarget='검색할필드'   value='m_id' 로넘겨 setTableParams()을 얻데이트하면 된다

  //유저 구분 변경
  const onUserGradeChange = (value) => {
    //console.log(`selected ${value}`);
    //tableparam 변경 - filter에 m_grade 추가
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        m_grade: value,
      },
    });

    //유저 목록을 해당 구분값의 유저로 변경
    getUserIdList({ target: `m_grade`, value: value }).then((result) => {
      setUserIdData(
        result.map((info, idx) => {
          return { label: info.m_id, value: info.m_id };
        })
      );
    });
  };

  const onUserChange = (value) => {
    //console.log(`selected ${value}`);
    //tableparam 변경 - filter에 m_id 추가

    let searchTarget = "m_id";
    if (value !== "" && searchTarget === "m_id") {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: 1,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
        searchDate: dateRange, // 검색기간
        dateTarget: dateTarget, // 기간을검색할필드로 기간선택 텍스트로 통일가능 없어두됨
        searchParam: { target: searchTarget, value: value }, //searchTarget  검색할 필드값   value=검색어
      });
    }
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/log/utimedaylist" style={{ color: "#333333" }}>
            로그
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            복습게임로그 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => navigate(0)}
          ></Button>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="contentArea">
        <Row
          gutter={24}
          style={{
            height: 35,
            border: "0px solid #999",
          }}
        >
          <Col span={12} align="left">
            <Space>
              <Select
                showSearch
                allowClear
                placeholder="회원구분별 검색"
                optionFilterProp="children"
                onChange={onUserGradeChange}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={Object.entries(userGradeInfo).map((info, idx) => {
                  return { label: info[1], value: info[0] };
                })}
                style={{
                  width: 150,
                }}
              />
              <Select
                showSearch
                allowClear
                placeholder="회원별 검색"
                optionFilterProp="children"
                onChange={onUserChange}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={userIdData}
                style={{
                  width: 130,
                }}
              />
            </Space>
          </Col>

          <Col
            span={12}
            align="right"
            style={{
              width: 150,
              border: "0px solid #999",
              height: 30,
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 15,
              fontWeight: "bold",
            }}
          >
            오늘 게임수:{gameStats.todaygame}건, 총 게임수:{gameStats.totalgame}
            건
          </Col>
        </Row>
        <Divider />
        <Row
          gutter={24}
          style={{ width: "100%", marginBottom: 15, border: "0px solid red" }}
        >
          <Col span={6}>
            <Space wrap>
              <Button onClick={downloadCSV}>현재 목록 엑셀로</Button>
              {csvComponent}
            </Space>
          </Col>
          {/*    
            <div style={{textAlign:'end',width:'50%',borderRadius:15,marginBottom:0,padding:0,border:'0px dotted red',}}> 
               <div style={{position:'relative',float:'right',width:'10%',border:'0px solid black',textAlign:'left',zIndex:0,}}> 
               <Input.Group compact style={{border:'0px solid blue',left:-20 }}>          
                <Search  placeholder="" enterButton   defaultValue={""}   onSearch={onSearch} style={{width: "0%",border:'0px solid red', }}/>
   
               </Input.Group>
                </div>
                <div style={{position:'relative',float:'right',width:'90%',border:'0px solid black',zIndex:15}}> 
               <Input.Group compact>
                   <DatePicker.RangePicker  style={{position:'relative',float:'right', width: 210,zIndex:15 }} locale="ko_KR" onChange={onSearchDateChange}/>
                   <Select  placeholder="검색조건"  allowClear   style={{position:'relative',float:'right',minWidth:100,border:'0px solid green', }}  
                   onChange={onSelectSearchDate}>
                  <Option value="ga_save_time">등록일</Option>
                </Select>
              </Input.Group> 
              </div>   
           </div>
*/}
          <Col
            span={18}
            style={{ textAlign: "right", border: "0px solid #999" }}
          >
            <Space style={{ float: "right" }}>
              <Input.Group
                compact
                style={{
                  float: "left",
                  minWidth: "50%",
                  border: "0px solid blue",
                }}
              >
                <Select
                  placeholder="검색필드"
                  allowClear
                  style={{ width: "30%" }}
                  onChange={onSelectSearch}
                  dropdownMatchSelectWidth={150}
                >
                  {/*이하 부분만 수정하면 자동으로 다른것 수정하지 않아도 수정됨*/}
                  <Option value="gh_index">게임고유번호</Option>
                  <Option value="m_id">회원아이디</Option>
                </Select>
                <Search
                  placeholder="검색어 입력"
                  allowClear
                  enterButton
                  defaultValue={""}
                  onSearch={onSearch}
                  style={{
                    width: "70%",
                    border: "0px solid red",
                  }}
                />
              </Input.Group>
            </Space>

            <Space style={{ float: "right" }}>
              <Input.Group compact>
                <Select
                  placeholder="검색날짜필드"
                  allowClear
                  style={{ minWidth: 120 }}
                  onChange={onSelectSearchDate}
                >
                  <Option value="ga_save_time">등록일</Option>
                </Select>
                <DatePicker.RangePicker
                  style={{ width: 200 }}
                  locale="ko_KR"
                  onChange={onSearchDateChange}
                />
              </Input.Group>
            </Space>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection} // 체크박스 선택한 값들을 실시간으로 변동하게 하는 object형 변수==> 전체 삭제기능필수
              columns={columns} //위에서지정한 컬럼타입을 저장한변수
              dataSource={data}
              pagination={{
                //이부분으로 pagenation이출력됨
                ...tableParams.pagination,
                position: ["bottomCenter"],
              }}
              onChange={handleTableChange}
              rowKey={(record) => record.gh_no} //===> 수정
              className="userlist-table"
              style={{ border: "0px solid #333" }}
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Gamestaticlist;
