import { request } from "../utils/axios";
import { useYN, delYN, commonYN, userGradeInfo } from "../config/staticInfo";
import { Link } from "react-router-dom";
import { message } from "antd";
import dayjs from "dayjs";
import cryptoRandomString from "crypto-random-string";

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

export const createFolderIndex = (id, count = 0) => {
  //fo_아이디_랜덤6자
  let folderIndex =
    "fo_" + id + "_" + cryptoRandomString({ length: 6, type: "alphanumeric" });
  console.log(folderIndex);
  console.log(count);

  if (count > 10) {
    message.error("폴더 고유번호를 생성하지 못했습니다.");
    return false;
  }

  //인덱스 중복 확인
  if (
    checkFolderDuplicate({ target: "fo_index", value: folderIndex }) === true
  ) {
    return createFolderIndex(id, count++);
  } else {
    return folderIndex;
  }
};

//폴더 정보 중복 검사
export const checkFolderDuplicate = async (param) => {
  return request("POST", "/folder/duplicate", JSON.stringify(param))
    .then((res) => {
      //결과가 0보다 크다면 중복됨, 아니면 없음
      return res.data.count > 0 ? true : false;
    })
    .catch((err) => console.log(err));
};

//폴더명 가져오기
export const getFolderNameList = async (param) => {
  const col = param?.col ? param.col : ["fo_name"];
  const target = param?.target ? param.target : null;
  const value = param?.value ? param.value : null;
  const order = param?.order ? param.order : null;
  const group = param?.group ? param.group : null;
  return request(
    "POST",
    `/simpleQuery`,
    JSON.stringify({
      col: col,
      table: "my_folder",
      target: target,
      value: value,
      order: order,
      group: group,
    })
  ).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    //console.log("result", result);
    return result;
  });
};

//폴더명 가져오기 - 아이디 기준으로 삭제되지 않은 폴더
export const getFolderListById = async (param) => {
  return request("POST", "/folder/listById", JSON.stringify(param))
    .then((res) => {
      //결과가 0보다 크다면 중복됨, 아니면 없음
      return res.data;
    })
    .catch((err) => console.log(err));
};

//카드 리스트 가져오기
export const getFolderList = async (tableParams) => {
  return request("POST", "/folder/list", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const folderList = res.data.folderList.map((result, idx) => {
        return {
          key: idx,
          ...result,
          fo_name: (
            <Link
              to={"/folder/detail/" + result.fo_index}
              style={{ color: "#000" }}
            >
              {result.fo_name}
            </Link>
          ),
        };
      });
      return { list: folderList, total: res.data.folderTotal.total };
    })
    .catch((err) => console.log(err));
};

//폴더 리스트 가져오기 엑셀용
export const getFolderFullList = async (tableParams) => {
  return request("POST", "/folder/fullList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const List = res.data.folderList.map((result, idx) => {
        return {
          fo_no: result.fo_no,
          m_id: result.m_id,
          m_grade: userGradeInfo[result.m_grade],
          fo_index: result.fo_index,
          fo_name: result.fo_name,
          fo_back: result.fo_back,
          public_yn: commonYN[result.public_yn],
          del_yn: delYN[result.del_yn],
          fo_cre_time: datetimeFormat(result.fo_cre_time),
          fo_del_time: datetimeFormat(result.ca_del_time),
        };
      });

      const csv_headers = [
        { label: "일련번호", key: "fo_no" },
        { label: "아이디", key: "m_id" },
        { label: "회원구분", key: "m_grade" },
        { label: "폴더고유번호", key: "fo_index" },
        { label: "폴더명", key: "fo_name" },
        { label: "배경색", key: "fo_back" },
        { label: "공유여부", key: "public_yn" },
        { label: "삭제여부", key: "del_yn" },
        { label: "폴더 생성시간", key: "fo_cre_time" },
        { label: "폴더 삭제시간", key: "ca_del_time" },
      ];
      return { csv_data: List, csv_header: csv_headers };
    })
    .catch((err) => console.log(err));
};

//폴더 삭제처리
export const deleteFolder = async (list) => {
  if (list.length > 0) {
    if (window.confirm("삭제 하시겠습니까? 유저가 볼 수 없도록 처리됩니다.")) {
      return request("DELETE", "/folder/delete/", JSON.stringify(list))
        .then((res) => {
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//폴더 내용 수정
export const updateFolder = async (params, fo_no) => {
  return request("POST", "/folder/update/" + fo_no, JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//폴더 상세정보 가져오기
export const getFolderInfo = async (fo_index) => {
  return request("POST", `/folder/detail/${fo_index}`).then((res) => {
    const Info = res.data[0];
    console.log("folderInfo", Info);
    return Info;
  });
};

//폴더 등록
export const registFolder = async (params) => {
  return request("POST", "/folder/regist", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//폴더등록시 회원구분선택시 폴더명 가져오기 : 초/중/고/대의 경우만 m_grade가 3~6까지만
export const selectfoldlist = async (value) => {
  console.log("넘겨맏은 회원그레이드", value);
  return request("POST", `/folder/foldlist/${value}`).then((res) => {
    const foldlist = res.data.selectfoldlist.map((result, idx) => {
      //console.log('반환폴드리스트'+JSON.stringify(foldlist,null,2))// 여기에 있으면 에러발생함
      return {
        foldname: result.foldname,
      };
    });
    return { list: foldlist };
    //console.log("foldlist", foldlist);
    //return JSON.stringify(foldlist);
  });
};
