import React, { useEffect, useState, View, Text } from "react";
import { Layout, Table } from "antd";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  IdcardOutlined,
  FolderOutlined,
  BulbOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import type { ColumnsType } from "antd/es/table";
import DashboardCard from "../components/DashboardCard";
import DashboardListCard from "../components/DashboardListCard";
import DashboardList from "../components/DashboardList";
import "../css/Dashboard.css"; // or 'antd/dist/antd.less'
import {
  userstatic1,
  cardstatic1,
  foldstatic1,
  gamestatic1,
  foldrank1,
  sharefoldrank1,
  gamedaystatic1,
  qnastatic1,
  getRecentNotice,
} from "../service/dashboardService";
import { userGradeInfo } from "../config/staticInfo";

//+++++++++++++++++++++++나중 페이지를 완료후 data를 select할것++++++++++++++++++++++++++
const { Content } = Layout;
//컬럼과 함께 antdesign  > table라이브러리에  출력하기위한 컬럼
const folderRankColumns = [
  {
    title: "순위",
    dataIndex: "ranking",
    width: "20%",
    align: "center",
  },
  { title: "생성폴더수", dataIndex: "num", width: "30%", align: "center" },
  { title: "폴더명", dataIndex: "fo_name", align: "center", ellipsis: true },
];
const shareRankColumns = [
  { title: "순위", dataIndex: "ranking", width: "20%", align: "center" },
  { title: "이동폴더수", dataIndex: "num", width: "30%", align: "center" },
  { title: "폴더명", dataIndex: "fo_name", align: "center", ellipsis: true },
];
const noticeColumns = [
  { title: "NO", dataIndex: "num", width: "20%", align: "center" },
  { title: "제목", dataIndex: "no_subject", align: "center", ellipsis: true },
  { title: "등록일", dataIndex: "reg_date", width: "30%", align: "center" },
];
const qnaColumns = [
  { title: "제목", dataIndex: "qa_subject", align: "center", ellipsis: true },
  { title: "날짜", dataIndex: "reg_date", width: "30%", align: "center" },
  {
    title: "답변여부",
    dataIndex: "ans_yn",
    width: "20%",
    align: "center",
  },
];
const Dashboard = () => {
  //상단회원통계 박스안의 통계
  const [userinfo, setuserinfo] = useState({
    totaluser: 0,
    todayuser: 0,
    outuser: 0,
    gradeUser: [{ title: "", value: 0 }],
  });

  //상단복습카드 박스안의 통계
  const [cardinfo, setcardinfo] = useState({
    totalcard: 0,
    todaycard: 0,
    crecard: 0,
    delcard: 0,
  });

  //상단복습폴더 박스안의 통계
  const [foldinfo, setfoldinfo] = useState({
    totalfold: 0,
    todayfold: 0,
    crefold: 0,
    delfold: 0,
  });

  //상단게임통계 박스안의 통계
  const [gameinfo, setgameinfo] = useState({
    totalgame: 0,
    todaygame: 0,
    gametotal: 0,
    daytotal: 0,
  });

  const [data, setData] = useState();
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  //기본 테이블 설정
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  //+++++++++++++++++++++++++++++++3. data를 가져와  리턴받은 값을 setData()로 data변수에 리턴한다+++++++++++++++++++++++++++++++//+++++수정+++++

  const userstatic = async () => {
    const userstatic_1 = await userstatic1(); //총가입자
    setuserinfo({
      totaluser: userstatic_1.totaluser.totaluser,
      todayuser: userstatic_1.todayuser.todayuser,
      outuser: userstatic_1.outuser.outuser,
      gradeUser: userstatic_1.gradeUser.map((row) => {
        return { title: userGradeInfo[row.m_grade], value: row.count + "명" };
      }),
    });
    console.log(
      "회원통계정보",
      JSON.stringify(userstatic_1.todayuser.todayuser, null, 2)
    );
  };

  const cardstatic = async () => {
    const cardstatic_1 = await cardstatic1();
    setcardinfo({
      totalcard: cardstatic_1.totalcard.totalcard,
      todaycard: cardstatic_1.todaycard.todaycard,
      crecard: cardstatic_1.crecard.crecard,
      delcard: cardstatic_1.delcard.delcard,
    });
    console.log(
      "카드통계정보",
      JSON.stringify(cardstatic_1.totalcard.totalcard, null, 2)
    );
  };

  const foldstatic = async () => {
    const foldstatic_1 = await foldstatic1();
    setfoldinfo({
      totalfold: foldstatic_1.totalfold.totalfold,
      todayfold: foldstatic_1.todayfold.todayfold,
      crefold: foldstatic_1.crefold.crefold,
      delfold: foldstatic_1.delfold.delfold,
    });
    console.log(
      "폴더통계정보",
      JSON.stringify(foldstatic_1.totalfold.totalfold, null, 2)
    );
  };

  const gamestatic = async () => {
    const gamestatic_1 = await gamestatic1();
    setgameinfo({
      totalgame: gamestatic_1.totalgame.totalgame,
      todaygame: gamestatic_1.todaygame.todaygame,
      gametotal: gamestatic_1.gametotal.gametotal
        ? 0
        : gamestatic_1.gametotal.gametotal,
      daytotal: gamestatic_1.daytotal.daytotal
        ? 0
        : gamestatic_1.daytotal.daytotal,
    });
    console.log("게임통계정보11", JSON.stringify(gamestatic_1, null, 2));
  };

  const foldrank = async () => {
    const foldrank11 = await foldrank1();
    console.log("foldrank_1" + JSON.stringify(foldrank11, null, 2));
    console.log("foldrank_2" + JSON.stringify(foldrank11.list1, null, 2));
    setData(foldrank11.list);
  };

  const sharefoldrank = async () => {
    const sharefoldrank11 = await sharefoldrank1();
    console.log("sharefoldrank11" + JSON.stringify(sharefoldrank11, null, 2));
    setData1(sharefoldrank11.list);
  };

  const gamedaystatic = async () => {
    const gamedaystatic11 = await gamedaystatic1();
    console.log("gamedaystatic11" + JSON.stringify(gamedaystatic11, null, 2));
    setData2(gamedaystatic11.list);
  };
  const setRecentNotice = async () => {
    const recentNotice = await getRecentNotice();
    console.log("recentNotice" + JSON.stringify(recentNotice, null, 2));
    setData2(recentNotice.list);
  };

  const qnastatic = async () => {
    const qnastatic11 = await qnastatic1();
    console.log("qnastatic11" + JSON.stringify(qnastatic11, null, 2));
    setData3(qnastatic11.list);
  };
  //+++++++++++++++++++++++++++++++2.  tableParams 변경시  fetchData();함수를 1번실행해서 데이타를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    userstatic();
    cardstatic();
    foldstatic();
    gamestatic();
    foldrank();
    sharefoldrank();
    //gamedaystatic();
    setRecentNotice();
    qnastatic();
    //console.log("게임통계정보1", JSON.stringify(gamestatic(),null,2) )
  }, []);

  return (
    <Content
      style={{
        margin: "16px",
        background: "#fff",
        minHeight: 360,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
        marginBottom: 15,
        padding: 30,
      }}
    >
      <Row gutter={16}>
        <Col span={6}>
          <DashboardListCard
            title="회원통계"
            content={{
              titles: ["총 가입자", "탈퇴자", "오늘 가입자"],
              values: [
                userinfo.totaluser,
                userinfo.outuser,
                userinfo.todayuser,
              ],
            }}
            list={userinfo.gradeUser}
            link="/user"
            theme="light"
          />
        </Col>
        <Col span={6}>
          <DashboardCard
            icon={<IdcardOutlined style={{ fontSize: 50, color: "#fff" }} />}
            title="복습카드통계"
            content={{
              titles: ["총 카드수", "오늘 등록", "생성/삭제"],
              values: [
                cardinfo.totalcard,
                cardinfo.todaycard,
                cardinfo.crecard + "/" + cardinfo.delcard,
              ],
            }}
            link="/log/cardstaticlist"
            theme="dark"
          />
        </Col>
        <Col span={6}>
          <DashboardCard
            icon={<FolderOutlined style={{ fontSize: 50 }} />}
            title="복습폴더통계"
            content={{
              titles: ["총 폴더수", "오늘 등록", "생성/삭제"],
              values: [
                foldinfo.totalfold,
                foldinfo.todayfold,
                foldinfo.crefold + "/" + foldinfo.delfold,
              ],
            }}
            link="/log/foldstaticlist"
            theme="light"
          />
        </Col>
        <Col span={6}>
          <DashboardCard
            icon={<BulbOutlined style={{ fontSize: 50, color: "#fff" }} />}
            title="복습게임통계"
            content={{
              titles: ["총 게임수", "오늘 게임수", "일 평균"],
              values: [
                gameinfo.totalgame,
                gameinfo.todaygame,
                gameinfo.daytotal > 0
                  ? (gameinfo.gametotal / gameinfo.daytotal).toFixed(2)
                  : 0,
              ],
            }}
            link="/log/gamestaticlist"
            theme="dark"
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ paddingTop: "25px", border: "0px solid red" }}>
        <Col span={12}>
          <DashboardList
            title="폴더 순위"
            content={{
              data: data,
              colums: folderRankColumns,
            }}
            link="/static/foldrank"
          />
        </Col>

        <Col span={12}>
          <DashboardList
            title="공유폴더 순위"
            content={{
              data: data1,
              colums: shareRankColumns,
            }}
            link="/static/sharefoldrank"
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12} style={{ paddingTop: "25px", border: "0px solid red" }}>
          <DashboardList
            title="최근 공지사항"
            content={{
              data: data2,
              colums: noticeColumns,
            }}
            link="/service/notice"
          />
        </Col>

        <Col span={12} style={{ paddingTop: "25px", border: "0px solid red" }}>
          <DashboardList
            title="1:1문의"
            content={{
              data: data3,
              colums: qnaColumns,
            }}
            link="/service/qna"
          />
        </Col>
      </Row>
    </Content>
  );
};

export default Dashboard;
