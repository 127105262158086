import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Col,
  Select,
  Row,
  Radio,
  message,
  InputNumber,
} from "antd";
import {
  agreeYN,
  useYN,
  loginYN,
  readYN,
  userState,
  genderInfo,
  regPhone,
  regPassword,
} from "../../config/staticInfo";
import ImageUploader from "../../components/ImageUploader";
import { registfoldname } from "../../service/foldnameService"; //==>수정
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

const validateMessages = {
  ///아래 form 에서 유효성검사시 출력되는 에러 메세지  필수
  required: "'${label}'을(를) 입력해주세요.",
  pattern: {
    mismatch: "'${label}' 형식에 맞게 작성해주세요.",
  },
  types: {
    email: "'${label}' 형식에 맞게 작성해주세요.",
  },

  // ...
};
const userGradeInfo = {
  1: "관리자",
  2: "매니저",
  3: "초등학생",
  4: "중학생",
  5: "고등학생",
  6: "대학생",
  7: "시험준비생",
  8: "교강사",
  9: "기타",
};

const Foldnameregist = () => {
  const [form] = Form.useForm(); //
  const navigate = useNavigate();

  //중복검사가 필요한 항목만 설정
  const [dpCheck, setDpCheck] = useState({
    m_id: "",
    m_nname: "",
  });

  useEffect(() => {
    //라디오버튼의 초기값 설정으로 한번 실행
    form.setFieldsValue({
      conf_yn: 1,
      useminfo_yn: 1,
      push_yn: 2,
      email_yn: 2,
      use_state: 1,
    });
  }, []);

  const onFinish = async (values) => {
    // 중복확인 2개 진행이후 등록하기 로직
    console.log("넘긴값" + JSON.stringify(values));
    /*
    const dpCheckSuccess = Object.entries(dpCheck).filter((result) => {
      return result[1].includes("success");

    });
    //success가 2개가 아니면 실행하지 않음
    if (dpCheckSuccess.length < 2) {
      message.error("중복확인을 진행하신 후 등록 가능합니다.");
      return false;
    }
   */
    const todaydate = dayjs().format("YYYY-MM-DD");
    const registParam = {
      //위 values에  아래 폼의 name값들이  자동저장되고 배열끝에 todaydate(오늘날짜)추가됨
      ...values,
      reg_date: todaydate,
    };
    console.log("넘긴값>날짜추가" + JSON.stringify(registParam));
    /*   */
    const result = await registfoldname(registParam);
    console.log("result message" + JSON.stringify(result));
    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("등록이 완료되었습니다.");
        navigate("/foldname");
        break;
      case "exist id":
        message.error("이미 존재하는 ID입니다.");
        break;
      case "exist nname":
        message.error("이미 존재하는 닉네임입니다.");
        break;
    }
  }; //onFinish  end

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /*
  //중복확인
  const checkDuplicate = async (target) => {
    const value = form.getFieldValue(target);
    if(!value){alert('data를 입력해주세요')}    //data없이 중복체크버큰클릭하는경우
    if (value) {// 중복체박스에 입력되있으면
      const checkResult = await checkUserDuplicate({//userservice->node로 중복검사요청함수
        target: target,
        value: value,
      });

      //결과값이 true면 중복된값(이미 존재하는 값) false일때 사용가능
      if (checkResult) {
        form.setFields([{ name: target, errors: ["사용중인 값입니다."] }]);
        setDpCheck({ ...dpCheck, [target]: "error" });
      } else {
        setDpCheck({ ...dpCheck, [target]: "success" });
      }
    }
  };

  //중복검사한 후에 값을 변경했을 경우 해당 항목 다시 검사 필요하도록 초기화
  const initCheck = (target) => {
    setDpCheck({ ...dpCheck, [target]: "" });
  };
*/

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/foldname" style={{ color: "#333333" }}>
            회원구분폴더 관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/foldname" style={{ color: "#333333" }}>
            회원구분폴더 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item reloadDocument style={{ color: "#333333" }}>
          <Link reloadDocument style={{ color: "#333333" }}>
            회원구분폴더 등록
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="contentArea" style={{ border: "0px solid #333" }}>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }} //라밸폭비율
          wrapperCol={{ span: 20 }} //input box 폭 비율
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages} // 이부분이있어야 아래 요소들의 rules를 자동으로 검사함
          style={{ textAlign: "left", border: "0px solid #333" }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="회원구분"
                name="m_grade"
                rules={[{ required: true }]}
              >
                <Select placeholder="==선택==" allowClear>
                  {Object.entries(userGradeInfo).map((result, idx) => {
                    return <Option value={result[0]}>{result[1]}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="폴더명"
                name="foldname"
                hasFeedback
                rules={[{ required: true }, { max: 30 }]}
                style={{ textAlign: "left", border: "0px solid #333" }}
                //validateStatus={dpCheck.m_id}
              >
                <Input
                  allowClear
                  maxLength={80}
                  //onChange={() => initCheck("m_id")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                {/*<Button type='primary' htmlType="submit" style={{ width:300,height:100,textAlign:'center',border:'1px solid #fff',borderRadius:15}}>*/}
                <Button type="primary" htmlType="submit">
                  등록
                </Button>
                <Button onClick={() => navigate(-1)} style={{ marginLeft: 5 }}>
                  {" "}
                  취소
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Content>
  );
};

export default Foldnameregist;
