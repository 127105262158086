import { request } from "../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지
import {
  printornot,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  outReason,
  userState,
  genderInfo,
} from "../config/staticInfo"; //===>수정  object변수 호출해서 선별출력위함
import {
  Link,
  useParams,
  useNavigate,
  Navigate,
  Redirect,
} from "react-router-dom";
import dayjs from "dayjs";
import { message } from "antd";

//+++++++++++++++++++++++++++++++++++++++이하 수정은 호출페이지에서 요청시 한개의 함수씩 수정할것+++++++++++++++++++++++++++

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

//유저 리스트 가져오기   axios페이지에서 생성한   request함수로 post형식으로 uri = /user/list 을 넘기면  node서버의 db접속 해서
//자동으로 데이타를 가져오면서 m_id: <Link to={"/user/detail/" + result.faq_no}>{result.m_id}</Link>,를건다
export const getsurveylist = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/survey/list", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람1112" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const surveylist = res.data.surveylist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          sv_no: result.sv_no,
          countans: (
            <Link to={"/service/surveyans/" + result.sv_no}>
              {result.countans ? result.countans : 0}
            </Link>
          ),
          sv_question: (
            <Link to={"/service/survey/detail/" + result.sv_no}>
              {result.sv_question}
            </Link>
          ),
          s_date: dayjs(result.s_date).format("YYYY-MM-DD"),
          e_date: dayjs(result.s_date).format("YYYY-MM-DD"),
          open_yn: result.open_yn,
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: surveylist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

//엑셀파일 다운시  리스트 가져오기//==>위  리스트의 리턴받은 데이타를 담은 변수 ipblocklist와  아래  ipblocklist 와동일해야 엑셀다운 버튼이 출력됨
//리스트 가져오기
export const getsurveyFullList = async (target, tableParams) => {
  //target: nodeserver>notice>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/survey/" + target, JSON.stringify(tableParams)) // nodeserver>notice> /notice/fullList을 실행한다===> 이부분변경시 다운버튼이 안뜨는 경우가있음
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하 node에서 리턴받은 ipblocklist 수정,매칭
        const surveyList = res.data.surveyList.map((result, idx) => {
          return {
            sv_no: result.sv_no,
            countans: result.countans,
            sv_question: result.sv_question,
            list1: result.list1,
            list2: result.list2,
            list3: result.list3,
            list4: result.list4,
            list5: result.list5,
            s_date: datetimeFormat(result.s_date),
            e_date: datetimeFormat(result.e_date),
            reg_date: datetimeFormat(result.reg_date),
            open_yn: printornot[result.open_yn],
          };
        });

        const csv_headers = [
          { label: "고유번호", key: "sv_no" },
          { label: "설문제목", key: "sv_question" },
          { label: "답1", key: "list1" },
          { label: "답2", key: "list2" },
          { label: "답3", key: "list3" },
          { label: "답4", key: "list4" },
          { label: "답5", key: "list5" },
          { label: "설문시작일", key: "s_date" },
          { label: "설문종료일", key: "e_date" },
          { label: "등록일", key: "reg_date" },
          { label: "출력여부", key: "open_yn" },
        ];

        return { csv_data: surveyList, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

//상세정보 가져오기, 상세페이지====>수정
export const getsurveydetail = async (sv_no) => {
  console.log("sv_no" + sv_no);
  return request("POST", `/survey/detail/${sv_no}`)
    .then((res) => {
      //상세 정보에서 사용 가능하도록 처리//randomInfo수정하지말것
      console.log("resrandom", JSON.stringify(res));

      //const infodata = res.data.infodata[0];// 질문관련 qnatable select data
      //const qnaInfoans = res.data.qnaans[0];// 질문에대한 답변관련 qnatableans select data
      const tcount = res.data.tcount[0]; //총참여자
      const list1pick = res.data.list1pick[0].list1pick; //답1선택한사람 수
      const list2pick = res.data.list2pick[0].list2pick; //답2선택한사람 수
      const list3pick = res.data.list3pick[0].list3pick; //답3선택한사람 수
      const list4pick = res.data.list4pick[0].list4pick; //답4선택한사람 수
      const list5pick = res.data.list5pick[0].list5pick; //답5선택한사람 수

      let ans1 = ((list1pick / tcount.tcount) * 100).toFixed(2);

      console.log("계산" + ans1);
      //let  list1=

      const surveyInfo = res.data.info[0];
      const renderInfo = [
        { name: "고유번호", value: surveyInfo.sv_no },
        { name: "설문제목", value: surveyInfo.sv_question },
        {
          name: "참여자",
          value: tcount.tcount ? (
            <Link to={"/service/surveyans/" + surveyInfo.sv_no}>
              {tcount.tcount}
            </Link>
          ) : (
            0
          ),
        },
        {
          name: "답1",
          value:
            surveyInfo.list1 +
            "  /  참여:" +
            list1pick +
            "  /  " +
            ((list1pick / tcount.tcount) * 100).toFixed(2) +
            "%",
        },
        {
          name: "답2",
          value:
            surveyInfo.list2 +
            "  /  참여:" +
            list2pick +
            "  /  " +
            ((list2pick / tcount.tcount) * 100).toFixed(2) +
            "%",
        },
        {
          name: "답3",
          value:
            surveyInfo.list3 +
            "  /  참여:" +
            list3pick +
            "  /  " +
            ((list3pick / tcount.tcount) * 100).toFixed(2) +
            "%",
        },
        {
          name: "답4",
          value:
            surveyInfo.list4 +
            "  /  참여:" +
            list4pick +
            "  /  " +
            ((list4pick / tcount.tcount) * 100).toFixed(2) +
            "%",
        },
        {
          name: "답5",
          value:
            surveyInfo.list5 +
            "  /  참여:" +
            list5pick +
            "  /  " +
            ((list5pick / tcount.tcount) * 100).toFixed(2) +
            "%",
        },
        { name: "설문시작일", value: datetimeFormat(surveyInfo.s_date) },
        { name: "설문종료일", value: datetimeFormat(surveyInfo.e_date) },
        { name: "등록일", value: datetimeFormat(surveyInfo.reg_date) },
        { name: "출력여부", value: printornot[surveyInfo.open_yn] },
        //{ name: "링크메뉴명", value: randomInfo.link_name},
      ];

      return renderInfo; //node에 요청해서 받아정리한 renderInfo 배열을 이함수를 호출한 페이지에 리턴한다
    })
    .catch((err) => console.log(err));
};

// 상세정보 가져오기  수정및 기타 파일들에서 호출====>수정
export const getsurveyInfo = async (sv_no) => {
  console.log("edit from >sv_no", sv_no);
  return request("POST", `/survey/detail/${sv_no}`).then((res) => {
    //return request("POST", `/notice/detail/${faq_no}`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const survey = res.data.info[0];
    console.log("edit>getsurveyInfo", survey);
    return survey;
  });
};

//등록 regist===>수정
export const registsurvey = async (params) => {
  console.log("넘긴값1>날자추가" + JSON.stringify(params));
  return (
    request("POST", "/survey/regist", JSON.stringify(params))
      /*   */
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );
};

//수정페이지
export const updatesurvey = async (params, sv_no) => {
  console.log("수정넘긴값1>" + JSON.stringify(params) + "/" + sv_no);
  return (
    request("POST", "/survey/update/" + sv_no, JSON.stringify(params))
      //return request("POST", `/notice/update/`+ JSON.stringify(params.faq_subject))
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );
};

// 리스트에서  다중체크후  삭제처리
export const deletechecks = async (list) => {
  //LIST(인수명selectedRowKeys와 틀려도 상관없음) 는 NOTICESERVICE> deletechecks(selectedRowKeys)로 넘겨온 체크된 faq_no리스트들임
  if (list.length > 0) {
    if (window.confirm("선택한 데이타를 삭제 하시겠습니까? .")) {
      return request("DELETE", "/survey/deletechecks/", JSON.stringify(list))
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));
          if (res.data.message === "success") {
            //const history = useHistory();
            alert("삭제되었습니다");
            const navigate = useNavigate();
            navigate("/survey/detail"); // 삭제후 이동안됨====> 메인에서 삭제하므로 변동없어도됨=> 꼭필요하면 이부분이 ipblocklist에 이동해야함
            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
          }
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};
