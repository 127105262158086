import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Main from "./components/Main";
import Signin from "./pages/Signin";
import Terms from "./pages/Terms";
import NotFound from "./pages/error/NotFound";
import Cardfomula from "./pages/card/Cardfomula";
//import Testmove from "./pages/Testmove"; //import Boardlist from "./pages/Board/Boardlist"; //import Boardnew from "./pages/Board/Boardnew"; //import Boardcontent from "./pages/Board/Boardcontent";
//import UserList from "./pages/user/UserList"; //import UserRegist from "./pages/user/UserRegist"; //import UserWithdraw from "./pages/user/UserWithdraw"; //import UserDetail from "./pages/user/UserDetail";
//import UserModify from "./pages/user/UserModify"; //import Terms from "./pages/user/Terms"; //import UserLog from "./pages/user/UserLog";

//메뉴등록방법
//1.main page에 router를 추가한다 2.component>sidemenu에 왼쪽메뉴를 추가한다 3. 페이지로드가 안되면  하단에 keyname값을 등록한다
function App() {
  const users = JSON.parse(sessionStorage.getItem("user"));
  console.log("app>session정보는2" + JSON.stringify(users));

  return (
    <BrowserRouter forceRefresh={true}>
      <Routes>
        {/* 무조건 처음은 로그인 페이지로이동  login만 있었슴*/}
        <Route path="/index" element={<Signin />} />
        <Route path="/login" element={<Signin />} />
        <Route path="/main" element={<Signin />} />
        <Route path="/card/fomula" element={<Cardfomula />} />
        <Route path="/terms/:flag" element={<Terms />} />
        {/* 이위치에있어야 카드등록시 로그인된상태로 공식선택 등록 페이지가 출력됨*/}
        {/*  내가만든 테스트용 route
        <Route path='testmove' element={<Testmove />} />
        <Route path="Boardlist" element={<Boardlist/>} />
        <Route path="Boardnew" element={<Boardnew/>} />
        <Route path="Boardcontent" element={<Boardcontent/>} />

    여기서 아래를 등록하면  왼쪽메뉴없이 우측 컨텐츠만 출력된다
        <Route exact path="user" element={<UserList />} />
          <Route exact path="user/detail/:m_no" element={<UserDetail />} />
          <Route exact path="user/modify/:m_no" element={<UserModify />} />
          <Route exact path="user/regist" element={<UserRegist />} />
          <Route exact path="user/withdraw" element={<UserWithdraw />} />
          <Route exact path="user/terms" element={<Terms />} />
          <Route exact path="user/log" element={<UserLog />} />
  */}
        {/** 로그인 하지 않은 상태에서 아래조건에 속하면  로그인 페이지로이동     로그인한상태 이면  아래조건의 주소입력시 해당페이지로이동          */}
        <Route path="/" element={<PrivateRoute />}>
          {[
            "",
            "user/*",
            "card/*",
            "folder/*",
            "game/*",
            "push/*",
            "random/*",
            "popup/*",
            "qna/*",
            "faq/*",
            "service/*",
            "survey/*",
            "surveyans/*",
            "notice/*",
            "ipblock/*",
            "report/*",
            "log/*",
            "static/*",
            "tutorial/*",
            "fomula/*",
            "foldname/*",
            "moreview/*",
          ].map((path) => (
            <Route path={path} element={<Main />} />
          ))}
        </Route>
        {/** 에러페이지 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

{
  /* 
예전원본파일

    <BrowserRouter forceRefresh={true}>
      <Routes>
        <Route path="/login" element={<Signin />} />

        <Route path="/" element={<PrivateRoute />}>
          {["", "user/*", "card/*", "folder/*"].map((path) => (
            <Route path={path} element={<Main />} />
          ))}
        </Route>
   
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>

*/
}
