import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout, Form, Input, Button, Divider, Row, Col } from "antd";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import { getTerms, updateTerms } from "../service/userService";

const { Content } = Layout; //antdesign 코드를 간편하게하기위한 태그변경함수

const Terms = () => {
  const [originalTerm, setOriginalTerm] = useState();
  const { flag } = useParams();

  //DB에서 약관 데이터 가져오기
  const fetchData = async () => {
    const termsInfo = await getTerms();
    console.log("termsInfo", termsInfo);

    //페이지가 열렸을때 값을 저장
    setOriginalTerm({
      te_no: [termsInfo[0]?.te_no, termsInfo[1]?.te_no, termsInfo[2]?.te_no],
      te_title: [
        termsInfo[0]?.te_title,
        termsInfo[1]?.te_title,
        termsInfo[2]?.te_title,
      ],
      te_detail: [
        termsInfo[0]?.te_detail,
        termsInfo[1]?.te_detail,
        termsInfo[2]?.te_detail,
      ],
    });
  };

  //초기값
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Content style={{ margin: "0 16px" }}>
      <div style={{ fontSize: 20, marginBottom: 15 }}>
        {flag === "private"
          ? originalTerm?.te_title[1]
          : originalTerm?.te_title[0]}
      </div>
      <div style={{ whiteSpace: "pre" }}>
        {flag === "private"
          ? originalTerm?.te_detail[1]
          : originalTerm?.te_detail[0]}
      </div>
    </Content>
  );
};

export default Terms;
