import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Col, Button, Row, Space, Image } from "antd";
import { Link, useParams } from "react-router-dom";
import { getreportdetail } from "../../service/reportService"; //===>수정
import { useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import { request } from "../../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지
import { message } from "antd";

// +++++++++++++++++++++++신고카드는 회원들고유권한이므로  수정,삭제 하지말것++++++++++++++++++++++++++++++++++++

const { Content } = Layout;

const Reportdetail = () => {
  const navigate = useNavigate();
  const { ba_no } = useParams(); //===>수정
  const [data, setData] = useState();
  const [backUrl, setbackUrl] = useState("/service/report"); //===> 수정 목록버튼클릭후 이동으로 변경했으나
  //console.log("faq_no"+ JSON.stringify(useParams()));//값을 못가져오는경우 main>route지정경로에서 파라미터 네임이 틀린경우이다
  const fetchData = async () => {
    //===>수정
    const reportinfo = await getreportdetail(ba_no); //==>수정
    console.log("reportinfo", reportinfo); //==>수정
    setData(reportinfo); //==>수정
  };

  useEffect(() => {
    fetchData();

    //상세 페이지를 회원목록, 탈퇴목록에서 사용하기위한 목록 버튼 URL
    setbackUrl(sessionStorage.getItem("backUrl"));
  }, []);

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/service/random" style={{ color: "#333333" }}>
            서비스관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/service/report" style={{ color: "#333333" }}>
            신고카드 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item reloadDocument style={{ color: "#333333" }}>
          <Link reloadDocument style={{ color: "#333333" }}>
            신고카드상세
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Row style={{ border: "0px solid #333", marginBottom: 20 }}>
          {/*gutter={[컬럼왼쪽 마진, 컬럼 상하마진]} */}
          {data?.map((result, idx) => {
            //data에 저장된  결과값이 있으면 result에 배열로 담아 출력, 배열의 idx(임의 순서를매긴 번호== for문과 동일)
            return (
              <>
                <Col span={24} style={{ border: "0px solid #333" }}>
                  {/*name=form에서 지정한 name*/}
                  <div
                    style={{
                      position: "relative",
                      float: "left",
                      width: "20%",
                      border: "0px solid #333",
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      backgroundColor: "#fff",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    {result.name}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      float: "left",
                      width: "80%",
                      border: "0px solid #333",
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      backgroundColor: "#fff",
                    }}
                  >
                    {result.value}
                  </div>
                </Col>
              </>
            );
          })}
        </Row>

        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space wrap>
              {/*<Button size="middle" htmlType='button' type='dashed' onClick={() => navigate(`/report/modify/${ba_no}`)}//===> 수정
                style={{}}>
                수정
              </Button>
              <Button danger size="middle"  htmlType='button'  type='primary' onClick={() => deletefaq(ba_no)}>
                삭제
              </Button>*/}
              <Button
                size="middle"
                type="default"
                style={{ width: 80, backgroundColor: "", color: "" }}
                onClick={() => navigate("/service/report", ba_no)}
              >
                {/*<Button size="middle"  type='primary' icon={<SearchOutlined />} style={{ backgroundColor: "" ,color:''}} onClick={() => navigate('/faq',faq_no)}>
                //===> 수정 <Button size="large" onClick={() => navigate(backUrl)}>이부분은 세션으로 backUrl을 저장*/}
                목록
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Reportdetail;
