import { request } from "../utils/axios";
import {
  agreeYN,
  useYN,
  delYN,
  badYN,
  commonYN,
  careason,
  userGradeInfo,
} from "../config/staticInfo";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { message, Button } from "antd";
import cryptoRandomString from "crypto-random-string";
import { Image } from "antd";

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

export const createCardIndex = (id, count = 0) => {
  //ca_아이디_랜덤6자
  let cardIndex =
    "ca_" + id + "_" + cryptoRandomString({ length: 6, type: "alphanumeric" });
  console.log(cardIndex);
  console.log(count);

  if (count > 10) {
    message.error("카드 고유번호를 생성하지 못했습니다.");
    return false;
  }

  //인덱스 중복 확인
  if (checkCardDuplicate({ target: "ca_index", value: cardIndex }) === true) {
    return createCardIndex(id, count++);
  } else {
    return cardIndex;
  }
};

//카드 정보 중복 검사
export const checkCardDuplicate = async (param) => {
  return request("POST", "/card/duplicate", JSON.stringify(param))
    .then((res) => {
      //결과가 0보다 크다면 중복됨, 아니면 없음
      return res.data.count > 0 ? true : false;
    })
    .catch((err) => console.log(err));
};
//카드 리스트 가져오기
export const getCardList = async (tableParams) => {
  return request("POST", "/card/list", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const cardList = res.data.cardList.map((result, idx) => {
        return {
          key: idx,
          ...result,
          ca_keyword: (
            <Link to={"/card/detail/" + result.ca_no}>{result.ca_keyword}</Link>
          ),
        };
      });
      return { list: cardList, total: res.data.cardTotal.total };
    })
    .catch((err) => console.log(err));
};

//카드 리스트 가져오기 엑셀용
export const getCardFullList = async (tableParams) => {
  return request("POST", "/card/fullList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const cardList = res.data.cardList.map((result, idx) => {
        return {
          ca_no: result.ca_no,
          m_id: result.m_id,
          m_grade: userGradeInfo[result.m_grade],
          ca_index: result.ca_index,
          fo_index: result.fo_index,
          fo_name: result.fo_name,
          ca_keyword: result.ca_keyword,
          ca_detail: result.ca_detail,
          ca_liked: useYN[result.ca_liked],
          x_number: result.x_number,
          t_number: result.t_number,
          time_set: result.time_set,
          bad_yn: badYN[result.bad_yn],
          del_yn: delYN[result.del_yn],
          reg_date: datetimeFormat(result.reg_date),
          ca_del_time: datetimeFormat(result.ca_del_time),
        };
      });

      const csv_headers = [
        { label: "고유번호", key: "ca_no" },
        { label: "아이디", key: "m_id" },
        { label: "회원구분", key: "m_grade" },
        { label: "카드고유번호", key: "ca_index" },
        { label: "폴더고유번호", key: "fo_index" },
        { label: "폴더명", key: "fo_name" },
        { label: "키워드명", key: "ca_keyword" },
        { label: "설명내용", key: "ca_detail" },
        { label: "즐겨찾기 여부", key: "ca_liked" },
        { label: "틀린횟수", key: "x_number" },
        { label: "총게임횟수", key: "t_number" },
        { label: "시간제한", key: "time_set" },
        { label: "신고여부", key: "bad_yn" },
        { label: "삭제여부", key: "del_yn" },
        { label: "카드 생성시간", key: "reg_date" },
        { label: "카드 삭제시간", key: "ca_del_time" },
      ];
      return { csv_data: cardList, csv_header: csv_headers };
    })
    .catch((err) => console.log(err));
};

//카드 삭제처리
export const deleteCard = async (list) => {
  if (list.length > 0) {
    if (window.confirm("삭제 하시겠습니까? 유저가 볼 수 없도록 처리됩니다.")) {
      return request("DELETE", "/card/delete/", JSON.stringify(list))
        .then((res) => {
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//카드 내용 수정
export const updateCard = async (params, ca_no) => {
  return request("POST", "/card/update/" + ca_no, JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//카드 등록
export const registCard = async (params) => {
  return request("POST", "/card/regist", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//카드 상세정보 가져오기
export const getCardDetail = async (ca_no) => {
  return request("POST", `/card/detail/${ca_no}`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const cardInfo = res.data[0];

    let reportReason = "";
    let reportReasonTitle = "";
    if (cardInfo.bad_yn === 1) {
      reportReasonTitle = "신고사유";
      reportReason = careason[cardInfo.reason];
    }
    const renderInfo = [
      { name: "일련번호", value: cardInfo.ca_no },
      { name: "", value: "" },

      { name: "아이디", value: cardInfo.m_id },
      { name: "카드 고유번호", value: cardInfo.ca_index },

      { name: "폴더명", value: cardInfo.fo_name },
      { name: "폴더 고유번호", value: cardInfo.fo_index },

      { name: "틀린횟수", value: cardInfo.x_number },
      { name: "총 게임횟수", value: cardInfo.t_number },

      { name: "시간제한", value: cardInfo.time_set + "초" },
      { name: "즐겨찾기여부", value: commonYN[cardInfo.ca_liked] },

      { name: "삭제여부", value: delYN[cardInfo.del_yn] },
      { name: "신고여부", value: badYN[cardInfo.bad_yn] },

      { name: "등록일시", value: datetimeFormat(cardInfo.reg_date) },
      { name: reportReasonTitle, value: reportReason },

      { name: "키워드명", value: cardInfo.ca_keyword },

      { name: "설명내용", value: cardInfo.ca_detail },
    ];
    return renderInfo;
  });
};

//카드 상세정보 가져오기
export const getCardInfo = async (ca_no) => {
  return request("POST", `/card/detail/${ca_no}`).then((res) => {
    const cardInfo = res.data[0];
    console.log("cardInfo", cardInfo);
    return cardInfo;
  });
};

export const getfomulalist = async (tableParams, moreButton = null) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/fomula/list", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람1112" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑

      const fomulalist = res.data.fomulalist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        /**/
        if (moreButton) {
          return {
            key: idx,
            fom_no: result.fom_no,
            //fom_name: <Link to={"/fomula/detail/" + result.fom_no}>{result.fom_name}</Link>,split("\n")
            fom_name: result.fom_name,
            //fom_detail: result.fom_detail.split("\n"),
            fom_detail: result.fom_detail.substr(0, 30),
            fom_image: <Image width={200} src={result.fom_image} />,
            reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
            mbutton: (
              <Button onClick={() => moreButton(result.fom_image)}>+</Button>
            ),
          };
        } else {
          return {
            key: idx,
            fom_no: result.fom_no,
            //fom_name: <Link to={"/fomula/detail/" + result.fom_no}>{result.fom_name}</Link>,split("\n")
            fom_name: result.fom_name,
            //fom_detail: result.fom_detail.split("\n"),
            fom_detail: result.fom_detail.substr(0, 30),
            fom_image: <Image width={200} src={result.fom_image} />,
            reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
          };
        }
      });
      return { list: fomulalist }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};
