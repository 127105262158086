import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./store";
//react-redux사용방법 https://m.blog.naver.com/wj8606/221843221092
//antd 한글 적용
import { ConfigProvider } from "antd";//contextapi와 비슷한기능을 갖음
import koKR from "antd/locale/ko_KR";


const root = ReactDOM.createRoot(document.getElementById("root"));//가상DOM(Virtual DOM)을 통해 변경된 부분의 UI만 효율적으로 업데이트
root.render(
  <Provider store={store}>
    <ConfigProvider locale={koKR}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ConfigProvider>
  </Provider>
);
// strict 모드란 react 앱 내의 잠재적인 문제를 알아내기 위한 도구로  배포가 되고나면 strict 모드는 저절로 작동하지 않음
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();// 웹상태감시용 컴포넌트 나중확인할것
