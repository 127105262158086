import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const DashboardListCard = ({ title, content, list, link, theme }) => {
  const { titles, values } = content;
  const [cardBackground, setCardBackground] = useState({
    background: "#f1f1f1",
  });
  const [cardColor, setCardColor] = useState({
    color: "#000000",
  });

  useEffect(() => {
    if (theme === "dark") {
      console.log(theme);
      setCardBackground({
        background: "#4c4c4c",
      });
      setCardColor({
        color: "#ffffff",
      });
    }
  }, [theme]);

  return (
    <>
      <div style={{ padding: "10px" }}>
        <Row gutter={24}>
          <Col span={20}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: 17,
                border: "0px solid red",
              }}
            >
              {title}
            </Typography>
          </Col>
          <Col span={4} style={{ paddingTop: "4px", border: "0px solid red" }}>
            <Link to={link}>
              {/*"/faq/detail/" + result.faq_no*/}
              <RightCircleOutlined />
            </Link>
          </Col>
        </Row>
      </div>
      <Card
        bordered={false}
        style={{
          ...cardBackground,
          padding: "15px 0",
          border: "0px solid red",
        }}
      >
        <Card.Grid hoverable={false} style={{ ...gridStyle, width: "40%" }}>
          <div style={{ marginBottom: 7 }}>
            <Typography style={{ fontSize: 11, ...cardColor }}>
              {titles[0]}
            </Typography>
            <Typography
              style={{ ...cardColor, fontSize: 16, fontWeight: "bold" }}
            >
              {values[0]}
            </Typography>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Typography style={{ fontSize: 11, ...cardColor }}>
              {titles[1]}
            </Typography>
            <Typography
              style={{ ...cardColor, fontSize: 16, fontWeight: "bold" }}
            >
              {values[1]}
            </Typography>
          </div>
          <div>
            <Typography style={{ fontSize: 11, ...cardColor }}>
              {titles[2]}
            </Typography>
            <Typography
              style={{ ...cardColor, fontSize: 16, fontWeight: "bold" }}
            >
              {values[2]}
            </Typography>
          </div>
        </Card.Grid>
        <Card.Grid hoverable={false} style={{ ...gridStyle, width: "60%" }}>
          {list.map((data) => (
            <div style={listStyle}>
              {data.title}{" "}
              <span style={{ fontWeight: "bold" }}>{data.value}</span>
            </div>
          ))}
        </Card.Grid>
      </Card>
    </>
  );
};

const gridStyle: React.CSSProperties = {
  width: "50%",
  textAlign: "center",
  padding: "10px",
  border: "none",
  boxShadow: "none",
};

const listStyle: React.CSSProperties = {
  textAlign: "left",
  fontSize: 13,
};
export default DashboardListCard;
