import { request } from "../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지
import {
  printornot,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  outReason,
  userState,
  genderInfo,
  popupFlag,
} from "../config/staticInfo"; //===>수정  object변수 호출해서 선별출력위함
import {
  Link,
  useParams,
  useNavigate,
  Navigate,
  Redirect,
} from "react-router-dom";
import dayjs from "dayjs";
import { message } from "antd";

//+++++++++++++++++++++++++++++++++++++++이하 수정은 호출페이지에서 요청시 한개의 함수씩 수정할것+++++++++++++++++++++++++++

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

//유저 리스트 가져오기   axios페이지에서 생성한   request함수로 post형식으로 uri = /user/list 을 넘기면  node서버의 db접속 해서
//자동으로 데이타를 가져오면서 m_id: <Link to={"/user/detail/" + result.faq_no}>{result.m_id}</Link>,를건다
export const getpopuplist = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/popup/list", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람1112" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const popuplist = res.data.popuplist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          pop_no: result.pop_no,
          pop_flag: popupFlag[result.pop_flag],
          pop_subject: (
            <Link to={"/service/popup/detail/" + result.pop_no}>
              {result.pop_subject}
            </Link>
          ),
          pop_dates: dayjs(result.pop_dates).format("YYYY-MM-DD"),
          pop_datee: dayjs(result.pop_datee).format("YYYY-MM-DD"),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
          view_yn: result.view_yn,
        };
      });
      return { list: popuplist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

//엑셀파일 다운시  리스트 가져오기//==>위  리스트의 리턴받은 데이타를 담은 변수 ipblocklist와  아래  ipblocklist 와동일해야 엑셀다운 버튼이 출력됨
//리스트 가져오기
export const getpopupFullList = async (target, tableParams) => {
  //target: nodeserver>notice>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/popup/" + target, JSON.stringify(tableParams)) // nodeserver>notice> /notice/fullList을 실행한다===> 이부분변경시 다운버튼이 안뜨는 경우가있음
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하 node에서 리턴받은 ipblocklist 수정,매칭
        const popupexlist = res.data.popupexlist.map((result, idx) => {
          return {
            pop_no: result.pop_no,
            pop_flag: popupFlag[result.pop_flag],
            pop_subject: result.pop_subject,
            pop_text: result.pop_text,
            pop_dates: datetimeFormat(result.pop_dates),
            pop_datee: datetimeFormat(result.pop_datee),
            reg_date: datetimeFormat(result.reg_date),
            view_yn: printornot[result.view_yn],
          };
        });

        const csv_headers = [
          { label: "고유번호", key: "pop_no" },
          { label: "구분", key: "pop_flag" },
          { label: "제목", key: "pop_subject" },
          { label: "내용", key: "pop_text" },
          { label: "시작일", key: "pop_dates" },
          { label: "종료일", key: "pop_datee" },
          { label: "등록일", key: "reg_date" },
          { label: "출력여부", key: "view_yn" },
        ];

        return { csv_data: popupexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

//상세정보 가져오기, 상세페이지====>수정
export const getpopupdetail = async (pop_no) => {
  console.log("pop_no" + pop_no);
  return request("POST", `/popup/detail/${pop_no}`)
    .then((res) => {
      //상세 정보에서 사용 가능하도록 처리//randomInfo수정하지말것
      console.log("resrandom", JSON.stringify(res));
      const popupInfo = res.data[0];
      const renderInfo = [
        { name: "고유번호", value: popupInfo.pop_no },
        { name: "구분", value: popupFlag[popupInfo.pop_flag] },
        { name: "제목", value: popupInfo.pop_subject },
        { name: "내용", value: popupInfo.pop_text },
        { name: "팝업이미지", value: popupInfo.pop_image },
        { name: "출력시작일", value: datetimeFormat(popupInfo.pop_dates) },
        { name: "출력종료일", value: datetimeFormat(popupInfo.pop_datee) },
        { name: "출력여부", value: printornot[popupInfo.view_yn] },
        { name: "등록일", value: datetimeFormat(popupInfo.reg_date) },
        //{ name: "링크메뉴명", value: randomInfo.link_name},
      ];

      return renderInfo; //node에 요청해서 받아정리한 renderInfo 배열을 이함수를 호출한 페이지에 리턴한다
    })
    .catch((err) => console.log(err));
};

// 상세정보 가져오기  수정및 기타 파일들에서 호출====>수정
export const getpopupInfo = async (pop_no) => {
  console.log("edit from >pop_no", pop_no);
  return request("POST", `/popup/detail/${pop_no}`).then((res) => {
    //return request("POST", `/notice/detail/${faq_no}`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const popup = res.data[0];
    console.log("edit>getnoticeInfo", popup);
    return popup;
  });
};

//app더보기 등록==> 수정 보완할것
export const moredata = async (params) => {
  /*return request("POST", "/user/regist", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));*/
};

//등록 regist===>수정
export const registpopup = async (params) => {
  console.log("넘긴값1>날자추가" + JSON.stringify(params));
  return (
    request("POST", "/popup/regist", JSON.stringify(params))
      /*   */
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );
};

//수정페이지
export const updatepopup = async (params, pop_no) => {
  console.log("수정넘긴값1>" + JSON.stringify(params) + "/" + pop_no);
  return (
    request("POST", "/popup/update/" + pop_no, JSON.stringify(params))
      //return request("POST", `/notice/update/`+ JSON.stringify(params.faq_subject))
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );
};

//FAQ삭제  ===> 삭제후 페이지이동이 되지않아 여기에 이부분을 faqdetail로 옯김
/*
export const deletefaq = async (faq_no) => {
  console.log("삭제번호넘긴값>" + JSON.stringify(faq_no));
  
  if(faq_no){
    
    if (window.confirm(faq_no + "를 삭제 하시겠습니까? 삭제하시면 복구가불가능합니다")) {
    return request("DELETE", `/faq/delete/${faq_no}`)
  
    .then((res) => {    
      console.log("삭제결과>" + JSON.stringify(res.data.message));
      
      //res?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${faq_no}`" />// 삭제후 이동안됨
      
      if(res.data.message==='success'){
        //const history = useHistory();
        alert('삭제되었습니다');
        const navigate = useNavigate();
        navigate("/notice");// 삭제후 이동안됨

        //history.push('/notice');// 삭제후 이동안됨
        //navigate("/notice");// 삭제후 이동안됨
        //res.data.message==='success'?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${faq_no}`" />
        //<Redirect to={{pathname: '/notice', }}/>
      }
      //<Navigate to="http://localhost:3000/notice/" /> 삭제후이동
      return res.data;
     })
    .catch((err) => console.log(err));
    }

  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }

};
*/

// 리스트에서  다중체크후  삭제처리
export const deletechecks = async (list) => {
  //LIST(인수명selectedRowKeys와 틀려도 상관없음) 는 NOTICESERVICE> deletechecks(selectedRowKeys)로 넘겨온 체크된 faq_no리스트들임
  if (list.length > 0) {
    if (window.confirm("선택한 데이타를 삭제 하시겠습니까? .")) {
      return request("DELETE", "/popup/deletechecks/", JSON.stringify(list))
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));
          if (res.data.message === "success") {
            //const history = useHistory();
            alert("삭제되었습니다");
            const navigate = useNavigate();
            navigate("/popup/detail"); // 삭제후 이동안됨====> 메인에서 삭제하므로 변동없어도됨=> 꼭필요하면 이부분이 ipblocklist에 이동해야함
            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
          }
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//+++++++++++++++++++++++++++++++++++++++이하는 수정하지말고 ++++++++++++++++++++++++나중 확인후 삭제할것++++++++++++++++++
//유저 상세정보 가져오기
export const getTerms = async () => {
  return request("POST", `/user/terms`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const termsInfo = res.data;
    console.log("userinfo1", termsInfo);
    return termsInfo;
  });
};

//유저 등록
export const updateTerms = async (params) => {
  return request("POST", "/user/updateTerms/", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//유저 로그 리스트 가져오기
export const getUserLogList = async (tableParams) => {
  return request("POST", "/user/logList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          key: idx,
          us_no: result.us_no,
          m_id: result.m_id,
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: logList, total: res.data.logTotal.total };
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기
export const getSimpleStats = async () => {
  return request("POST", `/user/simpleStats`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("유저상세정보result", result);
    return result;
  });
};

//유저 아이디 리스트 가져오기
export const getUserIdList = async (param) => {
  const target = param?.target ? param.target : null;
  const value = param?.value ? param.value : null;
  return request(
    "POST",
    `/simpleQuery`,
    JSON.stringify({
      col: ["m_id"],
      table: "mem_info",
      target: target,
      value: value,
    })
  ).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    //console.log("result", result);
    return result;
  });
};
//+++++++++++++++++++++++++++++++++++++++이하는 수정하지말고 ++++++++++++++++++++++++나중 확인후 삭제할것  END++++++++++++++++++
