import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  Table,
  Space,
  DatePicker,
  Input,
  Select,
  message,
} from "antd";
import { FileExcelOutlined, ReloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { Link, useNavigate } from "react-router-dom";
import type { FilterValue } from "antd/es/table/interface";
import { CSVLink } from "react-csv";

import {
  getUserList,
  getUserFullList,
  deleteUser,
  getSimpleStats,
} from "../../service/userService";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
+++++++++++관리자에서는 회원별데이타를 가져올필요없고 admin관리자인지 메니져인지에  따라 crud권한만 접근/접근불가 로 구분해서 코딩할것 +++++++++++
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
//테이블의  column 헤더에 출력되는 1row 값

const columns: ColumnsType = [
  {
    title: "No",
    dataIndex: "m_no",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "아이디",
    dataIndex: "m_id",
  },
  { title: "이메일", dataIndex: "m_email" },
  { title: "닉네임", dataIndex: "m_nname" },
  {
    title: "회원구분",
    dataIndex: "m_grade",
    filters: [
      { text: "관리자", value: 1 },
      { text: "매니저", value: 2 },
      { text: "초등학생", value: 3 },
      { text: "중학생", value: 4 },
      { text: "고등학생", value: 5 },
      { text: "대학생", value: 6 },
      { text: "시험준비생", value: 7 },
      { text: "교사강사", value: 8 },
      { text: "기타", value: 9 },
    ], //필터는 리스트에서 클릭시 자동 정렬되는 구문임 불필요시 삭제할것
  },
  { title: "학년", dataIndex: "grade" },
  { title: "생년월일", dataIndex: "birth" },
  {
    title: "성별",
    dataIndex: "gender",
    filters: [
      { text: "미선택", value: null },
      { text: "남자", value: 1 },
      { text: "여자", value: 2 },
      //{ text: "기타", value: 3 },
    ], //필터는 리스트에서 클릭시 자동 정렬되는 구문임 불필요시 삭제할것
  },
  {
    title: "사용여부",
    dataIndex: "use_state",
    render: (use_state) => {
      switch (use_state) {
        default:
        case 1:
          return `사용중`;
        case 2:
          return `탈퇴`;
        case 3:
          return `휴면`;
      }
    },
    filters: [
      { text: "사용중", value: 1 },
      { text: "탈퇴", value: 2 },
      { text: "휴면", value: 3 },
    ], //필터는 리스트에서 클릭시 자동 정렬되는 구문임 불필요시 삭제할것
  },
  {
    title: "가입일",
    dataIndex: "intime",
  },
];

const UserList = () => {
  const { user } = useSelector((state) => state.auth); ///==>무슨의미? 어디서 호출?

  console.log("userinfo정보는" + JSON.stringify(user));
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState();
  const [dateRange, setDateRange] = useState(["", ""]);
  const [searchTarget, setSearchTarget] = useState();
  const [dateTarget, setDateTarget] = useState();
  const [loading, setLoading] = useState(false);
  const [csvComponent, setCsvCompnent] = useState(<></>);

  //++++++++++++++초기로딩시  유저상태값을가져온다  아무것도 안찍히고 초기값만찍힘
  const [userStats, setUserStats] = useState({
    today: 0,
    total: 0,
    use_state: { 1: 0, 2: 0, 3: 0 }, //무슨의미인지?
  });

  //기본 테이블 설정
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  //+++++++++++++++++++++++++++++++0. 로그인시 저장된 세션 정보를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    //// 세션정보를 가져올수 없음   ===> 원격지에서는 같은서버로 세션정보를 가져올수 있음 //xn--pn3b68hmps.com/user
    //alert('0');
    const users = JSON.parse(sessionStorage.getItem("user"));
    console.log("userlist>session정보는" + JSON.stringify(users));
  }, []);

  //console.log('테이블명정보' + JSON.stringify( tableParams));
  //use_state별로 가져온 total수를 (3개상태별로 카운트를한숫자3개를  use_state: { 1: 0, 2: 0, 3: 0 }, 순서대로  리턴한다
  const getUserStats = async () => {
    const statsResult = await getSimpleStats(); // service>userService.js >  getSimpleStats()  함수를 실행해서 mode에 던지고 결과를 리텀받는다\
    let stateCount = [];

    //상태별 배열에 저장
    statsResult.stateCount.forEach((info) => {
      stateCount[info.use_state] = info.total;
    });

    setUserStats({
      today: statsResult.todayJoin?.total, //오늘의 가입자  계: service>userService.js > getSimpleStats()를 실행해서 node>user 로 todayJoin 변수를 리턴받음
      use_state: stateCount,
      // ㅇ위 상태별 가입자들을 모두더한 값이 total임
      total: statsResult.stateCount.reduce((acc, cur, i) => {
        return acc + cur.total;
      }, 0),
    });
    console.log("페이지로드시 가져온 회원정보", statsResult);
  };
  //+++++++++++++++++++++++++++++++1. getUserStats 살행해  유저상태별값에따를 총계를 가져온다userdata를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    sessionStorage.setItem("backUrl", "/user"); ///backUrl에 /user란 경로 등록==> 무슨의미인가???
    getUserStats();
    //alert('1');
  }, []);

  //테이블  체크박스를 클릭할때 select가 변경되었을때 no값을 리턴한다
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  //날짜 선택시
  const onSearchDateChange = (dates, dateStrings) => {
    console.log("선택한 날짜는" + dateStrings); //시작일dateStrings[0]   종료일dateStrings[1]    2023-01-11식으로출력
    setDateRange(dateStrings);
  };

  //검색필드 선택시 value와 option값을 인수로 넘겨  setSearchTarget()를 업데이트한다
  const onSelectSearch = (value, option) => {
    setSearchTarget(value);
  };
  const onSelectSearchDate = (value, option) => {
    setDateTarget(value);
  };

  //antd 라이브러리에서 검색 input박스 에 버튼까지 연결시킨 input 박스임 입력검색 버튼을 눌렀을때 이 컴포의 value
  const onSearch = (value) => {
    // (검색값value가 있고 검색필드에서 값이 선택됬다면 ) || (검색시작일과종료일이 선택된  검색구간)
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
      searchDate: dateRange,
      dateTarget: dateTarget,
      searchParam: { target: searchTarget, value: value },
    });
  };

  //+++++++++++++++++++++++++++++++3. data를 가져와  리턴받은 값을 setData()로 data변수에 리턴한다+++++++++++++++++++++++++++++++
  const fetchData = async () => {
    //alert('3');
    setLoading(true);
    /*fetch(
      `//xn--pn3b68hmps.com/api/user/list?${qs.stringify(
        (tableParams)
      )}`
    )*/
    console.log("tableParams", tableParams);
    const memberResult = await getUserList(tableParams); //// service>userService.js >  getUserList()  함수를 실행해서 node에 던지고 결과를 리턴받는다
    //console.log('memberResult'+JSON.stringify( memberResult,null,2))
    setData(memberResult.list);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: memberResult.total,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  //+++++++++++++++++++++++++++++++2.  tableParams 변경시  fetchData();함수를 1번실행해서 데이타를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    //alert('2');
    fetchData();
    setCsvCompnent(<></>);
  }, [JSON.stringify(tableParams)]);

  //console.log('datasms'+JSON.stringify( fetchData(),null,2))

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter
  ) => {
    console.log("table chagne는", pagination, filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters,
      sorter,
    });
  };

  const downloadCSV = async () => {
    const { csv_data, csv_header } = await getUserFullList(
      "fullList",
      tableParams
    );
    setCsvCompnent(
      <CSVLink
        data={csv_data}
        headers={csv_header}
        filename={dayjs().format("YYYYMMDD") + "_user_list.csv"}
      >
        <Button className="exelButton">
          <FileExcelOutlined />
          받기
        </Button>
      </CSVLink>
    );
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/user" style={{ color: "#333333" }}>
            회원관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            회원목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => navigate(0)}
          ></Button>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea" style={{ padding: 30 }}>
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col span={6}></Col>
          <Col span={18}>
            <div style={{ textAlign: "right", fontSize: 16 }}>
              오늘 가입자:{userStats.today}명, 총 회원:{userStats.total}명 (
              사용중:{userStats.use_state[1] ? userStats.use_state[1] : 0}명,
              휴면:{userStats.use_state[2] ? userStats.use_state[2] : 0}
              명, 탈퇴: {userStats.use_state[3] ? userStats.use_state[3] : 0}명
              )
            </div>
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{ paddingBottom: 15, border: "0px solid #999" }}
        >
          <Col span={8}>
            <Space wrap>
              {/*아래는  서버에서 사용시코드이고 local에서사용시{user.user.m_grade} 한다*/}
              {user.m_grade === 1 ? (
                <Button danger onClick={() => deleteUser("")}>
                  선택 삭제
                </Button>
              ) : null}
              <Button onClick={downloadCSV}>현재 목록 엑셀로</Button>
              {csvComponent}
            </Space>
          </Col>
          <Col
            span={16}
            style={{ textAlign: "right", border: "0px solid #999" }}
          >
            <Space style={{ float: "right" }}>
              <Input.Group
                compact
                style={{
                  float: "left",
                  minWidth: 330,
                  border: "0px solid #999",
                }}
              >
                <Select
                  placeholder="검색필드"
                  allowClear
                  style={{ width: "30%" }}
                  onChange={onSelectSearch}
                  dropdownMatchSelectWidth={250}
                >
                  <Option value="m_id">아이디</Option>
                  <Option value="m_nname">닉네임</Option>
                  <Option value="m_email">이메일</Option>
                  <Option value="grade">학년</Option>
                  <Option value="birth">생년월일(ex:20160717) </Option>
                  <Option value="email_yn">
                    이메일수신동의:동의 1, 동의안함 2{" "}
                  </Option>
                </Select>
                <Search
                  placeholder="검색어 입력"
                  allowClear
                  enterButton
                  defaultValue={""}
                  onSearch={onSearch}
                  style={{
                    width: "70%",
                  }}
                />
              </Input.Group>
            </Space>
            <Space style={{ float: "right" }}>
              <Input.Group compact>
                <Select
                  placeholder="검색일자"
                  allowClear
                  style={{ minWidth: 120 }}
                  onChange={onSelectSearchDate}
                >
                  <Option value="intime">가입일</Option>
                  <Option value="outtime">탈퇴일</Option>
                  <Option value="last_login_time">최근 로그인</Option>
                  <Option value="last_logout_time">최근 로그아웃</Option>
                </Select>
                <DatePicker.RangePicker
                  style={{ width: 240 }}
                  locale="ko_KR"
                  onChange={onSearchDateChange}
                />
              </Input.Group>
            </Space>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              columns={columns} //위에서지정한 컬럼타입을 저장한변수
              dataSource={data}
              pagination={{
                ...tableParams.pagination,
                position: ["bottomCenter"],
              }}
              onChange={handleTableChange}
              rowKey={(record) => record.m_no}
              className="userlist-table"
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default UserList;
