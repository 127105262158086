import { request } from "../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지
import {
  oxconf,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  outReason,
  userState,
  genderInfo,
  userGradeInfo,
} from "../config/staticInfo"; //===>수정  object변수 호출해서 선별출력위함
import {
  Link,
  useParams,
  useNavigate,
  Navigate,
  Redirect,
} from "react-router-dom";
import dayjs from "dayjs";
import { message } from "antd";

//+++++++++++++++++++++++++++++++++++++++이하 수정은 호출페이지에서 요청시 한개의 함수씩 수정할것+++++++++++++++++++++++++++

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

//유저 리스트 가져오기   axios페이지에서 생성한   request함수로 post형식으로 uri = /user/list 을 넘기면  node서버의 db접속 해서
//자동으로 데이타를 가져오면서 m_id: <Link to={"/user/detail/" + result.faq_no}>{result.m_id}</Link>,를건다
export const getgamelist = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/game/list", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람1112" + JSON.stringify(res.data));
      //console.log('반환온파람1112'+JSON.stringify(res.data)+JSON.stringify('쿼리는' + res.data.sql))
      //리스트에 사용 가능하도록 리스트 맵핑
      const gamelist = res.data.gamelist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        let score = ((result.correct / result.card_count) * 100).toFixed(0);
        console.log("반환온파람111123" + JSON.stringify(res.data.gamelist));
        return {
          key: idx,
          num: result.num,
          gh_index: (
            <Link to={"/game/detail/" + result.gh_index + "/" + result.m_id}>
              {result.gh_index}
            </Link>
          ),
          m_id: result.m_id,
          m_email: result.m_email,
          m_nname: result.m_nname,
          grade: result.grade,
          m_grade: userGradeInfo[result.m_grade],
          birth: result.birth ? dayjs(result.birth).format("YYYY-MM-DD") : "",
          //save_time:datetimeFormat(result.save_time),//  2023-02-03 15:17:25
          save_time: dayjs(result.save_time).format("YYYY-MM-DD"), //2023-02-03
          correct: result.correct,
          wrong: result.wrong,
          card_count: result.card_count,
          score: score,
        };
      });
      return { list: gamelist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

//엑셀파일 다운시  리스트 가져오기//==>위  리스트의 리턴받은 데이타를 담은 변수 ipblocklist와  아래  ipblocklist 와동일해야 엑셀다운 버튼이 출력됨
//리스트 가져오기
export const getgameFullList = async (target, tableParams) => {
  //target: nodeserver>notice>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/game/" + target, JSON.stringify(tableParams)) // nodeserver>notice> /notice/fullList을 실행한다===> 이부분변경시 다운버튼이 안뜨는 경우가있음
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하 node에서 리턴받은 ipblocklist 수정,매칭
        const gameexlist = res.data.gameexlist.map((result, idx) => {
          let score = ((result.correct / result.card_count) * 100).toFixed(0);
          return {
            //gh_no: result.gh_no, 합산해서 가져오므로 고유번호는 없고 전체 체크삭제불필요
            key: idx,
            num: result.num,
            gh_index: result.gh_index,
            m_id: result.m_id,
            m_email: result.m_email,
            m_nname: result.m_nname,
            grade: result.grade,
            m_grade: userGradeInfo[result.m_grade],
            birth: result.birth ? dayjs(result.birth).format("YYYY-MM-DD") : "",
            save_time: dayjs(result.save_time).format("YYYY-MM-DD"), //2023-02-03
            correct: result.correct,
            wrong: result.wrong,
            card_count: result.card_count,
            score: score,
          };
        });

        const csv_headers = [
          { label: "고유번호", key: "num" }, //합산해서 가져오므로 고유번호는 없고 전체 체크삭제불필요
          { label: "게임고유번호", key: "gh_index" },
          { label: "아이디", key: "m_id" },
          { label: "닉네임", key: "m_nname" },
          { label: "회원구분", key: "m_grade" },
          { label: "학년", key: "grade" },
          { label: "생년월일", key: "birth" },
          { label: "저장시간", key: "save_time" },
          { label: "정답수", key: "correct" },
          { label: "오답수", key: "wrong" },
          { label: "총카드수", key: "card_count" },
          { label: "점수", key: "score" },
        ];

        return { csv_data: gameexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

//상세정보 가져오기, 상세페이지====>수정
export const getgamedetail = async (gh_index, m_id) => {
  console.log("gh_index:" + gh_index + "m_id:" + m_id);
  return request("POST", `/game/cardList/${gh_index}/${m_id}`)
    .then((res) => {
      console.log("return" + gh_index + "/" + m_id);
      //상세 정보에서 사용 가능하도록 처리//randomInfo수정하지말것
      console.log("resrandom11", JSON.stringify(res.data));
      const gametopinfo = res.data.gametopinfo[0];
      //const cardList = res.data.cardList;
      //const cardInfo = res.data.cardInfo;
      console.log("gametopinfo11", JSON.stringify(gametopinfo)); //gh_index 와  card_list들이출력
      //console.log("cardList11",JSON.stringify(cardList));//gh_index 와  card_list들이출력
      //console.log("cardInfo11",JSON.stringify(cardInfo));// 각카드들의 정보가 출력
      //let score=(result.correct/result.card_count*100).toFixed(0);
      const renderInfo = [
        { name: "게임고유번호", value: gametopinfo.gh_index },
        { name: "저장시간", value: gametopinfo.save_time },
        { name: "정답", value: gametopinfo.correct },
        { name: "오답", value: gametopinfo.wrong },
        { name: "총", value: gametopinfo.card_count },
        {
          name: "점수",
          value: ((gametopinfo.correct / gametopinfo.card_count) * 100).toFixed(
            2
          ),
        },
      ];
      return renderInfo; //node에 요청해서 받아정리한 renderInfo 배열을 이함수를 호출한 페이지에 리턴한다
    })
    .catch((err) => console.log(err));
};

//상세정보 가져오기, 상세페이지====>수정
export const getgamedetail1 = async (gh_index, m_id) => {
  console.log("ghca_index1:" + gh_index + "m_id:" + m_id);
  return request("POST", `/game/cardList1/${gh_index}/${m_id}`)
    .then((res) => {
      const cardinfolist1 = res.data.cardInfo;
      console.log("반환온파람1112347" + JSON.stringify(cardinfolist1));

      //console.log('반환온파람1112'+JSON.stringify(res.data)+JSON.stringify('쿼리는' + res.data.sql))
      //리스트에 사용 가능하도록 리스트 맵핑

      const cardinfolist = res.data.cardInfo.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        console.log("반환온파람11123467" + JSON.stringify(result));
        return {
          key: idx,
          ...result,
          ca_keyword: (
            <Link to={"/card/detail/" + result.ca_no}>{result.ca_keyword}</Link>
          ),
        };
      });

      return cardinfolist; //++++++++++++++++수정+++++++++++++++
      //return 'test';
    })
    .catch((err) => console.log("nodata" + err));
};

// 상세정보 가져오기  수정및 기타 파일들에서 호출====>수정
export const getgameInfo = async (gh_no) => {
  console.log("edit from >gh_no", gh_no);
  return request("POST", `/game/detail/${gh_no}`).then((res) => {
    //return request("POST", `/notice/detail/${faq_no}`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const game = res.data[0];
    console.log("edit>getnoticeInfo", game);
    return game;
  });
};

//app더보기 등록==> 수정 보완할것
export const moredata = async (params) => {
  return request("POST", "/user/regist", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//등록 regist===>수정
export const registgame = async (params) => {
  console.log("넘긴값1>날자추가" + JSON.stringify(params));
  return (
    request("POST", "/game/regist", JSON.stringify(params))
      /*   */
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );
};

//수정페이지
export const updategame = async (params, gh_no) => {
  console.log("수정넘긴값1>" + JSON.stringify(params) + "/" + gh_no);
  return (
    request("POST", "/game/update/" + gh_no, JSON.stringify(params))
      //return request("POST", `/notice/update/`+ JSON.stringify(params.faq_subject))
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );
};

// 리스트에서  다중체크후  삭제처리
export const deletechecks = async (list) => {
  //LIST(인수명selectedRowKeys와 틀려도 상관없음) 는 NOTICESERVICE> deletechecks(selectedRowKeys)로 넘겨온 체크된 faq_no리스트들임
  if (list.length > 0) {
    if (window.confirm("선택한 데이타를 삭제 하시겠습니까? .")) {
      return request("DELETE", "/game/deletechecks/", JSON.stringify(list))
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));
          if (res.data.message === "success") {
            //const history = useHistory();
            alert("삭제되었습니다");
            const navigate = useNavigate();
            navigate("/game/detail"); // 삭제후 이동안됨====> 메인에서 삭제하므로 변동없어도됨=> 꼭필요하면 이부분이 ipblocklist에 이동해야함
            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
          }
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//+++++++++++++++++++++++++++++++++++++++이하는 수정하지말고 ++++++++++++++++++++++++나중 확인후 삭제할것++++++++++++++++++
//유저 상세정보 가져오기
export const getTerms = async () => {
  return request("POST", `/user/terms`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const termsInfo = res.data;
    console.log("userinfo1", termsInfo);
    return termsInfo;
  });
};

//유저 등록
export const updateTerms = async (params) => {
  return request("POST", "/user/updateTerms/", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//유저 로그 리스트 가져오기
export const getUserLogList = async (tableParams) => {
  return request("POST", "/user/logList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          key: idx,
          us_no: result.us_no,
          m_id: result.m_id,
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: logList, total: res.data.logTotal.total };
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기
export const getSimpleStats = async () => {
  return request("POST", `/user/simpleStats`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("유저상세정보result", result);
    return result;
  });
};

//유저 상세정보 가져오기
export const getSimpleStats1 = async () => {
  return request("POST", `/game/simpleStats1`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("게임통계정보result", result);
    return result;
  });
};

//유저 아이디 리스트 가져오기
export const getUserIdList = async (param) => {
  const target = param?.target ? param.target : null;
  const value = param?.value ? param.value : null;
  return request(
    "POST",
    `/simpleQuery`,
    JSON.stringify({
      col: ["m_id"],
      table: "mem_info",
      target: target,
      value: value,
    })
  ).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    //console.log("result", result);
    return result;
  });
};
//+++++++++++++++++++++++++++++++++++++++이하는 수정하지말고 ++++++++++++++++++++++++나중 확인후 삭제할것  END++++++++++++++++++
