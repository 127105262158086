import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  BarChartOutlined,
  IdcardOutlined,
  FolderOutlined,
  BulbOutlined,
  BellOutlined,
  ToolOutlined,
  FunctionOutlined,
  ReadOutlined,
  FolderOpenOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import "../css/Main.css";
import { Link, useLocation } from "react-router-dom";

//1. 아래메뉴를 추가한후 2.main페이지에서 router를 추가한다3. 페이지로드가 안되면 app의 하단에 keyname값을 등록한다

const SideMenu = () => {
  const location = useLocation();
  //현재 메뉴 path를 key로 하여 선택된 메뉴 표시
  const [current, setCurrent] = useState(location.pathname);

  const onMenu = (e) => {
    setCurrent(e.key);
  };

  const [openKeys, setOpenkeys] = useState([]); //펼쳐져 있을 메뉴

  useEffect(() => {
    const pathname = location.pathname; // 현재 위치
    const splitPathName = pathname.split("/"); // '/'기준으로 위치 나누기

    console.log("splitPathName", splitPathName);
    const openKey =
      splitPathName.length === 4
        ? [`${splitPathName[1]}`, `${splitPathName[1]}/${splitPathName[2]}`] //오픈된 메뉴가 2단의 메뉴인 경우
        : [`${splitPathName[1]}`]; //1단만 열려 있는경우

    console.log("openKey", openKey);
    setOpenkeys([...openKeys, openKey]);
    setCurrent(pathname);
  }, [location.pathname]);

  /* 이하 상단메뉴 삭제
    {
      label: <Link to="/">대시보드</Link>,
      key: "/",
      icon: React.createElement(DashboardOutlined),
    }, // remember to pass the key prop
  */

  const items = [
    {
      label: "회원관리",
      key: "user",
      icon: React.createElement(UserOutlined),
      children: [
        { label: <Link to="/user">회원목록</Link>, key: "/user" },
        { label: <Link to="/user/regist">회원등록</Link>, key: "/user/regist" },
        {
          label: <Link to="/user/withdraw">탈퇴목록</Link>,
          key: "/user/withdraw",
        },
        { label: <Link to="/user/terms">약관관리</Link>, key: "/user/terms" },
        { label: <Link to="/user/log">접속기록</Link>, key: "/user/log" },
      ],
    },

    {
      label: "복습카드",
      key: "card",
      icon: React.createElement(IdcardOutlined),
      children: [
        { label: <Link to="/card">복습카드 목록</Link>, key: "/card" },
        {
          label: <Link to="/card/regist">복습카드 등록</Link>,
          key: "/card/regist",
        },
        {
          label: <Link to="/card/wrong">틀린 복습카드 목록</Link>,
          key: "/card/wrong",
        },
        {
          label: <Link to="/card/favorite">즐겨찾기 목록</Link>,
          key: "/card/favorite",
        },
      ],
    },
    {
      label: "복습폴더",
      key: "folder",
      icon: React.createElement(FolderOutlined),
      children: [
        { label: <Link to="/folder">복습폴더 목록</Link>, key: "/folder" },
        {
          label: <Link to="/folder/regist">복습폴더 등록</Link>,
          key: "/folder/regist",
        },
        {
          label: <Link to="/folder/public">공유폴더 목록</Link>,
          key: "/folder/public",
        },
        /*{
          label: <Link to="/folder/static">고정폴더 목록</Link>,
          key: "/folder/static",
        },*/
      ],
    },
    {
      label: "복습게임기록",
      key: "/game",
      icon: React.createElement(BulbOutlined),
      children: [
        { label: <Link to="/game">복습게임기록</Link>, key: "/game" }, //같은 링크를 사용하려면 key값을 다르게 해야 클릭시 배경이 파란색으로 변경된다
        //  { label: <Link to="/game">푸시전송 이력</Link>, key: "/game1" },
      ],
    },

    {
      label: "서비스관리",
      key: "service",
      icon: React.createElement(ToolOutlined),
      children: [
        {
          label: <Link to="/service/random">랜덤카피관리</Link>,
          key: "/service/random",
        },
        {
          label: <Link to="/service/popup">팝업관리</Link>,
          key: "/service/popup",
        },
        { label: <Link to="/service/qna">1:1문의</Link>, key: "/service/qna" },
        { label: <Link to="/service/faq">FAQ</Link>, key: "/service/faq" },
        {
          label: <Link to="/service/survey">설문조사</Link>,
          key: "/service/survey",
        },
        /*{
          label: <Link to="/service/surveyans">설문조사참여자</Link>,
          key: "/service/surveyans",
        },*/
        {
          label: <Link to="/service/notice">공지</Link>,
          key: "/service/notice",
        },
        /*{
          label: <Link to="/service/ipblock">IP접근허용</Link>,
          key: "/service/ipblock",
        },*/
        {
          label: <Link to="/service/report">신고카드</Link>,
          key: "/service/report",
        },
        {
          label: <Link to="/service/moreview">더보기관리</Link>,
          key: "/service/moreview",
        },
      ],
    },
    {
      label: "튜토리얼 관리",
      key: "/tutorial",
      icon: React.createElement(ReadOutlined),
      children: [
        { label: <Link to="/tutorial">튜토리얼 관리</Link>, key: "/tutorial" },
      ],
    },
    {
      label: "공식등록 관리",
      key: "/fomula",
      icon: React.createElement(FunctionOutlined),
      children: [
        { label: <Link to="/fomula">공식등록 관리</Link>, key: "/fomula" },
      ],
    },
    {
      label: "회원구분폴더관리",
      key: "/foldname",
      icon: React.createElement(FolderOpenOutlined),
      children: [
        {
          label: <Link to="/foldname">회원구분별폴더관리</Link>,
          key: "/foldname",
        },
      ],
    },
    {
      label: "로그",
      key: "log",
      icon: React.createElement(LineChartOutlined),
      children: [
        {
          label: <Link to="/log/utimedaylist">일별 사용시간</Link>,
          key: "/log/utimedaylist",
        },
        {
          label: <Link to="/log/utimemonthlist">월별 사용시간</Link>,
          key: "/log/utimemonthlist",
        },
        {
          label: <Link to="/log/cardstaticlist">복습카드 로그</Link>,
          key: "/log/cardstaticlist",
        },
        {
          label: <Link to="/log/foldstaticlist">복습폴더 로그</Link>,
          key: "/log/foldstaticlist",
        },
        {
          label: <Link to="/log/gamestaticlist">복습게임 로그</Link>,
          key: "/log/gamestaticlist",
        },
      ],
    },
    {
      label: "통계",
      key: "static",
      icon: React.createElement(BarChartOutlined),
      children: [
        {
          label: <Link to="/static/cardstaticday">복습카드 일통계</Link>,
          key: "/static/cardstaticday",
        },
        {
          label: <Link to="/static/cardstaticmonth">복습카드 월통계</Link>,
          key: "/static/cardstaticmonth",
        },
        {
          label: <Link to="/static/foldstaticday">복습폴더 일통계</Link>,
          key: "/static/foldstaticday",
        },
        {
          label: <Link to="/static/foldstaticmonth">복습폴더 월통계</Link>,
          key: "/static/foldstaticmonth",
        },
        {
          label: <Link to="/static/gamestaticday">복습게임 일통계</Link>,
          key: "/static/gamestaticday",
        },
        {
          label: <Link to="/static/gamestaticmonth">복습게임 월통계</Link>,
          key: "/static/gamestaticmonth",
        },
        {
          label: <Link to="/static/foldrank">폴더 순위</Link>,
          key: "/static/foldrank",
        },
        {
          label: <Link to="/static/sharefoldrank">공유폴더 순위</Link>,
          key: "/static/sharefoldrank",
        },
      ],
    },
    {
      label: (
        <a
          target="_blank"
          href="https://console.firebase.google.com/project/tmd-boksup/messaging?hl=ko"
        >
          푸시알림관리
        </a>
      ),
      key: "push",
      icon: React.createElement(BellOutlined),
    },
  ];

  return (
    <>
      <Menu
        theme="dark"
        selectedKeys={[current]}
        openKeys={openKeys}
        onOpenChange={(openKeys) => setOpenkeys(openKeys)}
        mode="inline"
        items={items}
        onClick={onMenu}
        style={{
          fontSize: "10pt",
          color: "#fff",
          border: "0px solid red",
          borderRadius: 20,
          marginBottom: 20,
        }}
      ></Menu>
    </>
  );
};

export default SideMenu;
