import React, { useEffect, useState } from "react";
import {Layout,Breadcrumb,Button,DatePicker,Form,Input,Col,Select,Row,Radio,message,InputNumber,} from "antd";
import {userGradeInfo,agreeYN,useYN,loginYN,readYN,userState,genderInfo,regPhone,regPassword,} from "../../config/staticInfo";
import ImageUploader from "../../components/ImageUploader";
import { checkUserDuplicate, registUser } from "../../service/userService";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const { Content } = Layout;
const { Option } = Select;

const validateMessages = {
  required: "'${label}'을(를) 입력해주세요.",
  pattern: {
    mismatch: "'${label}' 형식에 맞게 작성해주세요.",
  },
  types: {
    email: "'${label}' 형식에 맞게 작성해주세요.",
  },

  // ...
};

const Pushsend = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  //중복검사가 필요한 항목만 설정
  const [dpCheck, setDpCheck] = useState({
    m_id: "",
    m_nname: "",
  });

  useEffect(() => {
    //초기값 설정
    form.setFieldsValue({
      conf_yn: 1,
      useminfo_yn: 1,
      push_yn: 2,
      email_yn: 2,
      use_state: 1,
    });
  }, []);

  const onFinish = async (values) => {
    const dpCheckSuccess = Object.entries(dpCheck).filter((result) => {
      return result[1].includes("success");
    });
    //success가 2개가 아니면 실행하지 않음
    if (dpCheckSuccess.length < 2) {
      message.error("중복확인을 진행하신 후 등록 가능합니다.");
      return false;
    }

    const registParam = {
      ...values,
      birth: dayjs(values.birth).format("YYYYMMDD"),
      pic_route: values.pic_route.file.url,
    };
    const result = await registUser(registParam);

    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("등록이 완료되었습니다.");
        navigate("/user/detail/" + result.result.insertId);
        break;
      case "exist id":
        message.error("이미 존재하는 ID입니다.");
        break;
      case "exist nname":
        message.error("이미 존재하는 닉네임입니다.");
        break;
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //중복확인
  const checkDuplicate = async (target) => {
    const value = form.getFieldValue(target);
    if (value) {
      const checkResult = await checkUserDuplicate({
        target: target,
        value: value,
      });

      //결과값이 true면 중복된값(이미 존재하는 값) false일때 사용가능
      if (checkResult) {
        form.setFields([{ name: target, errors: ["사용중인 값입니다."] }]);
        setDpCheck({ ...dpCheck, [target]: "error" });
      } else {
        setDpCheck({ ...dpCheck, [target]: "success" });
      }
    }
  };

  //중복검사한 후에 값을 변경했을 경우 해당 항목 다시 검사 필요하도록 초기화
  const initCheck = (target) => {
    setDpCheck({ ...dpCheck, [target]: "" });
  };
  return (
    <Content style={{ margin: "0 16px",border:'1px solid #f8f8f8',backgroundColor:'#fff',marginTop:15 ,boxShadow:'1px 1.5px 5px 1px #ebebeb',borderRadius:15,}}>
      <Breadcrumb style={{ padding: "20px 0px 0px 30px " ,borderBottom :'1px solid #ebebeb',fontWeight:'bold',color:'#333', fontSize:14,height:55,}}>
        <Breadcrumb.Item>푸시알림관리</Breadcrumb.Item>
        <Breadcrumb.Item>푸시알림보네기</Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="회원구분"
                name="m_grade"
                rules={[{ required: true }]}
              >
                <Select placeholder="==선택==" allowClear>
                  {Object.entries(userGradeInfo).map((result, idx) => {
                    return <Option value={result[0]}>{result[1]}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="아이디"
                name="m_id"
                hasFeedback
                rules={[{ required: true }, { min: 5, max: 12 }]}
                validateStatus={dpCheck.m_id}
              >
                <Input
                  allowClear
                  onChange={() => initCheck("m_id")}
                  addonAfter={
                    <Button
                      type="text"
                      size="small"
                      onClick={() => checkDuplicate("m_id")}
                    >
                      중복확인
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item
                name="m_nname"
                label="닉네임"
                rules={[{ required: true }, { min: 2, max: 12 }]}
                hasFeedback
                validateStatus={dpCheck.m_nname}
              >
                <Input
                  allowClear
                  onChange={() => initCheck("m_nname")}
                  addonAfter={
                    <Button
                      type="text"
                      size="small"
                      onClick={() => checkDuplicate("m_nname")}
                    >
                      중복확인
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item
                label="이메일"
                name="m_email"
                rules={[{ required: true }, { type: "email" }]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="UID기기번호"
                name="hp_uid"
                rules={[{ required: true }]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="비밀번호"
                name="m_pw"
                rules={[
                  { required: true },
                  { min: 8, max: 20 },
                  {
                    pattern: regPassword,
                    message:
                      "비밀번호는 영문, 숫자, 특수문자를 혼합하여야 합니다.",
                  },
                ]}
              >
                <Input.Password allowClear />
              </Form.Item>
              <Form.Item label="이용약관동의" name="conf_yn">
                <Radio.Group>
                  <Radio value={1}>{agreeYN[1]}</Radio>
                  <Radio value={2}>{agreeYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="개인정보처리동의" name="useminfo_yn">
                <Radio.Group>
                  <Radio value={1}>{agreeYN[1]}</Radio>
                  <Radio value={2}>{agreeYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="푸시알림수신동의" name="push_yn">
                <Radio.Group>
                  <Radio value={1}>{agreeYN[1]}</Radio>
                  <Radio value={2}>{agreeYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="이메일수신동의" name="email_yn">
                <Radio.Group>
                  <Radio value={1}>{agreeYN[1]}</Radio>
                  <Radio value={2}>{agreeYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="전화번호"
                name="m_hp"
                rules={[{ required: true }, { pattern: regPhone }]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="성별"
                name="gender"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={1}>{genderInfo[1]}</Radio>
                  <Radio value={2}>{genderInfo[2]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="학년" name="grade">
                <InputNumber allowClear />
              </Form.Item>
              <Form.Item
                label="생년월일"
                name="birth"
                rules={[{ required: true }]}
              >
                <DatePicker style={{ float: "left" }} locale="ko_KR" />
              </Form.Item>
              <Form.Item label="회원상태" name="use_state">
                <Radio.Group>
                  <Radio value={1}>{userState[1]}</Radio>
                  <Radio value={2}>{userState[2]}</Radio>
                  <Radio value={3}>{userState[3]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="프로필 사진" name="pic_route">
                <ImageUploader target="profile" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                <Button type="primary" htmlType="submit">
                  등록
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Content>
  );
};

export default Pushsend;
