import { request } from "../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지
import { userGradeInfo, delYN, staticState } from "../config/staticInfo";
import {
  Link,
  useParams,
  useNavigate,
  Navigate,
  Redirect,
} from "react-router-dom";
import dayjs from "dayjs";
import { message } from "antd";

//+++++++++++++++++++++++++++++++++++++++이하 수정은 호출페이지에서 요청시 한개의 함수씩 수정할것+++++++++++++++++++++++++++

//date 포멧을 바꾸거나 없으면 공백
const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

const datetimeFormatmonth = (date) => {
  return date ? dayjs(date).format("YYYY-M") : "";
};
/*  밀리세컨드로 변환해서 출력하기  2시간 20분30초방식으로 출력하기
var time1 = new Date("2019-09-03 12:00:00");
var time2 = new Date("2019-09-03 14:23:32");

 var milisecond =time2 - time1;// 위 사용시간을 밀리세컨드로 변환   8612000
 console.log(`milisecond는${milisecond}`)

 var carcu_time= Math.floor((milisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));// 시간부분문 출력   2시간
 var carcu_minute= Math.floor((milisecond % (1000 * 60 * 60)) / (1000 * 60));// 분부분만 출력   23분
 var carcu_second= Math.floor((milisecond % (1000 * 60)) / 1000);//초 부분만출력   32초
 var use_time=carcu_time+'시간' + carcu_minute +'분'+ carcu_second+'초';
 console.log(`사용시간${carcu_time}/사용분${carcu_minute}/사용초${carcu_second}/use_time${use_time}`);

 // 배열변수화시켜 출력하기
 const total_time={
  '총사용시간':carcu_time, '총사용분':carcu_minute, '총사용초':carcu_second,'use_time':use_time
 }
 console.log('종합'+JSON.stringify(total_time));
*/

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//+++++++++++++++++++++++++++++++++++++++++++++++통계관련 페이지 리스트 정리+++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

//유저 리스트 가져오기   axios페이지에서 생성한   request함수로 post형식으로 uri = /user/list 을 넘기면  node서버의 db접속 해서
//자동으로 데이타를 가져오면서 m_id: <Link to={"/user/detail/" + result.no_no}>{result.m_id}</Link>,를건다
export const utimeday_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/static/Utimedaylist", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const utimedaylist = res.data.utimedaylist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        //var milisecond= datetimeFormat(result.logout_time)- datetimeFormat(result.logout_time); 계산안됨
        let milisecond = result.usingtime;
        let carcu_time = Math.floor(
          (milisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ); // 시간부분문 출력   2시간
        let carcu_minute = Math.floor(
          (milisecond % (1000 * 60 * 60)) / (1000 * 60)
        ); // 분부분만 출력   23분
        let carcu_second = Math.floor((milisecond % (1000 * 60)) / 1000); //초 부분만출력   32초
        let minuteonly = Math.floor(milisecond / 60000);
        console.log("변환시간" + JSON.stringify(milisecond));
        return {
          key: idx,
          us_no: result.us_no,
          m_id: result.m_id,
          m_grade: userGradeInfo[result.m_grade],
          grade: result.grade,
          logintime: datetimeFormat(result.login_time),
          logouttime: datetimeFormat(result.logout_time),
          //usingtime:carcu_time +'시간'+carcu_minute+'분'+carcu_second+'초',
          usingtime: minuteonly <= 0 ? "1" : minuteonly,
          //usingtime: result.usingtime,
          //usingtime:datetimeFormat(result.logout_time)- datetimeFormat(result.logout_time),  NaN으로 출력
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: utimedaylist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const utimemonth_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/static/Utimemonthlist", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const utimemonthlist = res.data.utimemonthlist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        //var milisecond= datetimeFormat(result.logout_time)- datetimeFormat(result.logout_time); 계산안됨
        let milisecond = result.cal_time;
        //let carcu_time= Math.floor((milisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));// 시간부분문 출력   2시간
        //let carcu_minute= Math.floor((milisecond % (1000 * 60 * 60)) / (1000 * 60));// 분부분만 출력   23분
        //let carcu_second= Math.floor((milisecond % (1000 * 60)) / 1000);//초 부분만출력   32초
        let minuteonly = Math.floor(milisecond / 60000); // 분으로 나누어출력
        var summinuteonly = Math.floor(milisecond / 60000); // 분으로 나누어출력
        //var summinuteonly += summinuteonly;   안됨

        console.log("변환시간" + JSON.stringify(milisecond));
        return {
          key: idx,
          num: result.num,
          m_id: result.m_id,
          m_grade: userGradeInfo[result.m_grade],
          grade: result.grade,
          //logintime: datetimeFormat(result.login_time),
          //logouttime:datetimeFormat(result.logout_time),
          //usingtime:carcu_time +'시간'+carcu_minute+'분'+carcu_second+'초',
          caltime: minuteonly <= 0 ? "1" : minuteonly,
          //summinuteonly:summinuteonly1,
          //usingtime: result.usingtime,
          //usingtime:datetimeFormat(result.logout_time)- datetimeFormat(result.logout_time),  NaN으로 출력
          reg_date: dayjs(result.savedate).format("YYYY-MM"),
        };
      });
      return { list: utimemonthlist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const cardstatic_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/static/Cardstaticlist", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const cardstaticlist = res.data.cardstaticlist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++

        return {
          key: idx,
          cas_no: result.cas_no,
          m_id: result.m_id,
          ca_index: result.ca_index,
          cas_state: staticState[result.cas_state],
          m_grade: userGradeInfo[result.m_grade],
          grade: result.grade,
          //usingtime: result.usingtime,
          //usingtime:datetimeFormat(result.logout_time)- datetimeFormat(result.logout_time),  NaN으로 출력
          reg_date: dayjs(result.ca_st_date).format("YYYY-MM-DD"),
        };
      });
      return { list: cardstaticlist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const cardstaticday_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/static/Cardstaticdaylist",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const cardstaticdaylist = res.data.cardstaticdaylist.map(
        (result, idx) => {
          // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++

          return {
            key: idx,
            num: result.num,
            newcard: result.newcard,
            delcard: result.delcard,
            cas_state: result.cas_state,
            //usingtime: result.usingtime,
            //usingtime:datetimeFormat(result.logout_time)- datetimeFormat(result.logout_time),  NaN으로 출력
            savedate: dayjs(result.savedate).format("YYYY-MM-DD"),
          };
        }
      );
      return { list: cardstaticdaylist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const cardstaticmonth_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/static/Cardstaticmonthlist",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const cardstaticmonthlist = res.data.cardstaticmonthlist.map(
        (result, idx) => {
          // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++

          return {
            key: idx,
            num: result.num,
            newcard: result.newcard,
            delcard: result.delcard,
            cas_state: result.cas_state,
            //usingtime: result.usingtime,
            //usingtime:datetimeFormat(result.logout_time)- datetimeFormat(result.logout_time),  NaN으로 출력
            savedate: dayjs(result.savedate).format("YYYY-MM"),
          };
        }
      );
      return { list: cardstaticmonthlist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const folderstatic_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/static/Folderstaticlist",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const folderstaticlist = res.data.folderstaticlist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++

        return {
          key: idx,
          m_id: result.m_id,
          m_grade: userGradeInfo[result.m_grade],
          grade: result.grade,
          fos_no: result.fos_no,
          fo_index: result.fo_index,
          fo_name: result.fo_name,
          fos_state: staticState[result.fos_state],
          reg_date: dayjs(result.fo_st_date).format("YYYY-MM-DD"),
        };
      });
      return { list: folderstaticlist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const foldstaticday_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/static/Foldstaticdaylist",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const foldstaticdaylist = res.data.foldstaticdaylist.map(
        (result, idx) => {
          // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
          return {
            key: idx,
            num: result.num,
            newfold: result.newfold,
            delfold: result.delfold,
            savedate: dayjs(result.savedate).format("YYYY-MM-DD"),
          };
        }
      );
      return { list: foldstaticdaylist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const foldstaticmonth_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/static/Foldstaticmonthlist",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const foldstaticmonthlist = res.data.foldstaticmonthlist.map(
        (result, idx) => {
          // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
          return {
            key: idx,
            num: result.num,
            newfold: result.newfold,
            delfold: result.delfold,
            savedate: dayjs(result.savedate).format("YYYY-MM"),
          };
        }
      );
      return { list: foldstaticmonthlist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const gamestaticday_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/static/Gamestaticdaylist",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const gamestaticdaylist = res.data.gamestaticdaylist.map(
        (result, idx) => {
          // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
          return {
            key: idx,
            num: result.num,
            daytotal: result.daytotal,
            savedate: dayjs(result.savedate).format("YYYY-MM-DD"),
          };
        }
      );
      return { list: gamestaticdaylist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const gamestaticmonth_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/static/Gamestaticmonthlist",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const gamestaticmonthlist = res.data.gamestaticmonthlist.map(
        (result, idx) => {
          // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
          return {
            key: idx,
            num: result.num,
            daytotal: result.daytotal,
            savedate: dayjs(result.savedate).format("YYYY-MM"),
          };
        }
      );
      return { list: gamestaticmonthlist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const foldrank_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request("POST", "/static/Foldranklist", JSON.stringify(tableParams))
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const foldranklist = res.data.foldranklist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          ranking: result.ranking,
          num: result.num,
          fo_name: result.fo_name,
        };
      });
      return { list: foldranklist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};

export const foldmoverank_list = async (tableParams) => {
  //++++++++++++++++수정+++++++++++++++
  console.log("넘겨온파람" + JSON.stringify(tableParams));
  return request(
    "POST",
    "/static/Foldmoveranklist",
    JSON.stringify(tableParams)
  )
    .then((res) => {
      console.log("반환온파람" + JSON.stringify(res.data));
      //리스트에 사용 가능하도록 리스트 맵핑
      const foldmoveranklist = res.data.foldmoveranklist.map((result, idx) => {
        // node에서 리턴한 noticelist 배열을 임의 idx배열순서대로 재저장 ++++++++++++++++이하 return{}수정+++++++++++++++
        return {
          key: idx,
          ranking: result.ranking,
          num: result.num,
          fo_name: result.fo_name,
        };
      });
      return { list: foldmoveranklist, total: res.data.memberTotal.total }; //++++++++++++++++수정+++++++++++++++
    })
    .catch((err) => console.log("nodata" + err));
};
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//+++++++++++++++++++++++++++++++++++++++++++++++엑셀다운리스트 정리+++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//엑셀다운 공지 리스트 가져오기  //엑셀파일 다운시  리스트 가져오기//==>위  리스트의 리턴받은 데이타를 담은 변수 ipblocklist와  아래  ipblocklist 와동일해야 엑셀다운 버튼이 출력됨
//유저 리스트 가져오기
export const getutimedayFullList = async (target, tableParams) => {
  //nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const usetimeexlist = res.data.usetimeexlist.map((result, idx) => {
          let milisecond = result.usingtime;
          //let minuteonly=((milisecond / (1000 * 60 )) % 60 );
          let minuteonly = Math.floor(milisecond / 60000);
          return {
            us_no: result.us_no,
            m_id: result.m_id,
            m_grade: userGradeInfo[result.m_grade],
            grade: result.grade,
            logintime: datetimeFormat(result.login_time),
            logouttime: datetimeFormat(result.logout_time),
            usingtime1: minuteonly <= 0 ? "1" : minuteonly,
            reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
          };
        });

        const csv_headers = [
          { label: "고유번호", key: "us_no" },
          { label: "회원아이디", key: "m_id" },
          { label: "회원구분", key: "m_grade" },
          { label: "학년", key: "grade" },
          { label: "로그인시간", key: "logintime" },
          { label: "로그아웃시간", key: "logouttime" },
          { label: "사용시간(분)", key: "usingtime1" },
          { label: "등록일", key: "reg_date" },
        ];

        return { csv_data: usetimeexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

//엑셀다운 공지 리스트 가져오기  //엑셀파일 다운시  리스트 가져오기//==>위  리스트의 리턴받은 데이타를 담은 변수 ipblocklist와  아래  ipblocklist 와동일해야 엑셀다운 버튼이 출력됨
//유저 리스트 가져오기
export const getutimemonthFullList = async (target, tableParams) => {
  //nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList1을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const usetime1exlist = res.data.usetime1exlist.map((result, idx) => {
          let milisecond = result.cal_time;
          let minuteonly = Math.floor(milisecond / 60000);
          return {
            ut_no: result.num,
            m_id: result.m_id,
            m_grade: userGradeInfo[result.m_grade],
            grade: result.grade,
            caltime: minuteonly <= 0 ? "1" : minuteonly,
            reg_date: datetimeFormatmonth(result.savedate),
          };
        });

        const csv_headers = [
          { label: "고유번호", key: "ut_no" },
          { label: "회원아이디", key: "m_id" },
          { label: "회원구분", key: "m_grade" },
          { label: "학년", key: "grade" },
          { label: "사용시간(분)", key: "caltime" },
          //{ label: "사용시간", key: "usingtime" },
          { label: "통계월", key: "reg_date" },
        ];

        return { csv_data: usetime1exlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

//유저 리스트 가져오기
export const getcardstaticFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const cardstaticexlist = res.data.cardstaticexlist.map(
          (result, idx) => {
            return {
              cas_no: result.cas_no,
              m_id: result.m_id,
              m_grade: userGradeInfo[result.m_grade],
              grade: result.grade,
              ca_index: result.ca_index,
              cas_state: staticState[result.cas_state],
              ca_st_date: dayjs(result.ca_st_date).format("YYYY-MM-DD"),
            };
          }
        );

        const csv_headers = [
          { label: "고유번호", key: "cas_no" },
          { label: "회원아이디", key: "m_id" },
          { label: "회원구분", key: "m_grade" },
          { label: "학년", key: "grade" },
          { label: "카드고유번호", key: "ca_index" },
          { label: "카드상태", key: "cas_state" },
          { label: "등록일", key: "ca_st_date" },
        ];

        return { csv_data: cardstaticexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

export const getcardstaticdayFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const cardstaticdayexlist = res.data.cardstaticdayexlist.map(
          (result, idx) => {
            return {
              num: result.num,
              newcard: result.newcard,
              delcard: result.delcard,
              //cas_state: result.cas_state,
              savedate: dayjs(result.savedate).format("YYYY-MM-DD"),
            };
          }
        );

        const csv_headers = [
          { label: "고유번호", key: "num" },
          { label: "생성카드", key: "newcard" },
          { label: "삭제카드", key: "delcard" },
          //{ label: "카드상태", key: "cas_state" },
          { label: "통계일", key: "savedate" },
        ];

        return { csv_data: cardstaticdayexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

export const getcardstaticmonthFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const cardstaticmonthexlist = res.data.cardstaticmonthexlist.map(
          (result, idx) => {
            return {
              num: result.num,
              newcard: result.newcard,
              delcard: result.delcard,
              //cas_state: result.cas_state,
              savedate: dayjs(result.savedate).format("YYYY-MM"),
            };
          }
        );

        const csv_headers = [
          { label: "고유번호", key: "num" },
          { label: "생성카드", key: "newcard" },
          { label: "삭제카드", key: "delcard" },
          //{ label: "카드상태", key: "cas_state" },
          { label: "통계일", key: "savedate" },
        ];

        return { csv_data: cardstaticmonthexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

export const getfolderstaticFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const folderstaticexlist = res.data.folderstaticexlist.map(
          (result, idx) => {
            return {
              fos_no: result.fos_no,
              m_id: result.m_id,
              m_grade: userGradeInfo[result.m_grade],
              grade: result.grade,
              fo_index: result.fo_index,
              fo_name: result.fo_name,
              fos_state: staticState[result.fos_state],
              fo_st_date: dayjs(result.fo_st_date).format("YYYY-MM-DD"),
            };
          }
        );

        const csv_headers = [
          { label: "고유번호", key: "fos_no" },
          { label: "회원아이디", key: "m_id" },
          { label: "회원구분", key: "m_grade" },
          { label: "학년", key: "grade" },
          { label: "폴더고유번호", key: "fo_index" },
          { label: "폴더명", key: "fo_name" },
          { label: "폴더상태", key: "fos_state" },
          { label: "등록일", key: "fo_st_date" },
        ];

        return { csv_data: folderstaticexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

export const getfoldstaticdayFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const foldstaticdayexlist = res.data.foldstaticdayexlist.map(
          (result, idx) => {
            return {
              num: result.num,
              newfold: result.newfold,
              delfold: result.delfold,
              //cas_state: result.cas_state,s
              savedate: dayjs(result.savedate).format("YYYY-MM-DD"),
            };
          }
        );

        const csv_headers = [
          { label: "고유번호", key: "num" },
          { label: "생성폴더", key: "newfold" },
          { label: "삭제폴더", key: "delfold" },
          //{ label: "카드상태", key: "cas_state" },
          { label: "통계일", key: "savedate" },
        ];

        return { csv_data: foldstaticdayexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

export const getfoldstaticmonthFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const foldstaticmonthexlist = res.data.foldstaticmonthexlist.map(
          (result, idx) => {
            return {
              num: result.num,
              newfold: result.newfold,
              delfold: result.delfold,
              //cas_state: result.cas_state,s
              savedate: dayjs(result.savedate).format("YYYY-MM"),
            };
          }
        );

        const csv_headers = [
          { label: "고유번호", key: "num" },
          { label: "생성폴더", key: "newfold" },
          { label: "삭제폴더", key: "delfold" },
          //{ label: "카드상태", key: "cas_state" },
          { label: "통계일", key: "savedate" },
        ];

        return { csv_data: foldstaticmonthexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

export const getgamestaticdayFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const gamestaticdayexlist = res.data.gamestaticdayexlist.map(
          (result, idx) => {
            return {
              num: result.num,
              daytotal: result.daytotal,
              savedate: dayjs(result.savedate).format("YYYY-MM"),
            };
          }
        );

        const csv_headers = [
          { label: "고유번호", key: "num" },
          { label: "생성게임수", key: "daytotal" },
          { label: "통계일", key: "savedate" },
        ];

        return { csv_data: gamestaticdayexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

export const getgamestaticmonthFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const gamestaticmonthexlist = res.data.gamestaticmonthexlist.map(
          (result, idx) => {
            return {
              num: result.num,
              daytotal: result.daytotal,
              savedate: dayjs(result.savedate).format("YYYY-MM"),
            };
          }
        );

        const csv_headers = [
          { label: "고유번호", key: "num" },
          { label: "생성게임수", key: "daytotal" },
          { label: "통계월", key: "savedate" },
        ];

        return { csv_data: gamestaticmonthexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

export const getfoldrankFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const gamestaticmonthexlist = res.data.gamestaticmonthexlist.map(
          (result, idx) => {
            return {
              ranking: result.ranking,
              num: result.num,
              fo_name: result.fo_name,
            };
          }
        );

        const csv_headers = [
          { label: "랭킹", key: "ranking" },
          { label: "생성폴더수", key: "num" },
          { label: "폴더명", key: "fo_name" },
        ];

        return { csv_data: gamestaticmonthexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};

export const getfoldmoverankFullList = async (target, tableParams) => {
  //target: nodeserver>static>에서 실행할 함수명
  console.log("넘겨온파람" + JSON.stringify(tableParams) + "/" + target); //{"pagination":{"current":1(다운할목록페이지),"pageSize":10(1페이지출력데이타),"total":9(총데이타)}}/fullList  로찍힘
  return (
    request("POST", "/static/" + target, JSON.stringify(tableParams)) // nodeserver>static> /static/fullList2을 실행한다
      //return request("POST", "/user/" + target, JSON.stringify(tableParams))
      .then((res) => {
        //리스트에 사용 가능하도록 리스트 맵핑===>이하수정
        const gamestaticmonthexlist = res.data.gamestaticmonthexlist.map(
          (result, idx) => {
            return {
              ranking: result.ranking,
              num: result.num,
              fo_name: result.fo_name,
            };
          }
        );

        const csv_headers = [
          { label: "랭킹", key: "ranking" },
          { label: "이동폴더수", key: "num" },
          { label: "폴더명", key: "fo_name" },
        ];

        return { csv_data: gamestaticmonthexlist, csv_header: csv_headers };
      })
      .catch((err) => console.log(err))
  );
};
//+++++++++++++++++++++++++++++++++++++++++++++++엑셀다운리스트 정리 끝+++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 리스트에서  다중체크후  삭제처리
export const deletechecks = async (list) => {
  //LIST(인수명selectedRowKeys와 틀려도 상관없음) 는 NOTICESERVICE> deletechecks(selectedRowKeys)로 넘겨온 체크된 NO_NO리스트들임
  if (list.length > 0) {
    if (window.confirm("선택한 데이타를 삭제 하시겠습니까? .")) {
      return request("DELETE", "/static/deletechecks/", JSON.stringify(list))
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));
          if (res.data.message === "success") {
            //const history = useHistory();
            alert("삭제되었습니다");
            const navigate = useNavigate();
            navigate("/foldnema/detail"); // 삭제후 이동안됨
            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
          }
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//리스트에서  다중체크후  삭제처리
export const deletechecks1 = async (list) => {
  //LIST(인수명selectedRowKeys와 틀려도 상관없음) 는 NOTICESERVICE> deletechecks(selectedRowKeys)로 넘겨온 체크된 NO_NO리스트들임
  if (list.length > 0) {
    if (window.confirm("선택한 데이타를 삭제 하시겠습니까? .")) {
      return request("DELETE", "/static/deletechecks1/", JSON.stringify(list))
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));
          if (res.data.message === "success") {
            //const history = useHistory();
            alert("삭제되었습니다");
            const navigate = useNavigate();
            navigate("/foldnema/detail"); // 삭제후 이동안됨
            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
          }
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//리스트에서  다중체크후  삭제처리
export const deletechecks2 = async (list) => {
  //LIST(인수명selectedRowKeys와 틀려도 상관없음) 는 NOTICESERVICE> deletechecks(selectedRowKeys)로 넘겨온 체크된 NO_NO리스트들임
  if (list.length > 0) {
    if (window.confirm("선택한 데이타를 삭제 하시겠습니까? .")) {
      return request("DELETE", "/static/deletechecks2/", JSON.stringify(list))
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));
          if (res.data.message === "success") {
            //const history = useHistory();
            alert("삭제되었습니다");
            const navigate = useNavigate();
            navigate("/foldnema/detail"); // 삭제후 이동안됨
            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
          }
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

export const deletechecks3 = async (list) => {
  //LIST(인수명selectedRowKeys와 틀려도 상관없음) 는 NOTICESERVICE> deletechecks(selectedRowKeys)로 넘겨온 체크된 NO_NO리스트들임
  if (list.length > 0) {
    if (window.confirm("선택한 데이타를 삭제 하시겠습니까? .")) {
      return request("DELETE", "/static/deletechecks3/", JSON.stringify(list))
        .then((res) => {
          console.log("삭제결과>" + JSON.stringify(res.data.message));
          if (res.data.message === "success") {
            //const history = useHistory();
            alert("삭제되었습니다");
            const navigate = useNavigate();
            navigate("/foldnema/detail"); // 삭제후 이동안됨
            //history.push('/notice');// 삭제후 이동안됨
            //navigate("/notice");// 삭제후 이동안됨
          }
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  } else {
    message.error("선택한 항목이 없습니다.");
    return false;
  }
};

//유저 상세정보 가져오기
export const getTerms = async () => {
  return request("POST", `/user/terms`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const termsInfo = res.data;
    console.log("userinfo1", termsInfo);
    return termsInfo;
  });
};

//유저 등록
export const updateTerms = async (params) => {
  return request("POST", "/user/updateTerms/", JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

//유저 로그 리스트 가져오기
export const getUserLogList = async (tableParams) => {
  return request("POST", "/user/logList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          key: idx,
          us_no: result.us_no,
          m_id: result.m_id,
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: dayjs(result.reg_date).format("YYYY-MM-DD"),
        };
      });
      return { list: logList, total: res.data.logTotal.total };
    })
    .catch((err) => console.log(err));
};

//엑셀다운로드 버튼클릭시 유저 리스트 가져오기
export const getLogFullList = async (tableParams) => {
  return request("POST", "/user/logFullList", JSON.stringify(tableParams))
    .then((res) => {
      //리스트에 사용 가능하도록 리스트 맵핑
      const logList = res.data.logList.map((result, idx) => {
        return {
          us_no: result.us_no,
          m_id: result.m_id,
          login_time: datetimeFormat(result.login_time),
          logout_time: datetimeFormat(result.logout_time),
          reg_date: datetimeFormat(result.reg_date),
        };
      });

      const csv_headers = [
        { label: "로그 고유번호", key: "us_no" },
        { label: "아이디", key: "m_id" },
        { label: "로그인 일시", key: "login_time" },
        { label: "로그아웃 일시", key: "logout_time" },
        { label: "등록일", key: "reg_date" },
      ];

      return { csv_data: logList, csv_header: csv_headers };
    })
    .catch((err) => console.log(err));
};

//유저 상세정보 가져오기
export const getSimpleStats = async () => {
  return request("POST", `/user/simpleStats`).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    console.log("유저상세정보result", result);
    return result;
  });
};

//유저 아이디 리스트 가져오기
export const getUserIdList = async (param) => {
  const target = param?.target ? param.target : null;
  const value = param?.value ? param.value : null;
  return request(
    "POST",
    `/simpleQuery`,
    JSON.stringify({
      col: ["m_id"],
      table: "mem_info",
      target: target,
      value: value,
    })
  ).then((res) => {
    //상세 정보에서 사용 가능하도록 처리
    const result = res.data;
    //console.log("result", result);
    return result;
  });
};
