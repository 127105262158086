import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Divider,
} from "antd";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import { getTerms, updateTerms } from "../../service/userService";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const { Content } = Layout;

const Terms = () => {
  const { user } = useSelector((state) => state.auth); ///==>무슨의미? 어디서 호출?
  const [form] = Form.useForm();
  const [originalTerm, setOriginalTerm] = useState();

  const onFinish = async (values) => {
    let updateInfo = [];
    let insertInfo = [];

    for (let i = 0; i < 3; i++) {
      //기존 te_no가 있다면 update
      if (originalTerm.te_no[i] !== undefined) {
        //제목, 내용중 바뀐값만 수정
        if (originalTerm.te_title[i] !== values.te_title[i]) {
          //console.log("update title");
          updateInfo[values.te_no[i]] = {
            ...updateInfo[values.te_no[i]],
            te_title: values.te_title[i],
          };
        }
        if (originalTerm.te_detail[i] !== values.te_detail[i]) {
          //console.log("update te_detail");
          updateInfo[values.te_no[i]] = {
            ...updateInfo[values.te_no[i]],
            te_detail: values.te_detail[i],
          };
        }
      } else if (
        originalTerm.te_no[i] === undefined &&
        (values.te_title[i] !== undefined || values.te_detail[i] !== undefined)
      ) {
        //기존 te_no가 없는데 입력된 title이 있다면 insert
        if (
          values.te_title[i] === undefined ||
          values.te_detail[i] === undefined
        ) {
          //입력된 값중 빈 필드가 있다면
          //console.log("insert fail");
          message.error("새로운 약관에 빈 필드를 입력해주세요.", 2);
          return false;
        }
        insertInfo.push({
          te_title: values.te_title[i],
          te_detail: values.te_detail[i],
        });
      }
    }
    //console.log("termInfos", JSON.stringify({ updateInfo, insertInfo }));
    if (updateInfo.length <= 0 && insertInfo.length <= 0) {
      message.error("변경된 항목이 없습니다.", 2);
    } else {
      const result = await updateTerms({ updateInfo, insertInfo });
      message.success("등록 및 수정이 완료되었습니다.", 2);
      //console.log("result", result);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //DB에서 약관 데이터 가져오기
  const fetchData = async () => {
    const termsInfo = await getTerms();

    //페이지가 열렸을때 값을 저장
    setOriginalTerm({
      te_no: [termsInfo[0]?.te_no, termsInfo[1]?.te_no, termsInfo[2]?.te_no],
      te_title: [
        termsInfo[0]?.te_title,
        termsInfo[1]?.te_title,
        termsInfo[2]?.te_title,
      ],
      te_detail: [
        termsInfo[0]?.te_detail,
        termsInfo[1]?.te_detail,
        termsInfo[2]?.te_detail,
      ],
    });
  };

  //초기값
  useEffect(() => {
    fetchData();
  }, []);

  //받아온 정보로 변경되었다면 form에 삽입
  useEffect(() => {
    form.setFieldsValue(originalTerm);
    //초기값 설정
  }, [originalTerm]);

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/user" style={{ color: "#333333" }}>
            회원관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            약관관리
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ textAlign: "center" }}
        >
          <Form.Item hidden name={["te_no", 0]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="약관제목"
            name={["te_title", 0]}
            rules={[{ max: 20 }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item label="약관내용" name={["te_detail", 0]}>
            <Input.TextArea allowClear rows={7} />
          </Form.Item>
          <Divider />
          <Form.Item hidden name={["te_no", 1]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="약관제목"
            name={["te_title", 1]}
            rules={[{ max: 20 }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item label="약관내용" name={["te_detail", 1]}>
            <Input.TextArea allowClear rows={7} />
          </Form.Item>
          <Divider />
          <Form.Item hidden name={["te_no", 2]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="약관제목"
            name={["te_title", 2]}
            rules={[{ max: 20 }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item label="약관내용" name={["te_detail", 2]}>
            <Input.TextArea allowClear rows={7} />
          </Form.Item>
          <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
            {user.m_grade === 1 ? (
              <Button type="primary" htmlType="submit">
                등록 및 수정
              </Button>
            ) : (
              ""
            )}
          </Form.Item>
        </Form>
      </div>
    </Content>
  );
};

export default Terms;
