import React, { useEffect, useState } from "react";
//import { Layout, Breadcrumb, Col, Button, Row, Space, Image } from "antd";
import {
  Layout,
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Col,
  Select,
  Row,
  Radio,
  message,
  InputNumber,
  Space,
  Image,
} from "antd";
import { Link, useParams } from "react-router-dom";
import {
  getqnadetail,
  updateqna,
  registqnaans,
} from "../../service/qnaService"; //===>수정
import { useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import "../../css//Totalstyle.css";
import { SearchOutlined } from "@ant-design/icons";
import { request } from "../../utils/axios"; //  nodeserver로 데이타를 요청하는 함수가있는 페이지  삭제후 이동이 안되 여기서 직접 axios로 node를 호출
import { printornot } from "../../config/staticInfo";

import dayjs from "dayjs";

const { Content } = Layout;

const { TextArea } = Input;
const validateMessages = {
  required: "'${label}'을(를) 입력해주세요.",
  pattern: {
    mismatch: "'${label}' 형식에 맞게 작성해주세요.",
  },
  types: {
    email: "'${label}' 형식에 맞게 작성해주세요.",
  },

  // ...
};

const datetimeFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : "";
};

const Qnadetail = () => {
  const navigate = useNavigate();
  const { qa_no } = useParams(); //===>수정
  //const { qas_no } = useParams();//===>수정
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [backUrl, setbackUrl] = useState("/ipblock"); //===> 수정 목록버튼클릭후 이동으로 변경했으나
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [originalInfo, setOriginalInfo] = useState();
  const [isvisible, setvisible] = useState(false);
  const [isvisible1, setvisible1] = useState(false);
  //console.log("가져온data들"+ JSON.stringify(data[0].qnaInfodata.m_id));
  //const qano=data[0].qnaInfodata.qa_no;
  //const {m_id,qa_subject,qa_detail}=data;
  const users = JSON.parse(sessionStorage.getItem("user"));
  console.log(
    "resqnauseParams",
    JSON.stringify(() => useParams, null, 2)
  ); //  {name:, value형태로 출력됨}
  console.log(
    "resqna111",
    JSON.stringify(() => (data ? data[2].value : undefined), null, 2)
  ); // 이렇게 호출하면 흰배경만 출력됨
  console.log("현재로그인회원", JSON.stringify(users, null, 2)); //

  console.log("backUrl" + JSON.stringify(backUrl));
  //console.log("ip_no"+ JSON.stringify(useParams()));//값을 못가져오는경우 main>route지정경로에서 파라미터 네임이 틀린경우이다
  const fetchData = async () => {
    //===>수정
    const qnainfo = await getqnadetail(qa_no); //==>수정
    console.log("qnainfo", qnainfo); //==>수정
    setData(qnainfo[0].renderInfo); //==>수정
    setData1(qnainfo[0].qnaInfoans); //==>수정
    //console.log("반환받은데이타0:",JSON.stringify(qnainfo,null,2));//
    //console.log("반환받은데이타00:",JSON.stringify(qnainfo[0].qnaInfodata.qa_no,null,2));//   한개씩출력
    //console.log("반환받은데이타1:",JSON.stringify(qnainfo[0].qnaInfodata,null,2));//   질문관련 테이블리턴값
    //console.log("반환받은데이타2:",JSON.stringify(qnainfo[0].qnaInfoans,null,2));// 질문답변관련 테이블리턴값

    setOriginalInfo({
      //=====>수정용으로 작성된 답변 내용이 있을때 form안에 미리 출력해주기위해 코딩
      qas_no: qnainfo[0].qnaInfoans.qas_no,
      qas_name: qnainfo[0].qnaInfoans.qas_name,
      qa_no: qnainfo[0].qnaInfoans.qa_no,
      //m_id:qnainfo[0].qnaInfoans.m_id,
      qas_detail: qnainfo[0].qnaInfoans.qas_detail,
      reg_date: qnainfo[0].qnaInfoans.reg_date
        ? dayjs(qnainfo[0].qnaInfoans.reg_date)
        : "",
    });
  };

  useEffect(() => {
    fetchData();
    //selectdata();

    //상세 페이지를 회원목록, 탈퇴목록에서 사용하기위한 목록 버튼 URL
    setbackUrl(sessionStorage.getItem("backUrl"));
  }, []);

  const onRemove = () => {
    if (window.confirm("삭제 하시겠습니까? 삭제하시면 복구가 불가능합니다.")) {
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        //삭제처리
        alert("삭제되었습니다.");
      }
    }
  };

  //+++++++++++++++++++++++++++++++++1:1문의에대한 답변이므로 삭제는 불가: 삭제 하려면 db에서 삭제해야함
  const deleteqna = async (qa_no) => {
    //==>수정 이하////  nodeserver로 삭제요청함/삭제후 이동이 안되 여기서 직접 axios로 node를 호출
    console.log("삭제번호넘긴값>" + JSON.stringify(qa_no));

    if (qa_no) {
      if (
        window.confirm(
          qa_no + "를 삭제 하시겠습니까? 삭제하시면 복구가불가능합니다"
        )
      ) {
        return request("DELETE", `/qna/delete/${qa_no}`)
          .then((res) => {
            console.log("삭제결과>" + JSON.stringify(res.data.message));

            //res?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${ip_no}`" />// 삭제후 이동안됨

            if (res.data.message === "success") {
              //const history = useHistory();
              alert("삭제되었습니다");
              navigate("/qna"); //

              //history.push('/notice');// 삭제후 이동안됨
              //navigate("/notice");// 삭제후 이동안됨
              //res.data.message==='success'?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${ip_no}`" />
              //<Redirect to={{pathname: '/notice', }}/>
            }
            //<Navigate to="http://localhost:3000/notice/" /> 삭제후이동
            return res.data;
          })
          .catch((err) => console.log(err));
      }
    } else {
      message.error("선택한 항목이 없습니다.");
      return false;
    }
  };

  //+++++++++++++++0. 위 faq_no 를 인수로 던져서 일단 가져와 출력한다  end===>수정 ++++++++++++++++++++

  /*/받아온 정보로 use_state=2  탈퇴 로 변경되었다면 form에 삽입*/
  useEffect(() => {
    form.setFieldsValue(originalInfo); //===> 이부분이 있어야  각필드의 input에 값을  초기로딩시 출력할수 있음==> 초기로딩시 보여지지 않는 문제 해결
    //초기에 페이지 로드시 데이타를 1차로 업데이트 상태변경시 form에 데이타를 출력하기 위한 구문임
  }, [originalInfo]);

  //+++++++++++++++++++++++++++++++++++++++++++++++++++수정용onFinish, onFinishFailed++++++++++++++++++++++++++++++++++++++++
  //++++++++++1.//onFinish  하면 form 의 name값이 values로 넘어간다/originalInfo=> 초기로딩시 select한값 이고 values는 입력박스의 값들의 배열변수 이다 ===>수정+++++++++
  const onFinish = async (values) => {
    const formValues = {
      //form에서 넘겨받은  values를 나열후 뒤에 ra_no는 앞부분배열순서에 reg_date필드에 다시 날짜포멧을 변경후 뒷순서배열에 리턴한다 ( 수정된것이든 안되든 무조건 반환)
      ...values,
      reg_date: dayjs(values.reg_date).format("YYYY-MM-DD"),
      //pic_route: values.pic_route.file.url,
    };

    console.log("form에서 넘겨받은값" + JSON.stringify(formValues)); //   console.log('form에서 넘겨받은값'+JSON.stringify(values.no_subject))
    console.log("초기 select한값" + JSON.stringify(originalInfo)); //   console.log('form에서 넘겨받은값'+JSON.stringify(values.no_subject))

    const qas_no = data1.qas_no; //data1?data1.qas_no:undefined;
    const result = await updateqna(formValues, qas_no); //==>수정
    console.log("result message1" + JSON.stringify(result));
    //console.log('node에서 받은값'+ JSON.stringify(result.accepetdata));
    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("수정이 완료되었습니다.");
        navigate("/qna/detail/" + qa_no);
        break;
      case "exist nname":
        message.error("이미 존재하는 닉네임입니다.");
        break;
    }
  }; //+++++++++++++++++++++++onfinish   end+++++++++++++++++++++++++++

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //+++++++++++++++++++++++++++++++++++++++++++++++++++답변등록용onFinish1, onFinishFailed1++++++++++++++++++++++++++++++++++++++++
  //++++++++++1.//onFinish  하면 form 의 name값이 values로 넘어간다/originalInfo=> 초기로딩시 select한값 이고 values는 입력박스의 값들의 배열변수 이다 ===>수정+++++++++
  const onFinish1 = async (values) => {
    const formValues = {
      //form에서 넘겨받은  values를 나열후 뒤에  reg_date필드에 다시 날짜포멧을 변경후 뒷순서배열에 리턴한다 ( 수정된것이든 안되든 무조건 반환)
      ...values,
      reg_date: dayjs().format("YYYY-MM-DD"),

      //pic_route: values.pic_route.file.url,
    };
    //console.log('originalInfo'+JSON.stringify(originalInfo))//
    console.log("form에서 넘겨받은값" + JSON.stringify(formValues)); //   console.log('form에서 넘겨받은값'+JSON.stringify(values.no_subject))

    const result1 = await registqnaans(formValues, qa_no); //==>수정  최종넘기는값 values(qas_name,qas_detail,reg_date,qa_no)
    console.log("result message1" + JSON.stringify(result1));
    //console.log('node에서 받은값'+ JSON.stringify(result.accepetdata));
    switch (result1.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("답변등록이 완료되었습니다.");
        navigate("/qna");
        break;
      case "exist nname":
        message.error("이미 존재하는 닉네임입니다.");
        break;
    }
  }; //+++++++++++++++++++++++onfinish   end+++++++++++++++++++++++++++

  const onFinishFailed1 = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/service/random" style={{ color: "#333333" }}>
            서비스관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/service/qna" style={{ color: "#333333" }}>
            1:1 문의 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item reloadDocument style={{ color: "#333333" }}>
          <Link reloadDocument style={{ color: "#333333" }}>
            1:1 문의상세
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Row style={{ border: "0px solid #333", marginBottom: 20 }}>
          {data?.map((result, idx) => {
            //data에 저장된  결과값이 있으면 result에 배열로 담아 출력, 배열의 idx(임의 순서를매긴 번호== for문과 동일)
            return (
              <>
                <Col span={24} style={{ border: "0px solid #333" }}>
                  {/*name=form에서 지정한 name*/}
                  <div
                    style={{
                      position: "relative",
                      float: "left",
                      width: "20%",
                      border: "0px solid #333",
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      backgroundColor: "#fff",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    {result.name}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      float: "left",
                      width: "80%",
                      border: "0px solid #333",
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      backgroundColor: "#fff",
                    }}
                  >
                    {result.value}
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space wrap>
              {data1 ? (
                <Button
                  className="editButton"
                  size="middle"
                  onClick={() => {
                    setvisible(!isvisible);
                  }}
                >
                  {!isvisible ? "답변보기" : "답변닫기"}{" "}
                </Button>
              ) : (
                <Button
                  className="editButton"
                  size="middle"
                  onClick={() => {
                    setvisible1(!isvisible1);
                  }}
                >
                  {!isvisible1 ? "답변작성" : "답변작성닫기"}
                </Button>
              )}
              {/*<Button danger size="middle" onClick={() => deleteqna(qa_no)}> 삭제</Button>*/}
              <Button
                size="middle"
                type="default"
                style={{ backgroundColor: "", color: "" }}
                onClick={() => navigate("/service/qna", qa_no)}
              >
                목록
              </Button>
            </Space>
          </Col>
        </Row>

        {isvisible ? ( //
          <>
            <Row
              gutter={24}
              style={{ border: "0px solid #333", marginBottom: 20 }}
            >
              <Col
                span={5}
                style={{
                  textAlign: "right",
                  border: "0px solid #333",
                  fontSize: 14,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontWeight: "bold",
                }}
              >
                일련번호
              </Col>
              <Col
                span={19}
                style={{
                  textAlign: "left",
                  border: "0px solid #333",
                  fontSize: 15,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {data1 ? data1.qas_no : undefined}
              </Col>
              <Col
                span={5}
                style={{
                  textAlign: "right",
                  border: "0px solid #333",
                  fontSize: 14,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontWeight: "bold",
                }}
              >
                질문고유번호
              </Col>
              <Col
                span={19}
                style={{
                  textAlign: "left",
                  border: "0px solid #333",
                  fontSize: 15,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {data1 ? data1.qa_no : undefined}
              </Col>
              {/*<Col span={5} style={{ textAlign: "right" ,border:'0px solid #333',fontSize:15,paddingTop:10,paddingBottom:10,fontWeight:'bold'}}>질문작성</Col>
          <Col span={19} style={{ textAlign: "left",border:'0px solid #333',fontSize:15,paddingTop:10,paddingBottom:10 }}>{data1?data1.m_id:undefined}</Col>*/}
              <Col
                span={5}
                style={{
                  textAlign: "right",
                  border: "0px solid #333",
                  fontSize: 15,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontWeight: "bold",
                }}
              >
                답변등록일
              </Col>
              <Col
                span={19}
                style={{
                  textAlign: "left",
                  border: "0px solid #333",
                  fontSize: 15,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {datetimeFormat(data1 ? data1.reg_date : undefined)}
              </Col>
            </Row>

            <Form
              name="basic"
              form={form}
              labelCol={{ span: 4 }} //라밸폭비율
              wrapperCol={{ span: 20 }} //input box 폭 비율
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              validateMessages={validateMessages} // 이부분이있어야 아래 요소들의 rules를 자동으로 검사함
              style={{ textAlign: "left", border: "0px solid #333" }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="답변작성자"
                    name="qas_name"
                    hasFeedback
                    rules={[{ required: true }, { min: 5, max: 20 }]}
                    style={{ textAlign: "left", border: "0px solid #333" }}
                  >
                    <Input allowClear maxLength={80} autoFocus />
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "left", border: "0px solid #333" }}
                    label="답변내용"
                    labelAlign="right"
                    name="qas_detail"
                    hasFeedback
                    rules={[{ required: true }, { min: 5, max: 500 }]}
                  >
                    <TextArea
                      placeholder="Controlled autosize"
                      maxLength={500}
                      autoSize={{ minRows: 10, maxRows: 15 }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                    {/*<Button type='primary' htmlType="submit" style={{ width:300,height:100,textAlign:'center',border:'1px solid #fff',borderRadius:15}}>
           htmlType="submit"클릭하면-> onFinish(values)함수를 호출해서  Form.Items 의 name값들이 values인수에 전달되어 db에 저장된다
           */}

                    <Space wrap>
                      <Button type="primary" htmlType="submit">
                        수정
                      </Button>
                      <Button onClick={() => setvisible(false)}>
                        답변닫기
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
        ) : undefined}

        {isvisible1 ? ( // 답변작성버튼클릭시 출력해서 답변을 작성한다
          <>
            <Form
              name="basic1"
              form={form1}
              labelCol={{ span: 4 }} //라밸폭비율
              wrapperCol={{ span: 20 }} //input box 폭 비율
              onFinish={onFinish1}
              onFinishFailed={onFinishFailed1}
              autoComplete="off"
              validateMessages={validateMessages} // 이부분이있어야 아래 요소들의 rules를 자동으로 검사함
              style={{ textAlign: "left", border: "0px solid #333" }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="답변작성자"
                    name="qas_name"
                    //hasFeedback
                    //rules={[{ required: true }, { min: 2, max: 20 }]}
                    style={{
                      textAlign: "left",
                      border: "0px solid #333",
                      marginTop: 20,
                    }}
                    //validateStatus={dpCheck.m_id}
                  >
                    <Input
                      allowClear
                      maxLength={80}
                      placeholder="답변작성자 아이디를 입력하세요"
                      //placeholder={users?users:'admin'}
                      //defaultValue={users ? users : 'admin'} // r기본으로 값을 못넘김
                      //defaultValue='admin'   // r기본으로 값을 못넘김
                      //onChange={() => initCheck("m_id")}
                      //enable={false}
                      //readOnly={true}
                      //autoFocus   // r기본으로 값을 못넘겨 자동으로 저장하려햇으나 실패
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "left", border: "0px solid #333" }}
                    label="답변내용"
                    labelAlign="right"
                    name="qas_detail"
                    hasFeedback
                    rules={[{ required: true }, { min: 5, max: 500 }]}
                    //validateStatus={dpCheck.faq_detail}
                  >
                    <TextArea
                      placeholder="답변내용"
                      maxLength={500}
                      autoSize={{ minRows: 10, maxRows: 15 }}
                    />
                  </Form.Item>
                  {/* <Form.Item  label="출력여부"  name="view_yn" rules={[{ required: true }]}  >
                <Radio.Group><Radio value={1}>{printornot[1]}</Radio><Radio value={2}>{printornot[2]}</Radio></Radio.Group>
        </Form.Item>*/}
                </Col>
              </Row>

              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                    {/*<Button type='primary' htmlType="submit" style={{ width:300,height:100,textAlign:'center',border:'1px solid #fff',borderRadius:15}}>
           htmlType="submit"클릭하면-> onFinish(values)함수를 호출해서  Form.Items 의 name값들이 values인수에 전달되어 db에 저장된다
           */}

                    <Space wrap>
                      <Button type="primary" htmlType="submit">
                        답변등록
                      </Button>
                      <Button onClick={() => setvisible1(false)}>취소</Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
        ) : undefined}
      </div>
    </Content>
  );
};

export default Qnadetail;
