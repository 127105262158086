import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { Card, Checkbox, Typography } from "antd";
import { updateCard } from "../service/cardService";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const gridStyle: React.CSSProperties = {
  padding: "5px",
  border: "none",
  boxShadow: "none",
};
const { Paragraph, Text } = Typography;

const ReviewCard = ({
  cardInfo,
  onSelect,
  selectedRow,
  onSetData,
  flag = "default",
}) => {
  const {
    m_id,
    fo_name,
    ca_keyword,
    ca_liked,
    x_number,
    ca_no,
    del_yn,
    bad_yn,
    ox,
  } = cardInfo;
  const [checkValue, setCheckValue] = useState(false);
  const [cardStyle, setCardStyle] = useState({});
  const [flagText, setFlagText] = useState();
  const [likeStar, setLikeStar] = useState(<StarOutlined />);

  useEffect(() => {
    if (del_yn === 2) {
      setCardStyle({
        borderStyle: "dashed",
        borderColor: "red",
        opacity: 0.5,
      });
      setFlagText(
        <div
          style={{
            position: "absolute",
            top: 30,
            width: "100%",
            textAlign: "center",
            color: "red",
            fontSize: 20,
            fontWeight: "bold",
            opacity: 0.5,
            pointerEvents: "none",
          }}
        >
          삭제됨
        </div>
      );
    } else if (bad_yn === 1) {
      setCardStyle({
        borderColor: "blue",
      });
      setFlagText(
        <div
          style={{
            position: "absolute",
            top: 30,
            width: "100%",
            textAlign: "center",
            color: "blue",
            fontSize: 20,
            fontWeight: "bold",
            opacity: 0.5,
            pointerEvents: "none",
          }}
        >
          신고됨
        </div>
      );
    } else {
      setCardStyle({});
      setFlagText();
    }
    //console.log(ca_liked);
    setLikeStar(
      ca_liked === 1 ? (
        <StarFilled style={{ cursor: "pointer" }} />
      ) : (
        <StarOutlined style={{ cursor: "pointer" }} />
      )
    );
  }, [cardInfo]);

  //선택된 값이 바뀌면 체크 변경
  useEffect(() => {
    if (selectedRow?.find((number) => number === ca_no) > 0) {
      setCheckValue(true);
    } else {
      setCheckValue(false);
    }
  }, [selectedRow]);

  const onCheckChange = (e) => {
    //참이면 카드no 추가 아니면 뺌
    if (e.target.checked) {
      onSelect((selectedRowKeys) => [...selectedRowKeys, ca_no]);
    } else {
      onSelect((selectedRowKeys) =>
        selectedRowKeys.filter((number) => number !== ca_no)
      );
    }
  };

  const onChangeLike = () => {
    updateCard({ ca_liked: ca_liked === 1 ? 2 : 1 }, ca_no).then((result) => {
      //console.log(result);
      //즐겨찾기 변경에 성공했다면 노출되는 표시 변경
      if (result.message === "success") {
        onSetData((data) =>
          data.map((row, idx) => {
            if (row.ca_no === ca_no) {
              return { ...row, ca_liked: ca_liked === 1 ? 2 : 1 };
            } else {
              return row;
            }
          })
        );
      }
    });
    //setLikeStare(ca_liked === 1 ? <StarOutlined /> : <StarFilled />);
  };

  return (
    <>
      <Card style={{ ...cardStyle, width: 160, padding: "5px 0" }}>
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,
            paddingLeft: 10,
            width: "70%",
            textAlign: "left",
            marginTop: 5,
          }}
        >
          {selectedRow ? (
            <Checkbox onChange={onCheckChange} checked={checkValue}>
              {fo_name}
            </Checkbox>
          ) : (
            <div>{fo_name}</div>
          )}
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,
            width: "30%",
            paddingRight: 15,
            textAlign: "right",
            fontSize: 20,
          }}
        >
          <div onClick={onChangeLike}>{likeStar}</div>
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,
            width: "100%",
            margin: "5px 0",
            textAlign: "center",
          }}
        >
          <Paragraph ellipsis={{ rows: 2 }}>{ca_keyword}</Paragraph>
        </Card.Grid>
        {flag === "wrong" || flag === "game" ? (
          <Card.Grid
            hoverable={false}
            style={{
              ...gridStyle,
              width: "100%",
              margin: "0",
              padding: "0",
              textAlign: "center",
            }}
          >
            <span style={{ color: "red", fontSize: "12px" }}>
              {flag === "wrong" ? `오답 ${x_number}회` : ""}
              {flag === "game" ? (ox === 1 ? "정답" : "오답") : ""}
            </span>
          </Card.Grid>
        ) : null}
        <Card.Grid
          hoverable={false}
          style={{ ...gridStyle, width: "100%", textAlign: "center" }}
        >
          by {m_id}
        </Card.Grid>
        {flagText}
      </Card>
    </>
  );
};

export default ReviewCard;
