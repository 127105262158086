import React from "react";
import { Col, Row, Button } from "antd";
import { LogoutOutlined,HomeOutlined  } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { authUserLogout } from "../feature/authSlice";

import "../css/Main.css";
import { Link,useNavigate,useParams,useLocation } from "react-router-dom";
import dayjs from "dayjs";

//const todaydate=dayjs().format("YYYY-MM-DD hh:mm:ss");
const todaydate=dayjs().format("YYYY-MM-DD");

const AppHeader = () => {
  const { user } = useSelector((state) => state.auth);///==>무슨의미? 어디서 호출?
  //const { params } = useParams();// url을 받아 m_grade에따라  버튼을 제어하려했으나 가져오지못함
  //const { search } = useLocation();// url을 받아 m_grade에따라  버튼을 제어하려했으나 가져오지못함
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const current = decodeURI(window.location.href);
//const search = current.split("?")[1];
//const params = new URLSearchParams(search);
//const keywords = params.get('keywords');
console.log('current:'+  JSON.stringify(current));
  //console.log('params:'+  JSON.stringify(params));
  //console.log('search:'+  JSON.stringify(search));
  console.log('user'+ JSON.stringify(user));
  console.log("loginsession" + sessionStorage.getItem("user") );
  

  return (
    <Row>
      <Col span={3} style={{fontSize:'14pt',fontWeight:'bold', color:'#000',border:'0px solid #999'}}>
              <div className="headerLogo"><Link to={"/"}>THENEXT 더넥스트</Link></div>
      </Col>
      <Col span={8} offset={13} style={{fontSize:'11pt',fontWeight:'normal', color:'#000',border:'0px solid #999',textAlign:'end',paddingRight:25}}>
        Today: {todaydate}, {user.m_nname+'/grade:'+user.m_grade}님 안녕하세요. {/* undefined이면 local접속임, 좌측은 서버에서 사용시코드이고 local에서사용시{user.user.m_nname+'/grade:'+user.user.m_grade} 한다*/}
        <Button  type="default"  icon={<LogoutOutlined />} style={{ marginLeft: "10px" }} 
        onClick={() => {
          if (window.confirm("로그아웃 하시겠습니까? .")) {        
          dispatch(authUserLogout())
          }
          }
          } ></Button>
        <Button  type="default"  icon={<HomeOutlined  />}  style={{ marginLeft: "10px" }} onClick={() => navigate('/')}></Button>
      </Col>
    </Row>
  );
};

export default AppHeader;
