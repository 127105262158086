import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Col, Button, Row, Space, Image } from "antd";
import { useParams } from "react-router-dom";
import { getnoticedetail,} from "../../service/noticeService";//===>수정
import { useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import "../../css//Totalstyle.css"; 
import { SearchOutlined } from "@ant-design/icons";
import { request } from "../../utils/axios";//  nodeserver로 데이타를 요청하는 함수가있는 페이지
import { message } from "antd";

const { Content } = Layout;

const Surveyansdetail = () => {
  const navigate = useNavigate();
  const { no_no } = useParams();//===>수정
  const [data, setData] = useState();
  const [backUrl, setbackUrl] = useState("/notice");//===> 수정 목록버튼클릭후 이동으로 변경했으나

  console.log("backUrl"+ JSON.stringify(backUrl));
  //console.log("no_no"+ JSON.stringify(useParams()));//값을 못가져오는경우 main>route지정경로에서 파라미터 네임이 틀린경우이다
  const fetchData = async () => {//===>수정
    const noticeinfo = await getnoticedetail(no_no);//==>수정
    console.log("noticeinfo", noticeinfo);//==>수정
    setData(noticeinfo);//==>수정
  };

  useEffect(() => {    
    fetchData();

    //상세 페이지를 회원목록, 탈퇴목록에서 사용하기위한 목록 버튼 URL
    setbackUrl(sessionStorage.getItem("backUrl"));
  }, []);

  const onRemove = () => {
    if (window.confirm("삭제 하시겠습니까? 삭제하시면 복구가 불가능합니다.")) {
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        //삭제처리
        alert("삭제되었습니다.");
      }
    }
  };

  const deletenotice = async (no_no) => {
    console.log("삭제번호넘긴값>" + JSON.stringify(no_no));
    
    if(no_no){
      
      if (window.confirm(no_no + "를 삭제 하시겠습니까? 삭제하시면 복구가불가능합니다")) {
      return request("DELETE", `/notice/delete/${no_no}`)
    
      .then((res) => {    
        console.log("삭제결과>" + JSON.stringify(res.data.message));
        
        //res?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${no_no}`" />// 삭제후 이동안됨
        
        if(res.data.message==='success'){
          //const history = useHistory();
          alert('삭제되었습니다');
          navigate("/notice");// 삭제후 이동안됨
  
          //history.push('/notice');// 삭제후 이동안됨
          //navigate("/notice");// 삭제후 이동안됨
          //res.data.message==='success'?<Navigate to="/notice" />:<Navigate to="/notice/detail/`${no_no}`" />
          //<Redirect to={{pathname: '/notice', }}/>
        }
        //<Navigate to="http://localhost:3000/notice/" /> 삭제후이동
        return res.data;
       })
      .catch((err) => console.log(err));
      }
  
    } else {
      message.error("선택한 항목이 없습니다.");
      return false;
    }
  
  };

  return (
    <Content style={{ margin: "0 16px",border:'1px solid #f8f8f8',backgroundColor:'#fff',marginTop:15 ,boxShadow:'1px 1.5px 5px 1px #ebebeb',borderRadius:15,}}>
      <Breadcrumb style={{ padding: "20px 0px 0px 30px " ,borderBottom :'1px solid #ebebeb',fontWeight:'bold',color:'#333', fontSize:14,height:55,}}>
        <Breadcrumb.Item>설문조사참여자</Breadcrumb.Item>
        <Breadcrumb.Item>설문조사참여자 상세</Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Row style={{border:'0px solid #333',marginBottom:20 }}>{/*gutter={[컬럼왼쪽 마진, 컬럼 상하마진]} */}          
          {data?.map((result, idx) => {//data에 저장된  결과값이 있으면 result에 배열로 담아 출력, 배열의 idx(임의 순서를매긴 번호== for문과 동일)
            return (
              <>
                <Col span={24} style={{border:'0px solid #333', }}>{/*name=form에서 지정한 name*/}
                  <div  style={{position:'relative', float:'left', width:'20%',border:'0px solid #333',paddingTop:10,paddingBottom:10,paddingLeft:20, backgroundColor:'#fff',textAlign:'right',fontWeight:'bold'}}>
                    {result.name}</div>
                  <div style={{position:'relative', float:'left', width:'80%',border:'0px solid #333',paddingTop:10,paddingBottom:10,paddingLeft:20, backgroundColor:'#fff'}}>
                    {result.value}</div>
                </Col>

              </>
            );
          })}
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space wrap>
              <Button size="middle" className="editButton" htmlType='button' type='default' onClick={() => navigate(`/notice/modify/${no_no}`)}//===> 수정
                style={{}}
              >
                수정
              </Button>
              <Button danger size="middle"  htmlType='button'  type='default' onClick={() => deletenotice(no_no)}>
                삭제
              </Button>
              <Button size="middle"  type='default'  onClick={() => navigate('/notice',no_no)}>
              {/*<Button size="middle"  type='primary' icon={<SearchOutlined />} style={{ backgroundColor: "" ,color:''}} onClick={() => navigate('/notice',no_no)}>
                //===> 수정 <Button size="large" onClick={() => navigate(backUrl)}>이부분은 세션으로 backUrl을 저장*/}
                목록
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Surveyansdetail;
