import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Col, Button, Row, Space, Image } from "antd";
import { Link, useParams } from "react-router-dom";
import { getCardDetail, deleteCard } from "../../service/cardService";
import { useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import { useSelector } from "react-redux";

const { Content } = Layout;

const CardDetail = () => {
  const { user } = useSelector((state) => state.auth); ///==>무슨의미? 어디서 호출?
  const navigate = useNavigate();
  const { ca_no } = useParams();
  const [data, setData] = useState();
  const [backUrl, setbackUrl] = useState("/card");

  const fetchData = async () => {
    const cardInfo = await getCardDetail(ca_no);
    console.log("cardInfo", cardInfo);
    setData(cardInfo);
  };

  useEffect(() => {
    fetchData();

    //상세 페이지를 회원목록, 탈퇴목록에서 사용하기위한 목록 버튼 URL
    setbackUrl(sessionStorage.getItem("backUrl"));
  }, []);

  const onRemove = () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        //삭제처리
        alert("삭제되었습니다.");
      }
    }
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/card" style={{ color: "#333333" }}>
            복습카드
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            복습카드 상세
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Row gutter={[16, 16]} className="detailTalbe">
          {data?.map((result, idx) => {
            if (result.name === "키워드명" || result.name === "설명내용") {
              return (
                <>
                  <Col span={4} className="rightTitle">
                    {result.name}
                  </Col>
                  <Col span={20}>
                    <div
                      dangerouslySetInnerHTML={{ __html: result.value }}
                    ></div>
                  </Col>
                </>
              );
            }

            return (
              <>
                <Col span={4} className="rightTitle">
                  {result.name}
                </Col>
                <Col span={8}>{result.value}</Col>
              </>
            );
          })}
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space wrap>
              <Button
                className="editButton"
                size="large"
                onClick={() => navigate(`/card/modify/${ca_no}`)}
              >
                수정
              </Button>
              {user.m_grade === 1 ? (
                <Button danger size="large" onClick={() => deleteCard([ca_no])}>
                  삭제
                </Button>
              ) : (
                ""
              )}

              <Button size="large" onClick={() => navigate(backUrl)}>
                목록
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default CardDetail;
