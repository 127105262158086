import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Row,
  Breadcrumb,
  Form,
  DatePicker,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  Radio,
  Space,
} from "antd";
import { commonYN, userGradeInfo } from "../../config/staticInfo";
import { Link, useNavigate } from "react-router-dom";
import { getUserIdList } from "../../service/userService";
import {
  createFolderIndex,
  registFolder,
  selectfoldlist,
} from "../../service/folderService";
import dayjs from "dayjs";
import { GithubPicker, CirclePicker } from "react-color";

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

const validateMessages = {
  required: "'${label}'을(를) 입력해주세요.",
  pattern: {
    mismatch: "'${label}' 형식에 맞게 작성해주세요.",
  },
  types: {
    email: "'${label}' 형식에 맞게 작성해주세요.",
  },

  // ...
};

const FolderRegist = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [userIdData, setUserIdData] = useState();
  const [staticFolder, setStaticFolder] = useState([]);
  const [folderBackground, setFolderBackground] = useState();
  const [folderIndexData, setFolderIndexData] = useState();
  const [foldlist, setfoldlist] = useState();
  const [foldselect, setfoldselect] = useState();
  const [Selected, setSelected] = useState("");

  useEffect(() => {
    //기본 유저목록, 유저 전체 대상
    getUserIdList().then((result) => {
      setUserIdData(
        result.map((info, idx) => {
          return { label: info.m_id, value: info.m_id };
        })
      );
    });
    //기본값
    form.setFieldsValue({
      fo_cre_time: dayjs(),
      del_yn: 1,
      public_yn: 2,
    });
  }, []);

  //유저 구분 변경
  const onUserGradeChange = async (value) => {
    const result = await selectfoldlist(value);
    console.log(`selected는`, JSON.stringify(result, null, 2));
    setfoldlist(result.list);
    //해당 유저구분에 해당하는 기본 폴더 가져오기
    console.log(`저장폴더리스트`, JSON.stringify(result.list), null, 2);
  };

  //유저 구분 변경
  const onUserGradeChangeForUser = (value) => {
    //유저 목록을 해당 구분값의 유저로 변경
    getUserIdList({ target: `m_grade`, value: value }).then((result) => {
      setUserIdData(
        result.map((info, idx) => {
          return { label: info.m_id, value: info.m_id };
        })
      );
    });
  };
  //기본 폴더 변경, 변경시 input에 값 옯기기
  const onStaicFolderChange = (e) => {
    setSelected(e);
    form.setFieldsValue({ fo_name: e });
    //setfoldselect(e.value)
    //setfoldselect(foldlist.foldname)
    console.log(`selectedname ${e}` + JSON.stringify(Selected[0], null, 2)); //e는 가져오지만 setSelected, setfoldselectd업데이트 값은 가져오지 못함

    console.log(`form` + JSON.stringify(form));
    //폴더명에 해당 폴더 명 삽입
    //form.Input.name['fo_name'].value=e;
  };

  //유저 변경
  const onUserChange = (value) => {
    //console.log(`selected ${value}`);

    //유저를 선택하면 카드 고유번호를 생성, 선택한 폴더 null로
    form.setFieldsValue({
      fo_index: value ? createFolderIndex(value) : value,
    });
  };

  const onFinish = async (values) => {
    console.log("Finish:", values);
    const registParam = {
      ...values,
      fo_cre_time: dayjs(values.fo_cre_time).format("YYYY-MM-DD HH:mm:ss"),
    };
    const result = await registFolder(registParam);

    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("등록이 완료되었습니다.");
        navigate("/folder/detail/" + registParam.fo_index);
        break;
      case "exist index":
        message.error("이미 존재하는 고유번호입니다.");
        break;
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/card" style={{ color: "#333333" }}>
            복습폴더
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            복습폴더 등록
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="아이디">
                <Input.Group compact>
                  <Select
                    showSearch
                    allowClear
                    placeholder="회원구분별 검색"
                    optionFilterProp="children"
                    onChange={onUserGradeChangeForUser}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={Object.entries(userGradeInfo).map((info, idx) => {
                      return { label: info[1], value: info[0] };
                    })}
                    style={{
                      width: 150,
                    }}
                  />
                  <Form.Item
                    name="m_id"
                    label="아이디"
                    rules={[{ required: true }]}
                    noStyle
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="아이디 검색"
                      optionFilterProp="children"
                      onChange={onUserChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={userIdData}
                      style={{
                        width: 200,
                      }}
                    />
                  </Form.Item>
                </Input.Group>
                {/** form 을 2개 사용할때 rule:required 로 생성되는 *표시가 생기지 않기때문에 수동 추가 */}
                <div
                  style={{
                    color: "#ff4d4f",
                    fontSize: "14px",
                    fontFamily: "SimSun,sans-serif",
                    lineHeight: 1,
                    position: "absolute",
                    top: "9px",
                    left: "-66px",
                  }}
                >
                  *
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="폴더명"
                name="fo_name"
                rules={[{ required: true }, { max: 30 }]}
                value={Selected}
              >
                {/*편법임 form value   공백안에 3항연산자  input value에 적어야 변경된 값을 input*/}
                <Input
                  value={Selected ? "" : ""}
                  onChange={setSelected}
                  allowClear
                  //placeholder="폴더이름을 입력하세요 (20자내, 한,영 공백포함)"
                  style={{}}
                  addonBefore={
                    <Space>
                      <Select
                        showSearch
                        allowClear
                        placeholder="회원구분별 검색"
                        optionFilterProp="children"
                        onChange={onUserGradeChange}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={Object.entries(userGradeInfo).map(
                          (info, idx) => {
                            return { label: info[1], value: info[0] };
                          }
                        )}
                        style={{
                          width: 150,
                          marginRight: 5,
                        }}
                      />

                      <Select
                        value={Selected}
                        showSearch
                        allowClear
                        placeholder="해당 구분 기본폴더"
                        optionFilterProp="children"
                        onChange={onStaicFolderChange}
                        //onChange={setfoldselect(foldlist)}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={foldlist?.map((result, idx) => {
                          return { value: result.foldname };
                        })}
                        style={{
                          width: 200,
                        }}
                      />

                      {/*  위 대신 사용해도됨
     <Select placeholder="==선택==" allowClear>
         {foldlist?.map((result, idx) => {
            return <Option value={result.foldname}>{result.foldname}</Option>;
         })}
     </Select>
     */}
                    </Space>
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}></Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="폴더 고유번호"
                name="fo_index"
                rules={[{ required: true }]}
              >
                <Input
                  value={Selected}
                  style={{
                    width: 200,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="폴더 배경색"
                name="fo_back"
                rules={[{ required: true }, { max: 30 }]}
              >
                <Input
                  value={folderBackground}
                  style={{
                    float: "left",
                    width: 410,
                  }}
                  className="colorPicker"
                  addonAfter={
                    <CirclePicker
                      width={300}
                      colors={[
                        "#f49a95",
                        "#f4b78c",
                        "#c0d8ba",
                        "#c7e2e1",
                        "#babff9",
                        "#debcee",
                        "#efc0d4",
                        "#ffcd85",
                        "#ffec8d",
                      ]}
                      color={folderBackground}
                      circleSize={20}
                      circleSpacing={8}
                      onChange={(color) => {
                        form.setFieldsValue({
                          fo_back: color.hex,
                        });
                        setFolderBackground(color.hex);
                      }}
                    />
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="공유 여부"
                name="public_yn"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Radio.Group>
                  <Radio value={1}>{commonYN[1]}</Radio>
                  <Radio value={2}>{commonYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="등록일시"
                name="fo_cre_time"
                rules={[{ required: true }]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <DatePicker showTime style={{ float: "left" }} locale="ko_KR" />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                <Button type="primary" htmlType="submit">
                  등록
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Content>
  );
};

export default FolderRegist;
