import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UnlockOutlined, LockOutlined, FormOutlined } from "@ant-design/icons";
import { Card, Checkbox, Typography } from "antd";
import { updateFolder } from "../service/folderService";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const gridStyle: React.CSSProperties = {
  padding: "5px",
  border: "none",
  boxShadow: "none",
};
const { Paragraph, Text } = Typography;

const ReviewFolder = ({ folderInfo, onSelect, selectedRow, onSetData }) => {
  const {
    m_id,
    fo_no,
    fo_name,
    fo_back,
    public_yn,
    fo_index,
    del_yn,
    card_count,
  } = folderInfo;
  const [checkValue, setCheckValue] = useState(false);
  const [folderStyle, setFolderStyle] = useState({});
  const [flagText, setFlagText] = useState();
  const [publicIcon, setPublicIcon] = useState();

  useEffect(() => {
    if (del_yn === 2) {
      setFolderStyle({
        borderStyle: "dashed",
        borderColor: "red",
        opacity: 0.5,
      });
      setFlagText(
        <div
          style={{
            position: "absolute",
            top: 30,
            width: "100%",
            textAlign: "center",
            color: "red",
            fontSize: 20,
            fontWeight: "bold",
            opacity: 0.5,
            pointerEvents: "none",
          }}
        >
          삭제됨
        </div>
      );
    } else {
      setFolderStyle({});
      setFlagText();
    }
    //console.log(public_yn);
    setPublicIcon(
      public_yn === 1 ? (
        <UnlockOutlined style={{ cursor: "pointer", opacity: 0.5 }} />
      ) : (
        <LockOutlined style={{ cursor: "pointer" }} />
      )
    );
  }, [folderInfo]);

  //선택된 값이 바뀌면 체크 변경
  useEffect(() => {
    if (selectedRow.find((number) => number === fo_no) > 0) {
      setCheckValue(true);
    } else {
      setCheckValue(false);
    }
  }, [selectedRow]);

  const onCheckChange = (e) => {
    //참이면 카드no 추가 아니면 뺌
    if (e.target.checked) {
      onSelect((selectedRowKeys) => [...selectedRowKeys, fo_no]);
    } else {
      onSelect((selectedRowKeys) =>
        selectedRowKeys.filter((number) => number !== fo_no)
      );
    }
  };

  const onChangePublic = () => {
    updateFolder({ public_yn: public_yn === 1 ? 2 : 1 }, fo_no).then(
      (result) => {
        //console.log(result);
        //즐겨찾기 변경에 성공했다면 노출되는 표시 변경
        if (result.message === "success") {
          onSetData((data) =>
            data.map((row, idx) => {
              if (row.fo_no === fo_no) {
                return { ...row, public_yn: public_yn === 1 ? 2 : 1 };
              } else {
                return row;
              }
            })
          );
        }
      }
    );
    //setLikeStare(public_yn === 1 ? <StarOutlined /> : <StarFilled />);
  };

  return (
    <>
      <Card
        style={{
          ...folderStyle,
          width: 160,
          padding: "5px 0",
          background: fo_back,
        }}
      >
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,
            paddingLeft: 15,
            width: "30%",
            textAlign: "left",
            fontSize: 20,
          }}
        >
          <div onClick={onChangePublic}>{publicIcon}</div>
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,
            width: "40%",
            padding: 0,
            textAlign: "center",
            fontSize: 30,
          }}
        >
          <div>
            <Link to={"/folder/modify/" + fo_index} style={{ color: "#000" }}>
              <FormOutlined />
            </Link>
          </div>
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,
            paddingRight: 15,
            width: "30%",
            textAlign: "right",
            marginTop: 5,
          }}
        >
          <Checkbox onChange={onCheckChange} checked={checkValue}></Checkbox>
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{
            ...gridStyle,
            width: "100%",
            height: 50,
            margin: "5px 0",
            textAlign: "center",
          }}
        >
          <Paragraph ellipsis={{ rows: 2 }}>{fo_name}</Paragraph>
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{ ...gridStyle, width: "60%", textAlign: "center" }}
        >
          by {m_id.substr(0, 8)}...
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{ ...gridStyle, width: "40%", textAlign: "center" }}
        >
          {card_count > 0 ? card_count : 0}카드
        </Card.Grid>
        {flagText}
      </Card>
    </>
  );
};

export default ReviewFolder;
