import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Col,
  Select,
  Row,
  Radio,
  message,
  InputNumber,
  Space,
} from "antd";
import {
  userGradeInfo,
  agreeYN,
  useYN,
  userState,
  genderInfo,
  regPhone,
  regPassword,
  outReason,
} from "../../config/staticInfo";
import ImageUploader from "../../components/ImageUploader";
import {
  checkUserDuplicate,
  getUserInfo,
  updateUser,
} from "../../service/userService";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'

const { Content } = Layout;
const { Option } = Select;

const validateMessages = {
  required: "'${label}'을(를) 입력해주세요.",
  pattern: {
    mismatch: "'${label}' 형식에 맞게 작성해주세요.",
  },
  types: {
    email: "'${label}' 형식에 맞게 작성해주세요.",
  },

  // ...
};

const UserModify = ({ history }) => {
  const [form] = Form.useForm();
  const { m_no } = useParams();
  const navigate = useNavigate();
  //중복검사가 필요한 항목만 설정
  const [dpCheck, setDpCheck] = useState({
    m_nname: "",
  });

  const [originalInfo, setOriginalInfo] = useState();
  const [withdrawComponent, setWithdrawComponent] = useState(<></>);

  const fetchData = async () => {
    const userInfo = await getUserInfo(m_no);

    setOriginalInfo({
      m_id: userInfo.m_id,
      m_nname: userInfo.m_nname,
      m_hp: userInfo.m_hp,
      m_grade: userInfo.m_grade,
      gender: userInfo.gender,
      grade: userInfo.grade,
      birth: dayjs(userInfo.birth),
      m_email: userInfo.m_email,
      hp_uid: userInfo.hp_uid,
      conf_yn: userInfo.conf_yn,
      useminfo_yn: userInfo.useminfo_yn,
      push_yn: userInfo.push_yn,
      email_yn: userInfo.email_yn,
      use_state: userInfo.use_state,
      auto_yn: userInfo.auto_yn,
      pic_route: {
        file: {
          uid: userInfo.m_id,
          url: userInfo.pic_route,
          status: "done",
          name: userInfo.m_nname,
        },
      },
      outtime: userInfo.outtime ? dayjs(userInfo.outtime) : "",
      out_reason: userInfo.out_reason,
      out_yn: userInfo.out_yn,
    });

    //console.log("userinfo2", originalInfo);
    //form.setFieldsValue(originalInfo);
  };

  useEffect(() => {
    fetchData();
    //초기값 설정
    //console.log(navigate);
  }, []);

  //받아온 정보로 변경되었다면 form에 삽입
  useEffect(() => {
    form.setFieldsValue(originalInfo);
    if (originalInfo?.use_state === 2) {
      renderWithdraw();
    }

    //초기값 설정
  }, [originalInfo]);

  const onFinish = async (values) => {
    //닉네임이 수정되었을 경우에만 중복확인 필요
    if (originalInfo.m_nname !== values.m_nname) {
      const dpCheckSuccess = Object.entries(dpCheck).filter((result) => {
        return result[1].includes("success");
      });
      //success가 1개가 아니면 실행하지 않음
      if (dpCheckSuccess.length < 1) {
        message.error("중복확인을 진행하신 후 등록 가능합니다.");
        return false;
      }
    }

    const formValues = {
      ...values,
      birth: dayjs(values.birth).format("YYYYMMDD"),
      pic_route: values.pic_route.file.url,
    };

    let updateInfo = {};

    Object.entries(originalInfo).map((original, idx) => {
      //console.log("origin", original[1]);
      //console.log("update", values[original[0]]);
      if (original[1] !== formValues[original[0]]) {
        switch (original[0]) {
          default:
            updateInfo = {
              ...updateInfo,
              [original[0]]: formValues[original[0]],
            };
            break;
          case "birth":
            if (
              dayjs(original[1]).format("YYYYMMDD") !== formValues[original[0]]
            ) {
              updateInfo = {
                ...updateInfo,
                [original[0]]: formValues[original[0]],
              };
            }
            break;
          case "outtime":
            if (
              dayjs(original[1]).format("YYYY-MM-DD HH:mm:ss") !==
              formValues[original[0]]
            ) {
              updateInfo = {
                ...updateInfo,
                [original[0]]: formValues[original[0]],
              };
            }
            break;
          case "pic_route":
            if (original[1].file.url !== formValues[original[0]]) {
              updateInfo = {
                ...updateInfo,
                [original[0]]: formValues[original[0]],
              };
            }
            break;
        }
        return true;
      }
      return false;
    });

    console.log("result updateinfo", updateInfo);

    //수정한 값이 없다면 수정 진행 안됨
    if (Object.keys(updateInfo).length <= 0) {
      message.error("수정된 항목이 없습니다.");
      return false;
    }

    const result = await updateUser(updateInfo, m_no);

    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("수정이 완료되었습니다.");
        navigate("/user/detail/" + m_no);
        break;
      case "exist nname":
        message.error("이미 존재하는 닉네임입니다.");
        break;
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //중복확인
  const checkDuplicate = async (target) => {
    const value = form.getFieldValue(target);
    if (value && value !== originalInfo[target]) {
      const checkResult = await checkUserDuplicate({
        target: target,
        value: value,
      });

      //결과값이 true면 중복된값(이미 존재하는 값) false일때 사용가능
      if (checkResult) {
        form.setFields([{ name: target, errors: ["사용중인 값입니다."] }]);
        setDpCheck({ ...dpCheck, [target]: "error" });
      } else {
        setDpCheck({ ...dpCheck, [target]: "success" });
      }
    }
  };

  //중복검사한 후에 값을 변경했을 경우 해당 항목 다시 검사 필요하도록 초기화
  const initCheck = (target) => {
    setDpCheck({ ...dpCheck, [target]: "" });
  };

  const stateCheck = (e) => {
    console.log("state", e);
    if (e.target.value === 2) {
      renderWithdraw();
    } else {
      setWithdrawComponent(<></>);
    }
  };

  const renderWithdraw = () => {
    setWithdrawComponent(
      <>
        <Form.Item label="탈퇴일시" name="outtime" rules={[{ required: true }]}>
          <DatePicker showTime style={{ float: "left" }} locale="ko_KR" />
        </Form.Item>
        <Form.Item
          label="탈퇴동의여부"
          name="out_yn"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={1}>{agreeYN[1]}</Radio>
            <Radio value={2}>{agreeYN[2]}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="탈퇴사유"
          name="out_reason"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={1}>{outReason[1]}</Radio>
            <Radio value={2}>{outReason[2]}</Radio>
            <Radio value={3}>{outReason[3]}</Radio>
            <Radio value={4}>{outReason[4]}</Radio>
            <Radio value={5}>{outReason[5]}</Radio>
          </Radio.Group>
        </Form.Item>
      </>
    );
    form.setFieldsValue({
      outtime: originalInfo?.outtime,
      out_reason: originalInfo?.out_reason,
      out_yn: originalInfo?.out_yn,
    });
  };

  return (
    <Content style={{ margin: "0 16px",border:'1px solid #f8f8f8',backgroundColor:'#fff',marginTop:15 ,boxShadow:'1px 1.5px 5px 1px #ebebeb',borderRadius:15,}}>
      <Breadcrumb style={{ padding: "20px 0px 0px 30px " ,borderBottom :'1px solid #ebebeb',fontWeight:'bold',color:'#333', fontSize:14,height:55,}}>
        <Breadcrumb.Item>푸시알림관리</Breadcrumb.Item>
        <Breadcrumb.Item>푸시알림수정</Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="회원구분"
                name="m_grade"
                rules={[{ required: true }]}
              >
                <Select placeholder="==선택==" allowClear>
                  {Object.entries(userGradeInfo).map((result, idx) => {
                    return (
                      <Option value={Number(result[0])}>{result[1]}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="아이디"
                name="m_id"
                rules={[{ required: true }, { min: 5, max: 12 }]}
              >
                <Input allowClear onChange={() => initCheck("m_id")} disabled />
              </Form.Item>
              <Form.Item
                name="m_nname"
                label="닉네임"
                rules={[{ required: true }, { min: 2, max: 12 }]}
                hasFeedback
                validateStatus={dpCheck.m_nname}
              >
                <Input
                  allowClear
                  onChange={() => initCheck("m_nname")}
                  addonAfter={
                    <Button
                      type="text"
                      size="small"
                      onClick={() => checkDuplicate("m_nname")}
                    >
                      중복확인
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item
                label="이메일"
                name="m_email"
                rules={[{ required: true }, { type: "email" }]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="UID기기번호"
                name="hp_uid"
                rules={[{ required: true }]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="신규 비밀번호"
                name="m_pw"
                rules={[
                  { min: 8, max: 20 },
                  {
                    pattern: regPassword,
                    message:
                      "비밀번호는 영문, 숫자, 특수문자를 혼합하여야 합니다.",
                  },
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder="비밀번호를 새로 입력해야할때만 입력주세요."
                />
              </Form.Item>
              <Form.Item label="이용약관동의" name="conf_yn">
                <Radio.Group disabled>
                  <Radio value={1}>{agreeYN[1]}</Radio>
                  <Radio value={2}>{agreeYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="개인정보처리동의" name="useminfo_yn">
                <Radio.Group disabled>
                  <Radio value={1}>{agreeYN[1]}</Radio>
                  <Radio value={2}>{agreeYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="푸시알림수신동의" name="push_yn">
                <Radio.Group>
                  <Radio value={1}>{agreeYN[1]}</Radio>
                  <Radio value={2}>{agreeYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="이메일수신동의" name="email_yn">
                <Radio.Group>
                  <Radio value={1}>{agreeYN[1]}</Radio>
                  <Radio value={2}>{agreeYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="전화번호"
                name="m_hp"
                rules={[{ required: true }, { pattern: regPhone }]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="성별"
                name="gender"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={1}>{genderInfo[1]}</Radio>
                  <Radio value={2}>{genderInfo[2]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="학년" name="grade">
                <InputNumber allowClear />
              </Form.Item>
              <Form.Item
                label="생년월일"
                name="birth"
                rules={[{ required: true }]}
              >
                <DatePicker style={{ float: "left" }} locale="ko_KR" />
              </Form.Item>
              <Form.Item label="회원상태" name="use_state">
                <Radio.Group onChange={(e) => stateCheck(e)}>
                  <Radio value={1}>{userState[1]}</Radio>
                  <Radio value={2}>{userState[2]}</Radio>
                  <Radio value={3}>{userState[3]}</Radio>
                </Radio.Group>
              </Form.Item>
              {withdrawComponent}
              <Form.Item label="프로필 사진" name="pic_route">
                <ImageUploader target="profile" />
              </Form.Item>
              <Form.Item label="자동로그인 여부" name="auto_yn">
                <Radio.Group>
                  <Radio value={1}>{useYN[1]}</Radio>
                  <Radio value={2}>{useYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                <Space wrap>
                  <Button type="primary" htmlType="submit">
                    수정
                  </Button>
                  <Button onClick={() => navigate(-1)}>취소</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Content>
  );
};

export default UserModify;
