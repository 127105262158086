import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  Form,
  DatePicker,
  Input,
  Select,
  message,
  InputNumber,
  Radio,
  Space,
} from "antd";
import { commonYN, badYN, delYN, userGradeInfo } from "../../config/staticInfo";
import { Link, useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "../../components/RichTextEditor";
import { getUserIdList } from "../../service/userService";
import { getFolderNameList } from "../../service/folderService";
import {
  createCardIndex,
  updateCard,
  getCardInfo,
} from "../../service/cardService";

import dayjs from "dayjs";

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

const validateMessages = {
  required: "'${label}'을(를) 입력해주세요.",
  pattern: {
    mismatch: "'${label}' 형식에 맞게 작성해주세요.",
  },
  types: {
    email: "'${label}' 형식에 맞게 작성해주세요.",
  },

  // ...
};

const CardModify = () => {
  const navigate = useNavigate();
  const { ca_no } = useParams();
  const [form] = Form.useForm();
  const [editorValue, setEditorValue] = useState();
  const [userIdData, setUserIdData] = useState();
  const [folderData, setFolderData] = useState();
  const [folderIndexData, setFolderIndexData] = useState();
  const [data, setData] = useState([]);
  const [originalInfo, setOriginalInfo] = useState();

  useEffect(() => {
    //기본 유저목록, 유저 전체 대상
    getUserIdList().then((result) => {
      setUserIdData(
        result.map((info, idx) => {
          return { label: info.m_id, value: info.m_id };
        })
      );
    });
    fetchData();
  }, []);

  const fetchData = async () => {
    const cardInfo = await getCardInfo(ca_no);

    setOriginalInfo({
      m_id: cardInfo.m_id,
      ca_index: cardInfo.ca_index,
      fo_name: cardInfo.fo_name,
      fo_index: cardInfo.fo_index,
      x_number: cardInfo.x_number,
      t_number: cardInfo.t_number,
      time_set: cardInfo.time_set,
      ca_keyword: cardInfo.ca_keyword,
      ca_detail: cardInfo.ca_detail,
      ca_liked: cardInfo.ca_liked,
      reg_date: cardInfo.reg_date ? dayjs(cardInfo.reg_date) : "",
      del_yn: cardInfo.del_yn,
      bad_yn: cardInfo.bad_yn,
    });

    //폴더 목록을 해당 유저가 보유중인 폴더로 변경
    getFolderNameList({
      col: [`fo_name`, `fo_index`],
      target: `m_id`,
      value: cardInfo.m_id,
    }).then((result) => {
      //폴더명 select
      setFolderData(
        result.map((info, idx) => {
          return { label: info.fo_name, value: info.fo_name };
        })
      );
      //폴더 선택시 입력될 index값
      setFolderIndexData(
        result.map((info, idx) => {
          return { fo_name: info.fo_name, fo_index: info.fo_index };
        })
      );
    });
    //console.log("cardInfo2", originalInfo);
    //form.setFieldsValue(originalInfo);
  };

  //받아온 정보로 변경되었다면 form에 삽입
  useEffect(() => {
    form.setFieldsValue(originalInfo);
    //초기값 설정
  }, [originalInfo]);

  //유저 변경
  const onUserChange = (value) => {
    //console.log(`selected ${value}`);

    //폴더 목록을 해당 유저가 보유중인 폴더로 변경
    getFolderNameList({
      col: [`fo_name`, `fo_index`],
      target: `m_id`,
      value: value,
    }).then((result) => {
      //폴더명 select
      setFolderData(
        result.map((info, idx) => {
          return { label: info.fo_name, value: info.fo_name };
        })
      );
      //폴더 선택시 입력될 index값
      setFolderIndexData(
        result.map((info, idx) => {
          return { fo_name: info.fo_name, fo_index: info.fo_index };
        })
      );
    });

    //유저를 선택하면 카드 고유번호를 생성
    form.setFieldsValue({
      ca_index: value ? createCardIndex(value) : value,
      fo_name: null,
      fo_index: null,
    });
  };

  //폴더 변경
  const onFolderChange = (value) => {
    const selectFolderIndex = folderIndexData.filter(
      (info) => info.fo_name === value
    );
    form.setFieldsValue({
      fo_index: selectFolderIndex[0]?.fo_index,
    });
  };

  //유저 구분 변경
  const onUserGradeChange = (value) => {
    //유저 목록을 해당 구분값의 유저로 변경
    getUserIdList({ target: `m_grade`, value: value }).then((result) => {
      setUserIdData(
        result.map((info, idx) => {
          return { label: info.m_id, value: info.m_id };
        })
      );
    });
  };
  const onFinish = async (values) => {
    console.log("Finish:", values);
    const formValues = {
      ...values,
      reg_date: dayjs(values.reg_date).format("YYYY-MM-DD HH:mm:ss"),
    };

    let updateInfo = {};

    Object.entries(originalInfo).map((original, idx) => {
      //console.log("origin", original[1]);
      //console.log("update", values[original[0]]);
      if (original[1] !== formValues[original[0]]) {
        switch (original[0]) {
          default:
            updateInfo = {
              ...updateInfo,
              [original[0]]: formValues[original[0]],
            };
            break;
          case "reg_date":
            if (
              dayjs(original[1]).format("YYYY-MM-DD HH:mm:ss") !==
              formValues[original[0]]
            ) {
              updateInfo = {
                ...updateInfo,
                [original[0]]: formValues[original[0]],
              };
            }
            break;
        }
        return true;
      }
      return false;
    });

    //console.log("originalInfo", originalInfo);
    //console.log("update", updateInfo);
    if (Object.keys(updateInfo).length <= 0) {
      message.error("수정된 내용이 없습니다.");
      return false;
    }

    const result = await updateCard(updateInfo, ca_no);

    console.log("update", result);

    switch (result.message) {
      default:
        message.error("오류가 발생했습니다.");
        break;
      case "success":
        alert("수정이 완료되었습니다.");
        navigate("/card/detail/" + ca_no);
        break;
      case "exist index":
        message.error("이미 존재하는 고유번호입니다.");
        break;
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/card" style={{ color: "#333333" }}>
            복습카드
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            복습카드 수정
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="아이디">
                <Input.Group compact>
                  <Select
                    showSearch
                    allowClear
                    placeholder="회원구분별 검색"
                    optionFilterProp="children"
                    onChange={onUserGradeChange}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={Object.entries(userGradeInfo).map((info, idx) => {
                      return { label: info[1], value: info[0] };
                    })}
                    style={{
                      width: 150,
                    }}
                  />
                  <Form.Item
                    name="m_id"
                    label="아이디"
                    rules={[{ required: true }]}
                    noStyle
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="아이디 검색"
                      optionFilterProp="children"
                      onChange={onUserChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={userIdData}
                      style={{
                        width: 200,
                      }}
                    />
                  </Form.Item>
                </Input.Group>
                {/** form 을 2개 사용할때 rule:required 로 생성되는 *표시가 생기지 않기때문에 수동 추가 */}
                <div
                  style={{
                    color: "#ff4d4f",
                    fontSize: "14px",
                    fontFamily: "SimSun,sans-serif",
                    lineHeight: 1,
                    position: "absolute",
                    top: "9px",
                    left: "-66px",
                  }}
                >
                  *
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="폴더명"
                name="fo_name"
                rules={[{ required: true }, { max: 30 }]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="아이디 선택 후 확인 가능"
                  optionFilterProp="children"
                  onChange={onFolderChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={folderData}
                  style={{
                    width: 250,
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="폴더 고유번호"
                name="fo_index"
                rules={[{ required: true }]}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Input
                  disabled
                  style={{
                    width: 200,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="카드 고유번호"
                name="ca_index"
                rules={[{ required: true }]}
              >
                <Input
                  allowClear
                  style={{
                    width: 300,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="키워드명"
                name="ca_keyword"
                rules={[{ required: true }, { max: 30 }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <link
                rel="stylesheet"
                href="https://unpkg.com/react-quill@1.3.3/dist/quill.snow.css"
              />
              <Form.Item
                label="카드 상세내용"
                name="ca_detail"
                rules={[{ required: true }]}
              >
                <RichTextEditor imageTarget="card" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="즐겨찾기 여부"
                name="ca_liked"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Radio.Group>
                  <Radio value={1}>{commonYN[1]}</Radio>
                  <Radio value={2}>{commonYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="틀린횟수"
                name="x_number"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Input
                  style={{
                    width: 100,
                  }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="신고여부"
                name="bad_yn"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Radio.Group>
                  <Radio value={1}>{badYN[1]}</Radio>
                  <Radio value={2}>{badYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="총 게임횟수"
                name="t_number"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Input
                  style={{
                    width: 100,
                  }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="삭제여부"
                name="del_yn"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Radio.Group>
                  <Radio value={1}>{delYN[1]}</Radio>
                  <Radio value={2}>{delYN[2]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="시간제한"
                name="time_set"
                rules={[{ required: true }]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <InputNumber
                  allowClear
                  style={{
                    width: 100,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="등록일시"
                name="reg_date"
                rules={[{ required: true }]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <DatePicker showTime style={{ float: "left" }} locale="ko_KR" />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                <Space wrap>
                  <Button type="primary" htmlType="submit">
                    수정
                  </Button>
                  <Button onClick={() => navigate(-1)}>취소</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Content>
  );
};

export default CardModify;
