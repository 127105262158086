import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Col, Button, Row, Space, Image } from "antd";
import { Link, useParams } from "react-router-dom";
import { getUserDetail, deleteUser } from "../../service/userService";
import { useNavigate } from "react-router-dom";
//import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import { useSelector } from "react-redux";

const { Content } = Layout;

const UserDetail = () => {
  const { user } = useSelector((state) => state.auth); ///==>무슨의미? 어디서 호출?
  const navigate = useNavigate();
  const { m_no } = useParams();
  const [data, setData] = useState();
  const [backUrl, setbackUrl] = useState("/user");

  console.log("m_no" + JSON.stringify(useParams()));
  const fetchData = async () => {
    const userInfo = await getUserDetail(m_no);
    console.log("userinfo1", userInfo);
    setData(userInfo);
  };

  useEffect(() => {
    fetchData();

    //상세 페이지를 회원목록, 탈퇴목록에서 사용하기위한 목록 버튼 URL
    setbackUrl(sessionStorage.getItem("backUrl"));
  }, []);

  const onRemove = () => {
    if (window.confirm("삭제 하시겠습니까? 삭제하시면 복구가 불가능합니다.")) {
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        //삭제처리
        alert("삭제되었습니다.");
      }
    }
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/user" style={{ color: "#333333" }}>
            회원관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            회원상세
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contentArea">
        <Row gutter={[16, 16]} className="detailTalbe">
          {data?.map((result, idx) => {
            if (result.name === "프로필사진") {
              //사진은  URL과 이미지가 보이도록
              return (
                <>
                  <Col span={4} className="rightTitle">
                    {result.name}
                  </Col>
                  <Col span={8}>
                    <div style={{ wordBreak: "break-word" }}>
                      {result.value}
                      <br />
                      <Image width={200} src={result.value} />
                    </div>
                  </Col>
                </>
              );
            } else if (result.name === "밑줄") {
              //가독성을 위해 구분선을 추가함
              return (
                <>
                  <Col
                    span={24}
                    style={{ borderBottom: "1px solid rgb(235, 235, 235)" }}
                  ></Col>
                </>
              );
            }
            return (
              <>
                <Col span={4} className="rightTitle">
                  {result.name}
                </Col>
                <Col span={8} style={{ wordBreak: "break-word" }}>
                  {result.value}
                </Col>
              </>
            );
          })}
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space wrap>
              <Button
                className="editButton"
                size="large"
                onClick={() => navigate(`/user/modify/${m_no}`)}
              >
                수정
              </Button>{" "}
              {/*아래는  서버에서 사용시코드이고 local에서사용시{user.user.m_grade} 한다*/}
              {user.m_grade === 1 ? (
                <Button danger size="large" onClick={() => deleteUser(m_no)}>
                  삭제
                </Button>
              ) : (
                ""
              )}
              <Button size="large" onClick={() => navigate(backUrl)}>
                목록
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default UserDetail;
