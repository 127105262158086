import React from "react";
import { Col, Row, Button } from "antd";
import { LogoutOutlined, HomeOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { authUserLogout } from "../feature/authSlice";

import "../css/Main.css";
import { Link, useNavigate } from "react-router-dom";

const AppFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(user);

  return (
    <Row
      gutter={24}
      style={{
        margin: "0 auto",
        width: "98.5%",
        height: "auto",
        minHeight: 120,
        fontSize: "14pt",
        fontWeight: "bold",
        color: "#000",
        border: "1px solid #ebebeb",
        borderRadius: 20,
        backgroundColor: "#ffffff",
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        padding: 20,
      }}
    >
      <Col
        span={5}
        style={{
          fontSize: "14pt",
          fontWeight: "bold",
          color: "#000",
          border: "0px solid #999",
        }}
      >
        <div className="headerLogo" style={{ marginTop: 25 }}>
          <Link to={"/"}>THENEXT 더넥스트</Link>
        </div>
      </Col>
      <Col
        span={19}
        style={{
          fontSize: "12pt",
          fontWeight: "normal",
          color: "#000",
          border: "0px solid #999",
          textAlign: "left",
          paddingTop: 15,
        }}
      >
        <div>
          대표자 : 박지수 ｜ 사업자등록번호 : 524-86-02814 
        </div>
        <div>
          본사 : 경기도 안양시 동안구 홍안대로514, 4층 
        </div>
        <div style={{ marginTop: 10 }}>
          Copyright ⓒ THENEXT Edu Inc All rights reserved.
        </div>
        {/*
        {user.m_nname}님 안녕하세요.
        <Button  type="default"  icon={<LogoutOutlined />} style={{ marginLeft: "10px", marginTop:22}} onClick={() => dispatch(authUserLogout())} ></Button>
        <Button  type="default"  icon={<HomeOutlined  />}  style={{ marginLeft: "10px" ,marginTop:22}} onClick={() => navigate('/')}></Button>
       */}
      </Col>
    </Row>
  );
};

export default AppFooter;
