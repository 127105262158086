import React, { useEffect, useState } from "react";
import { Layout, Form, Input, Button, Divider, Row, Col } from "antd";
import { Navigate } from "react-router-dom";
import { PoweroffOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { authUserLogin, authUserInfo } from "../feature/authSlice"; // 회원로그인관련 정보를가져오는 페이지
import { request } from "../utils/axios";
{
  /*회원로그인페이지*/
}

const { Content } = Layout; //antdesign 코드를 간편하게하기위한 태그변경함수

const Signin = () => {
  const users = JSON.parse(sessionStorage.getItem("user"));
  console.log("session정보는" + JSON.stringify(users));

  const { isLogin } = useSelector((state) => state.auth); // 무슨의미인가?
  const dispatch = useDispatch(); //state 변경이 이루어질 수 있게 하는 것(Action) 과 state 변경을 해주는 Action을 스토어까지 가져와주는 스토어 관리자(Dispatcher)  사용선언
  console.log("isLogin은 " + JSON.stringify(isLogin, null, 2));

  const [form] = Form.useForm();
  const [ip, setIp] = useState();
  //console.log('아이피정보1'+ ip)

  const ipinfo = async () => {
    const ipData = await fetch("https://geolocation-db.com/json/");
    const locationIp = await ipData.json();
    setIp(locationIp);

    //console.log('아이피정보는'+ip);
    return locationIp;
  };

  //form.setFieldsValue({ipinformation:ip});
  //form.setFieldsValue({ ipinformation: ip ? ip.IPv4 : "" });

  //게시글 가져오기
  useEffect(() => {
    dispatch(authUserInfo());
    //ipinfo();
  }, []);
  console.log("아이피정보" + JSON.stringify(ip, null, 2));

  const onFinish = async (values) => {
    await dispatch(authUserLogin(values)).then(() => {
      //feature/authSlice> authUserLogin 함수로 입력값전달해서 콜백을 받는다
      console.log("로그인values", values); // 아래에서 버튼클릭후 성공이면 값이  userid: 'admin', password: '1234' 출력된다
      console.log("login then", isLogin);
      console.log("session user", JSON.parse(sessionStorage.getItem("user")));
    });
    console.log("login", isLogin);
    /*axios
      .post("//xn--pn3b68hmps.com/api/userLogin", {
        userid: values.userid,
        password: values.password,
      })
      .then((res) => {
        console.log(res);
        console.log("res.data.userId :: ", res.data.userId);
        console.log("res.data.msg :: ", res.data.msg);
        if (res.data.m_id === undefined) {
          // id 일치하지 않는 경우 userId = undefined, msg = '입력하신 id 가 일치하지 않습니다.'
          console.log("======================", res.data.msg);
          alert("입력하신 id 가 일치하지 않습니다.");
        } else if (res.data.m_id === null) {
          // id는 있지만, pw 는 다른 경우 userId = null , msg = undefined
          console.log(
            "======================",
            "입력하신 비밀번호 가 일치하지 않습니다."
          );
          alert("입력하신 비밀번호 가 일치하지 않습니다.");
        } else if (res.data.m_id === values.userid) {
          // id, pw 모두 일치 userId = userId1, msg = undefined
          console.log("======================", "로그인 성공");
          sessionStorage.setItem("userid", values.userid);
          // 작업 완료 되면 페이지 이동(새로고침)
          document.location.href = "/";
        }
      })
      .catch();*/
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (isLogin) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <Content style={{ margin: "0 16px" }}>
      <div
        style={{
          padding: 34,
          background: "#fff",
          maxWidth: 500,
          border: "2px solid #ebebeb",
          borderRadius: 20,
          margin: "250px auto 5px",
          boxShadow: "1px 1.5px 5px 1px #ebebeb",
        }}
      >
        <Row>
          <Col
            span={6}
            style={{
              padding: 5,
              textAlign: "center",
            }}
          >
            <p>
              <PoweroffOutlined style={{ fontSize: 80 }} />
            </p>
          </Col>
          <Col span={18}>
            <p
              style={{
                padding: 5,
                textAlign: "left",
              }}
            >
              THENEXT 더넥스트
              <br />
              <span style={{ fontSize: 30, fontWeight: "bold" }}>
                MEMBER <span style={{ color: "#df0000" }}>LOGIN</span>
              </span>
              <br />
              <span style={{ fontSize: 11, color: "#8f8f8f" }}>
                자기주도 학습용 APP 관리자
              </span>
            </p>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 20 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={{
                margin: "0 auto",
                fontSize: "14pt",
                color: "#000",
                border: "0px solid #999",
                width: "80%",
                verticalAlign: "middle",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Row justify="space-between" align="middle">
                <Col span={19}>
                  <Form.Item
                    label="ID"
                    name="userid"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                    style={{
                      fontSize: "14pt",
                      color: "#000",
                      border: "0px solid red",
                      width: "99%",
                    }}
                  >
                    <Input
                      allowClear
                      style={{
                        fontSize: "12pt",
                        color: "#000",
                        border: "1px solid #ebebeb",
                        height: 40,
                      }}
                    />
                    {/*입력된 내용 삭제 아이콘이 달려있는 input*/}
                  </Form.Item>

                  <Form.Item
                    label="PW"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    style={{
                      fontSize: "14pt",
                      color: "#000",
                      border: "0px solid red",
                      width: "99%",
                      marginTop: -20,
                    }}
                  >
                    <Input.Password
                      allowClear
                      style={{
                        fontSize: "12pt",
                        color: "#000",
                        border: "1px solid #ebebeb",
                        height: 40,
                      }}
                    />
                    {/*입력된 내용 삭제 아이콘이 달려있는 input*/}
                  </Form.Item>
                  {/*}
                  <Form.Item
                    label="ip"
                    name="ipinformation"
                    style={{
                      fontSize: "14pt",
                      color: "#000",
                      border: "0px solid red",
                      width: "99%",
                      marginTop: -20,
                    }}
                  >
                    <Input
                      readOnly
                      style={{
                        fontSize: "12pt",
                        color: "#000",
                        border: "1px solid #ebebeb",
                        height: 40,
                      }}
                    />
                  </Form.Item>*/}
                </Col>
                <Col span={4}>
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        height: 88,
                        width: 88,
                        fontSize: "12pt",
                        color: "#fff",
                        border: "0px solid #005ff9",
                        left: -15,
                        borderRadius: 20,
                      }}
                    >
                      LOGIN
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      <p
        style={{
          textAlign: "center",
          color: "#8f8f8f",
        }}
      >
        COPYRIGHT © THENEXT Edu CO,.LTD ALL RIGHTS RESERVED
      </p>
    </Content>
  );
};

export default Signin;
