import React, { useEffect } from "react";
import { Navigate,Link } from "react-router-dom";
import Main from "./Main";

import { useDispatch, useSelector } from "react-redux";
import { authUserInfo } from "../feature/authSlice";

const PrivateRoute = () => {
  const { isLogin } = useSelector((state) => state.auth);// 로그인상태를 어떻게 체크하는지???   설명 https://phsun102.tistory.com/85
  const dispatch = useDispatch();
//console.log('isLogin은' + JSON.stringify(state))

  //게시글 가져오기
  useEffect(() => {
    dispatch(authUserInfo());// 있으나 없으나 게시글가져오기에는 지장없음
  }, []);

  //return isLogin() ? <Outlet /> : <Navigate to="/login" replace={true} />;
  //로그인 하지 않았다면 로그인 페이지로, 아니면  메인화면 띄움, url에 직접입력되는경우 이동되는 페이지
  //return isLogin ? <Main /> : <Link to="/user">회원목록</Link>
  return isLogin ? <Main /> : <Navigate to="/login" replace={true} />;
};

export default PrivateRoute;
