import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Button,
  Row,
  Breadcrumb,
  Table,
  Space,
  DatePicker,
  Input,
  Select,
  message,
  Form,
  Divider,
} from "antd";
import { FileExcelOutlined, ReloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, Link } from "react-router-dom";
import type { FilterValue } from "antd/es/table/interface";
import { CSVLink } from "react-csv";
import {
  moredata,
  getreportlist,
  getreportFullList,
  deletechecks,
  getSimpleStats,
} from "../../service/reportService";
import {
  reqnumber,
  userGradeInfo,
  agreeYN,
  useYN,
  loginYN,
  readYN,
  userState,
  genderInfo,
  regPhone,
  regPassword,
  removeblank,
  reportReason,
} from "../../config/staticInfo";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
//신고받은 카드는 수정할 필요없음 회원들의 고유 개인의사에대한 결과이므로 수정이 필요한경우 회원확인후 db에서 수정해야함

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

//테이블 bad_card사용,   column 헤더에 출력되는 1row 값

console.log("날짜" + dayjs().format("YYYY-MM-DD hh:mm:ss"));

//=====>수정
const columns: ColumnsType = [
  {
    title: "No",
    dataIndex: "ba_no",
    align: "center",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  { title: "신고자", dataIndex: "m_id", align: "center" },
  {
    title: "카드상태",
    dataIndex: "ca_condition",
    align: "center",
    filters: [
      { text: "공유불가", value: 1 },
      { text: "틀린카드", value: 2 },
    ],
  },
  { title: "폴더명", dataIndex: "fo_name", align: "center" },
  { title: "카드명", dataIndex: "ca_keyword", align: "center" },
  {
    title: "신고사유",
    dataIndex: "reason",
    align: "center",
    filters: [
      { text: "부정확", value: 1 },
      { text: "부적절", value: 2 },
      { text: "부정행위", value: 3 },
      { text: "지적재산권", value: 4 },
      { text: "기타", value: 5 },
    ],
  },
  { title: "신고일", dataIndex: "e_date", align: "center" },
];

const Reportlist = () => {
  const { user } = useSelector((state) => state.auth); ///==>무슨의미? 어디서 호출?
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //체크박스체크시 넘겨온ba_no 키값을 selectedRowKeys에 배열로저장및 업데이트한다
  const [data, setData] = useState();
  const [dateRange, setDateRange] = useState(["", ""]); // 기간검색  시작일과 종료일이 저장되고 업데이트된다
  const [searchTarget, setSearchTarget] = useState(); //검색필드 담는 변수와 업데이트 함수
  const [dateTarget, setDateTarget] = useState(); // 기간을 검색하는 여러필드들을 사전에  선택하고 업데이트 하게 한다
  const [loading, setLoading] = useState(false);
  const [csvComponent, setCsvCompnent] = useState(<></>);

  const [form] = Form.useForm();

  //기본 테이블 설정   페이징처리시 필요한 초기세팅값
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  //console.log('테이블명정보' + JSON.stringify( tableParams));

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문==> 수정하지말것+++++++++++++++++++++++++++++++++++++++

  //기간선택부분  //날짜 선택시 검색 시작일,종료일선택해서 setDateRange()함수 업데이트//
  const onSearchDateChange = (dates, dateStrings) => {
    console.log("선택한 날짜는" + dateStrings); //시작일dateStrings[0]   종료일dateStrings[1]    2023-01-11식으로출력
    setDateRange(dateStrings);
  };

  //검색필드 선택시 value와 option값을 인수로 넘겨  setSearchTarget()를 업데이트한다==> 수정하지말것
  const onSelectSearch = (value, option) => {
    setSearchTarget(value);
  };

  //없어도 되는부분 이선택
  const onSelectSearchDate = (value, option) => {
    // 원래 이부분은 기간이지만 검색할 날짜필드값이 많아  여러칼럼을 select에서 option으로 선택하게 했음==> 수정하지말것
    setDateTarget(value);
  };

  //실제 검색함수==> antd 라이브러리에서 검색 input박스 에 버튼까지 연결시킨 input 박스임 입력검색 버튼을 눌렀을때 이 컴포의 value==> 수정하지말것
  const onSearch = (value) => {
    // (검색값value가 있고 검색필드에서 값이 선택됬다면 ) || (검색시작일과종료일이 선택된  검색구간)==> 수정하지말것
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
      searchDate: dateRange, // 검색기간
      dateTarget: dateTarget, // 기간을검색할필드로 기간선택 텍스트로 통일가능 없어두됨
      searchParam: { target: searchTarget, value: value }, //searchTarget  검색할 필드값   value=검색어
    });
  };

  //++++++++++++++++++++++++++++++++++검색일과 검색어 입력후 검색을 하기위한 구문 end+++++++++++++++++++++++++++++++++++++++

  //+++++++++++++++++++++++++++++++3. data를 가져와  리턴받은 값을 setData()로 data변수에 리턴한다+++++++++++++++++++++++++++++++//+++++수정+++++
  const fetchData = async () => {
    //alert('3');
    setLoading(true);
    /*fetch(
        `//xn--pn3b68hmps.com/api/user/list?${qs.stringify(
          (tableParams)
        )}`
      )*/
    console.log("tableParams1", tableParams);
    const listresult = await getreportlist(tableParams); //// service>userService.js >  getUserList()  함수를 실행해서 node에 던지고 결과를 리턴받는다  ====>수정
    console.log("listresult" + JSON.stringify(listresult, null, 2));
    setData(listresult.list);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: listresult.total,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  //+++++++++++++++++++++++++++++++2.  tableParams 변경시  fetchData();함수를 1번실행해서 데이타를 가져온다+++++++++++++++++++++++++++++++
  useEffect(() => {
    //alert('2');
    fetchData();
    setCsvCompnent(<></>);
  }, [JSON.stringify(tableParams)]);

  //console.log('datasms'+JSON.stringify( fetchData(),null,2))

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter
  ) => {
    console.log("table chagne는", pagination, filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters,
      sorter,
    });
  };

  {
    /*======>수정  받기 클릭시 다운파일명 수정,  
  nodeserver> res.send({reportlist:list});//==>수정 , 이부분이 reportservice의 reportlist부분과 일치하지않으면 다운로드버튼이 안보이고, checkbox클릭시 값이 출력되지않아 전체삭제불가능*/
  }
  const downloadCSV = async () => {
    const { csv_data, csv_header } = await getreportFullList(
      "fullList",
      tableParams
    );
    setCsvCompnent(
      <CSVLink
        data={csv_data}
        headers={csv_header}
        filename={dayjs().format("YYYYMMDD") + "_report_list.csv"}
      >
        <Button className="exelButton">
          <FileExcelOutlined />
          받기
        </Button>
      </CSVLink>
    );
  };

  //데이터 갱신
  const refreshData = () => {
    fetchData();
    //엑셀항목 초기화
    setCsvCompnent(<></>);
    //선택된 항목 초기화
    setSelectedRowKeys([]);
  };

  return (
    <Content
      style={{
        margin: "0 16px",
        border: "1px solid #f8f8f8",
        backgroundColor: "#fff",
        marginTop: 15,
        boxShadow: "1px 1.5px 5px 1px #ebebeb",
        borderRadius: 15,
      }}
    >
      <Breadcrumb
        style={{
          padding: "20px 0px 0px 30px ",
          borderBottom: "1px solid #ebebeb",
          fontWeight: "bold",
          color: "#333",
          fontSize: 14,
          height: 55,
        }}
      >
        <Breadcrumb.Item>
          <Link to="/service/random" style={{ color: "#333333" }}>
            서비스관리
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link reloadDocument style={{ color: "#333333" }}>
            신고카드 목록
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => navigate(0)}
          ></Button>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="contentArea"
        style={{
          borderRadius: 15,
          marginBottom: 15,
          padding: 30,
          border: "0px solid red",
        }}
      >
        <Row
          gutter={24}
          style={{ paddingBottom: 15, border: "0px solid #999" }}
        >
          <Col span={6}>
            <Space wrap>
              <Button onClick={downloadCSV}>현재 목록 엑셀로</Button>
              {csvComponent}
            </Space>
          </Col>

          <Col
            span={18}
            style={{ textAlign: "right", border: "0px solid #999" }}
          >
            <Space style={{ float: "right" }}>
              <Input.Group
                compact
                style={{
                  float: "left",
                  minWidth: "50%",
                  border: "0px solid blue",
                }}
              >
                <Select
                  placeholder="검색필드"
                  allowClear
                  style={{ width: "30%" }}
                  onChange={onSelectSearch}
                >
                  {/*이하 부분만 수정하면 자동으로 다른것 수정하지 않아도 수정됨*/}
                  <Option value="m_id">신고자</Option>
                  <Option value="fo_name">폴더명</Option>
                  <Option value="ca_keyword">카드명</Option>
                </Select>
                <Search
                  placeholder="검색어 입력"
                  allowClear
                  enterButton
                  defaultValue={""}
                  onSearch={onSearch}
                  style={{
                    width: "70%",
                    border: "0px solid red",
                  }}
                />
              </Input.Group>
            </Space>

            <Space style={{ float: "right" }}>
              <Input.Group compact>
                <Select
                  placeholder="날짜필드입력"
                  allowClear
                  style={{ minWidth: 80 }}
                  onChange={onSelectSearchDate}
                >
                  <Option value="e_date">등록일</Option>
                </Select>
                <DatePicker.RangePicker
                  style={{ width: 200 }}
                  locale="ko_KR"
                  onChange={onSearchDateChange}
                />
              </Input.Group>
            </Space>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Table
              columns={columns} //위에서지정한 컬럼타입을 저장한변수
              dataSource={data}
              pagination={{
                //이부분으로 pagenation이출력됨
                ...tableParams.pagination,
                position: ["bottomCenter"],
              }}
              onChange={handleTableChange}
              rowKey={(record) => record.ba_no} //수정
              className="userlist-table"
              style={{ border: "0px solid #333" }}
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Reportlist;
